import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent {

  returnUrl: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  onPasswordChanged() {

    // clear the password must be changed flag so that routing will occur normally.
    this.auth.markPasswordChanged();

    // navigate to the return url
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    }
    else {
      this.router.navigate(['/']);
    }

  }
}

import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DocumentItem } from 'src/app/models/DocumentItem';
import { DocumentDownloadComponent } from '../document-download/document-download.component';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent {
  @Input() doc: DocumentItem;
  @ViewChild('fileInput') fileInput : ElementRef;

  touched: boolean;

  constructor(private changeDetector : ChangeDetectorRef) { }

  handleFileInput(files:FileList){
    let file = files.item(0);
    if(file != null){
      let doc = this.doc;
      doc.fileName = file.name;
      doc.fileToUpload = file;
      let fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        doc.fileContent = fileReader.result.toString();
      }
      fileReader.readAsDataURL(file);
    }
  }

  enableDocUpload(evt){
    this.touched = true;
    this.changeDetector.detectChanges();
    this.fileInput.nativeElement.click();
  }
}
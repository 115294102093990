import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (req.method === 'GET') {
            const noCacheRequest = req.clone({
                headers: req.headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
            });
            return next.handle(noCacheRequest);
        }

        return next.handle(req);
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { UserService, UserContactInfo } from '../../data-services'
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  @Input() user: User;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private toastr: ToastrService) { }

  ngOnInit() {
    var accountId = this.route.snapshot.paramMap.get('accountId')

    this.userService.getUser(accountId)
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  updateContactInfo(contactInfo: UserContactInfo) {

    this.userService.updateContactInfo(this.user.id, contactInfo)
      .subscribe(() => {
        this.toastr.success(`${this.user.firstName}'s account has been updated successfully`, "Success!");
        this.loadUser(this.user.id);
      });
  }

  onDenyUser() {
    this.location.back();
  }

  onDeleteUser() {
    this.location.back();
  }

  loadUser(id: string) {
    this.userService.getUser(id).subscribe(u => {
      this.user = u;
    })
  }
}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { PasswordValidation } from '../../shared';
import { finalize } from 'rxjs/operators';
import { AccountService, OrganizationSearchResult } from '../../data-services';
import { User } from '../../models';
import { ToastrService } from 'ngx-toastr';
import { ValidationProblemDetails } from 'src/app/shared';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  /**
   * The registration form.
   */
  signUpForm: FormGroup;

  /**
   * The registered user.
   */
  user: User;

  /**
   * A flag that a user must check when registering a new organization.
   */
  authorizedRep: boolean;

  /**
   * A flag that indicates that an attempt to submit the registration form has occured.
   */
  submitted: boolean;

  /**
   * A flag that indicates that the registration form has been submitted to the server and waiting for a response.
   */
  registering: boolean;

  /**
   * A flag that is used to determine if a new organization should be created.
   */
  progress: number = 0;

  currentStep: TemplateRef<any>;
  
  @ViewChild('orgSearch') orgSearchTemplate: TemplateRef<any>;
  @ViewChild('verifyOrgInfo') verifyOrgInfoTemplate: TemplateRef<any>;
  @ViewChild('orgInfo') orgInfoTemplate: TemplateRef<any>;
  @ViewChild('licenseAgreement') licenseAgreementTemplate: TemplateRef<any>;
  @ViewChild('license') licenseTemplate: TemplateRef<any>;
  @ViewChild('userInfo') userInfoTemplate: TemplateRef<any>;
  @ViewChild('confirmation') confirmationTemplate: TemplateRef<any>;

  private path: Array<{ progress: number, template: TemplateRef<any>}> = [];

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { 
    
    this.signUpForm = this.formBuilder.group({
      organizationId: [''],
      organizationName: [''],
      assuranceNumber: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(13), PasswordValidation.minUnique(6) ])],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required]
    }, { validator: Validators.compose([PasswordValidation.MatchPassword(), this.requireOneOf('organizationId','organizationName')]) });
  }

  /**
   * A custom valiation which requires at least of the specified controls to have a value.
   * 
   * @param controls A collection of controls to check
   */
  requireOneOf(...controls: string[]): ValidatorFn
  {
    return (ac: AbstractControl) => {
      var result = controls.some(controlName => {
        return false == this.isEmptyInputValue(ac.get(controlName));
      });

      return result ? null : { requireOneOf: true };
    }
  }

  isEmptyInputValue(value) {
    return value == null || value.length === 0;
  }

  /**
   * Accessor for the organization id form control.
   */
  get organizationId() {
    return this.signUpForm.controls['organizationId'];
  }

  /**
   * Accessor for the organization name form control.
   */
  get organizationName() {
    return this.signUpForm.controls['organizationName'];
  }

  /**
   * Accessor for the first name form control.
   */
  get firstName() {
    return this.signUpForm.controls['firstName'];
  }

  /**
   * Accessor for the last name form control.
   */
  get lastName() {
    return this.signUpForm.controls['lastName'];
  }

  /**
   * Accessor for the phone number form control.
   */
  get phoneNumber() {
    return this.signUpForm.controls['phoneNumber'];
  }

  /**
   * Accessor for the email form control.
   */
  get email() {
    return this.signUpForm.controls['email'];
  }
  
  /**
   * Accessor for the password form control.
   */
  get password() {
    return this.signUpForm.controls['password'];
  }

  /**
   * Accessor for the password confirmation form control.
   */
  get confirmPassword() {
    return this.signUpForm.controls['confirmPassword'];
  }


  ngOnInit() {
    this.next(this.orgSearchTemplate, 0);
  }

  next(template: TemplateRef<any>, progress: number) {
    this.path.push({ template: this.currentStep, progress: this.progress });
    this.currentStep = template;
    this.progress = progress;

    // clear the submitted flag to hide any validation errors
    this.submitted = false;
  }

  previous() {
    var step = this.path.pop();
    this.currentStep = step.template;
    this.progress = step.progress;
  }

  onSubmit() {
    
    this.submitted = true;

    if (this.signUpForm.invalid) {
      return;
    }

    this.registering = true;

    this.accountService.register(this.signUpForm.value)
      .pipe(finalize(() => {
        this.registering = false;
      }))
      .subscribe((user: User) => {
        this.user = user;
        this.next(this.confirmationTemplate, 100);
      },
      (err: ValidationProblemDetails) => {
        if (err.errors && err.errors['']) {
          
          if (err.errors[''].includes('PasswordMismatch')) {
            this.password.setErrors({ mismatch: true });
          }

          if (err.errors[''].includes('BlackListedPassword')) {
            this.password.setErrors({ blacklisted: true });
          }
        }
      });
  }

  
  
  /**
   * This is called when a user selects an institution from the typeahead list
   * 
   * @param event The event received from the NgbTypeahead control.
   */
  onOrganizationSelected(event: OrganizationSearchResult) {
    console.debug("selected", event);
    this.signUpForm.patchValue({ 
      organizationId: event.id,
      organizationName: event.name,
      assuranceNumber: event.assuranceNumber
    });
  }

  /**
   * Clears the state of the component, so the user can try the registration process again.
   * 
   * @param event The DOM event that triggered this function call.
   */
  reset() {
    this.path = [];
    this.next(this.orgSearchTemplate, 0);
    this.user = null;
    this.signUpForm.reset();
  }

  /**
   * Instructs the server to resend the email confirmation message to the newly registered user.
   * 
   * @param event The DOM event that triggered this function call.
   */
  resend(event: MouseEvent) {
    event.preventDefault(); // prevent navigation when link is clicked
    this.accountService.sendEmailConfirmation(this.user.id).subscribe(() => {
      this.toastr.success('Confirmation email sent.');
    });
  }

  clearOrganizationFormFields() {
    console.debug('clearing organization selection');
    this.signUpForm.patchValue({ organizationId: null, organizationName: null, assuranceNumber: null });
  }

}

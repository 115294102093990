import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Role } from './models';
import { AuthGuard } from './auth';
import {
  CenteredLayoutComponent,
  MainLayoutComponent,
  NotFoundComponent,
  ForbiddenComponent,
  PendingChangesGuard
} from './shared';

import {
  SplashComponent
} from './home';

import {
  AccountDetailsComponent,
  AccountManagerComponent,
  ChangePasswordComponent,
  ConfirmEmailComponent,
  ForceChangePasswordComponent,
  ForgotPasswordComponent,
  ProfileEditorComponent,
  ResetPasswordComponent,
  SignInComponent,
  SignUpComponent,
  RegistrationComponent,
  PendingApprovalComponent 
} from './accounts';

import {
  OrganizationDetailsComponent,
  OrganizationListComponent
} from './organizations';

import {
  ContinuingReviewComponent,
  InvitationListComponent,
  SiteDetailsComponent,
  SiteModificationComponent,
  StudyDetailsComponent,
  StudyListComponent,
  ReportableNewInformationComponent,
  ReportableNewInformationCreateComponent,
  SiteModificationCreateComponent
} from './research';
import { SignOutComponent } from './accounts/sign-out/sign-out.component';


const routes: Routes = [
  { path: '403', component: ForbiddenComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'login', component: CenteredLayoutComponent, children: [{ path: '', component: SignInComponent }]},
  { path: 'signup', component: CenteredLayoutComponent, children: [{ path: '', component: RegistrationComponent }]},
  { path: 'signOut', component: SignOutComponent },
  { path: 'change-password', component: CenteredLayoutComponent, children: [{ path: '', component: ForceChangePasswordComponent }]},
  { path: 'accounts/approval-pending', component: CenteredLayoutComponent, children: [{ path: '', component: PendingApprovalComponent }]},
  { path: 'accounts/forgot-password', component: CenteredLayoutComponent, children: [{ path: '', component: ForgotPasswordComponent }]},
  { path: 'accounts/reset-password', component: CenteredLayoutComponent, children: [{ path: '', component: ResetPasswordComponent }]},
  { 
    path: 'accounts/:userId/confirm-email', 
    component: CenteredLayoutComponent, 
    children: [{
      path: '',
      component: ConfirmEmailComponent
    }]
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', component: SplashComponent, pathMatch: 'full' },
      {
        path: 'accounts',
        canActivate: [AuthGuard],
        children: [
          { 
            path: ':accountId', 
            component: AccountDetailsComponent,
            data: {
              roles: [Role.AccountManager]
            }
          },
          { 
            path: ':accountId/manage', 
            component: AccountManagerComponent,
            children: [
              { path: '', redirectTo: './profile', pathMatch: 'full' },
              { path: 'password', component: ChangePasswordComponent },
              { path: 'profile', component: ProfileEditorComponent }
            ]
          }
        ]
      },
      {
        path: 'organizations',
        canActivate: [AuthGuard],
        data: {
          roles: [Role.AccountManager]
        },
        children: [
          { path: '', component: OrganizationListComponent },
          { path: ':orgId', component: OrganizationDetailsComponent }
        ]
      },
      {
        path: 'research',
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'studies', pathMatch: 'full' },
          { path: 'invitations', component: InvitationListComponent },
          { path: 'studies', component: StudyListComponent },
          { path: 'studies/:studyId', component: StudyDetailsComponent },
          { path: 'studies/:studyId/sites/:siteId', component: SiteDetailsComponent, canDeactivate: [PendingChangesGuard] },
          { path: 'studies/:studyId/sites/:siteId/cr', component: ContinuingReviewComponent },          
          { path: 'studies/:studyId/sites/:siteId/mod', component: SiteModificationCreateComponent, canDeactivate: [PendingChangesGuard] },
          { path: 'studies/:studyId/sites/:siteId/mod/:modId', component: SiteModificationComponent, canDeactivate: [PendingChangesGuard] },
          { path: 'studies/:studyId/sites/:siteId/rni', component: ReportableNewInformationCreateComponent, canDeactivate: [PendingChangesGuard] },
          { path: 'studies/:studyId/sites/:siteId/rni/:rniId', component: ReportableNewInformationComponent, canDeactivate: [PendingChangesGuard] }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

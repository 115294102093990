import { Component, OnInit } from '@angular/core';
import { DiagnosticsService } from 'src/app/data-services/diagnostics.service';

@Component({
  selector: 'app-forbidden-example',
  templateUrl: './forbidden-example.component.html',
  styleUrls: ['./forbidden-example.component.scss']
})
export class ForbiddenExampleComponent implements OnInit {

  showError = false;

  constructor(private diagnostics: DiagnosticsService) { }

  ngOnInit() {
  }

  test() {
    this.diagnostics.forbidden().subscribe(
      (d) => this.showError = true,
      (e) => this.showError = true);
  }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentItem } from 'src/app/models/documentItem';

@Component({
  selector: 'app-document-upload-list',
  templateUrl: './document-upload-list.component.html',
  styleUrls: ['./document-upload-list.component.scss']
})
export class DocumentUploadListComponent {
  @Output() docListChanged = new EventEmitter<any>();
  @Input() documents: DocumentItem[];
  studyId: string;

  constructor() { }

  removeDocRow(rowValue: any) {
    if (rowValue) {
      //rendering in this component's template filters out docs where delete === true
      rowValue.delete = true;
      this.docListChanged.emit(this.documents);
    }
  }

  handleFileInput(files: FileList) {
    for (var i = 0; i < files.length; i++) {
      let file = files.item(i);
      let doc = {
        fileName: file.name,
        fileToUpload: file,
        fileContent: null,
        studyId: this.studyId,
        itemId: null
      }
      let fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        doc.fileContent = fileReader.result.toString();
      }
      fileReader.readAsDataURL(file);
      this.documents.push(doc);
    }
    this.docListChanged.emit(this.documents);
  }
}


import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Site } from '../../models/site';

@Component({
  selector: 'app-site-contacts-reader',
  templateUrl: './site-contacts-reader.component.html',
  styleUrls: ['./site-contacts-reader.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SiteContactsReaderComponent implements OnInit {
  @Input() site: Site;

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {  } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Role } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private auth: AuthService, private router: Router) {

  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.auth.isAuthenticated) {

        var currentUser = this.auth.currentUser;

        if (this.auth.requirePasswordChange) {
          this.router.navigate(['/change-password'], { queryParams: { returnUrl: state.url }, queryParamsHandling: 'merge' });
          return false;
        }

        if (this.auth.isApproved === false) {
          this.router.navigate(['/accounts/approval-pending'], { queryParams: { returnUrl: state.url }, queryParamsHandling: 'merge' });
          return false;
        }

        // does the route require a specific role?
        if (next.data.roles) {

          // is the currentUser an admin?
          if (currentUser.roles.includes(Role.Administrator)) {
            return true;
          }

          // check if the user has a role that is listed in the route data
          var hasRole = currentUser.roles.some(role => next.data.roles.includes(role));
          if (!hasRole) {
            console.debug(currentUser.name, "not in role", next.data.roles)
            this.router.navigate(['/']);
            return false;
          }

        }

        return true;
      }

      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
  }
}

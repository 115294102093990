import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CenteredLayoutComponent } from './layouts/centered-layout/centered-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { AuthModule } from '../auth';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { LoadingComponent } from './loading/loading.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { DocumentDownloadCellComponent } from './document-download-cell/document-download-cell.component';
import { DocumentUploadCellComponent } from './document-upload-cell/document-upload-cell.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { InvitationCountComponent } from './invitation-count/invitation-count.component';
import { RouterLinkCellComponent } from './router-link-cell/router-link-cell.component';
import { PendingApprovalCountComponent } from './pending-approval-count/pending-approval-count.component';
import { DeleteRowComponent } from './delete-row/delete-row.component';
import { DocumentUploadListCellComponent } from './document-upload-list-cell/document-upload-list-cell.component';
import { DocumentDownloadListCellComponent } from './document-download-list-cell/document-download-list-cell.component';
import { AppcuesWidgetComponent } from './appcues-widget/appcues-widget.component';
import { StepListComponent } from './step-list/step-list.component';
import { DocumentUploadListComponent } from './document-upload-list/document-upload-list.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { HelpComponentDialog } from './help-dialog/help-dialog.component';

@NgModule({
    declarations: [
        CenteredLayoutComponent,
        EmptyLayoutComponent,
        MainLayoutComponent,

        HeaderComponent,
        FooterComponent,
        NavBarComponent,

        DocumentDownloadComponent,
        DocumentUploadComponent,
        DocumentDownloadCellComponent,
        DocumentUploadCellComponent,
        DocumentUploadListCellComponent,
        DocumentDownloadListCellComponent,

        FeedbackDialogComponent,
        HelpComponentDialog,

        NotFoundComponent,
        ForbiddenComponent,
        LoadingComponent,
        MessageBoxComponent,
        InvitationCountComponent,
        RouterLinkCellComponent,
        PendingApprovalCountComponent,
        DeleteRowComponent,        
        AppcuesWidgetComponent,        
        StepListComponent,        
        DocumentUploadListComponent,        
        ReadMoreComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        AuthModule
    ],
    entryComponents: [
        FeedbackDialogComponent,
        MessageBoxComponent,
        HelpComponentDialog
    ],
    exports: [
        FooterComponent,
        DocumentUploadListComponent,
        DocumentDownloadComponent,
        DocumentUploadComponent,
        LoadingComponent,
        StepListComponent,
        ReadMoreComponent
    ]
})
export class SharedModule { }
import { Component } from '@angular/core';

@Component({
  template: '<p class="display-3">404 Not Found</p>'
})
export class NotFoundComponent {

  constructor() { }

}

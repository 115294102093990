import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';


@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // If the token exists, set the authorization header
        const token = this.auth.accessToken;
        if (token) {
            var request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        
        // Look for the X-RefreshToken header in the response, if it exists
        // notify the auth service to update the access token.
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.headers.has('X-RefreshToken')) {
                        this.auth.handleRefresh(event.headers.get('X-RefreshToken'));
                    }
                }
            })
        );
    }
}

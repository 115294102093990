import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchService } from '../../data-services';
import { Site, SiteStatus } from '../../models/site';
import { ToastrService } from 'ngx-toastr';
import { DocumentUploadCellComponent, AgGridDefaults, DocumentDownloadCellComponent } from '../../shared';
import { RouterLinkCellComponent } from '../../shared';
import { DeleteRowComponent } from 'src/app/shared/delete-row/delete-row.component';
import { NotifyDialogComponent } from '../notify-dialog/notify-dialog.component';
import { DocumentUploadListCellComponent } from 'src/app/shared/document-upload-list-cell/document-upload-list-cell.component';
import { DocumentDownloadListCellComponent } from 'src/app/shared/document-download-list-cell/document-download-list-cell.component';
import { NgbModal, NgbModalOptions, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ContinuingReviewComponent } from '../continuing-review/continuing-review.component';
import { ComponentCanDeactivate } from 'src/app/shared/pending-changes.guard';
import { ObjectUtils } from 'src/app/shared/object-utils';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('tabs') tabs: NgbTabset;
  @ViewChild(NotifyDialogComponent) notifyDialog :NotifyDialogComponent;

  studyExchangeId: string;
  siteExchangeId: string;
  site: Site;
  originalSite: Site;
  loadComplete: boolean = false;
  saving: boolean = false;
  sending: boolean = false;
  canEdit: boolean = false;
  canMod: boolean = false;
  canRni: boolean = false;
  canCR: boolean = false;
  canReview: boolean = false;
  canSave: boolean = false;
  SiteStatus = SiteStatus; //provides a reference to the SiteStatus enum in the context of the component.html page for *ngIf usage
  pageValidationErrors: string[] = [];  
  private submitAction: string;
  private modalOptions: NgbModalOptions ={
    centered: true
  }

  fundingSourceGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "name", editable: x => this.getCanEdit() },
      { 
        headerName: "Draft", field: "documents", editable: x => this.getCanEdit(), autoHeight: true, type: ["noSort"],
        cellEditor: "documentUploadEditor",
        cellRenderer: "documentDownloadListRenderer"
      },
      {
        headerName: "Final", field: "finalDocuments", editable: false, autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      }
    ],
    frameworkComponents: {
      documentUploadEditor: DocumentUploadListCellComponent,
      documentDownloadListRenderer: DocumentDownloadListCellComponent,
      deleteRowRenderer: DeleteRowComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  rniGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      {
        headerName: "Name", field: "name", cellRenderer: "routerLinkRenderer", 
        cellRendererParams: { 
          routerLink: [
            '/research/studies', 
            this.route.snapshot.paramMap.get('studyId'), 
            'sites', 
            this.route.snapshot.paramMap.get('siteId'), 
            'rni',
            '{{id}}'
          ],
          displayAttribute: 'name'
        }
      },
      { headerName: "Date of Awareness", field: "dateOfAwareness", minWidth: 170, type: ["date"] },
      { headerName: "Status", field: "status", minWidth: 100 }
    ],
    frameworkComponents: {
      routerLinkRenderer: RouterLinkCellComponent
    }
  });

  modGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      {
        headerName: "Name", field: "name", minWidth: 100, cellRenderer: "routerLinkRenderer", 
        cellRendererParams: {
          routerLink: [
            '/research/studies',
            this.route.snapshot.paramMap.get('studyId'),
            'sites',
            this.route.snapshot.paramMap.get('siteId'),
            'mod',
            '{{id}}'
          ],
          displayAttribute: 'name'
        }
      },
      { headerName: "Summary", field: "summary" },
      { headerName: "Date Created", field: "dateCreated", minWidth: 135, type: ["date"] },
      { headerName: "Status", field: "status", minWidth: 100 }
    ],
    
    frameworkComponents: {
      routerLinkRenderer: RouterLinkCellComponent
    }
  });

  attachmentGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion",  type: ["nonEditable"] }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent,
      documentDownloadRenderer: DocumentDownloadCellComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  private consentFormsGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion", type: ["nonEditable"] }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent,
      documentDownloadRenderer: DocumentDownloadCellComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  private recruitmentMaterialsGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion", type: ["nonEditable"] }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent,
      documentDownloadRenderer: DocumentDownloadCellComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  constructor(
    private route: ActivatedRoute,
    private researchService: ResearchService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    private modal: NgbModal,
    private sharedService: SharedService) { }

  ngOnInit() {
    //pull values from the url using param name assigned in app-routing module
    this.loadComplete = false;
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.siteExchangeId = this.route.snapshot.paramMap.get('siteId');
    this.researchService.getSiteDetails(this.studyExchangeId, this.siteExchangeId)
      .subscribe(
        (site) => {
          this.loadComplete = true;
          this.saving = false;
          this.sending = false;
          this.site = site;
          this.setPermissions();
          this.applyPermissionsToGrids();
          //Need at least one for the ngFor loop to render an input
          if (this.site.piProxies == null || this.site.piProxies.length == 0) {
            this.site.piProxies = [{
              firstName: "",
              lastName: "",
              email: ""
            }];
          }
          this.originalSite = ObjectUtils.deepCopy(this.site);
          this.showRequestedTab();
          this.RefreshGridView();
        });
  }

  //use canDeactivate as a handler for the window.beforeUnload event
  @HostListener('window:beforeunload')
  canDeactivate() : boolean {
    //if changes made, return false to trigger a warning/confirmation dialog via pending-changes.guard
    let diffObj = ObjectUtils.getDifference(this.originalSite, this.site);
    return (Object.keys(diffObj).length == 0);
  }

  setPermissions(){
    //booleans for hide/show of page content
    switch(this.site.status){
      case SiteStatus.active:
        this.canCR = true; //fall through from most permissive to least permissive states
      case SiteStatus.inactive:
      case SiteStatus.closed:
      case SiteStatus.suspended:
      case SiteStatus.terminated:
        this.canMod = true;
        this.canRni = true;
      case SiteStatus.pending:
      case SiteStatus.discarded:
        this.canReview = true;
        break;
      default:
        break;
    }

    //in draft you can save or send. In pending, you can only send.
    this.canSave = this.site.status == SiteStatus.draft;

    //List of editable states 
    switch(this.site.status){
      case SiteStatus.draft:
      case SiteStatus.pending:
        this.canEdit = true;
        break;
      default:
        this.canEdit = false;
        break;
    }
  }

  applyPermissionsToGrids(){
    if (this.fundingSourceGridOptions.columnApi) {
      this.fundingSourceGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if (this.attachmentGridOptions.columnApi) {
      this.attachmentGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if(this.consentFormsGridOptions.columnApi){
      this.consentFormsGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if(this.recruitmentMaterialsGridOptions.columnApi){
      this.recruitmentMaterialsGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
  }

  studyFundingSourceGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Name", field: "name"},
      {
        headerName: "Draft", field: "documents", autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      },
      {
        headerName: "Final", field: "finalDocuments", autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      }
    ],
    frameworkComponents: {
      documentDownloadListRenderer: DocumentDownloadListCellComponent
    }
  });

  showRequestedTab(){
    /*make the change detector realize the viewChild named tabs is present on the page
    alternative: run this under a setTimeout() and hope you win the race condition
    alternate alternative: set up an observable to combine two observables with combineLatest() 
      to observe when both the data is loaded and the viewChild initialized, which means using a setter on the viewChild which triggers .next()
      but don't forget to unsubscribe if you go that route
    */
    this.changeDetector.detectChanges();

    let frag = this.route.snapshot.fragment;
    if(frag){
      this.tabs.select(frag);
      window.location.hash = "";
    }
  }

  // Click events
  onSaveDraft() {
    this.submitAction = "save";
  }
  onSaveAndSend() {
    this.submitAction = "saveAndSend";
  }

  // POST
  onSubmit() {
    if (this.submitAction === "save") {
      this.onSave();
    } else if (this.submitAction === "saveAndSend") {
      this.onSendToSirb();
    }
  }

  onSave() {
    if (this.validatePage()) {
      this.saving = true;
      this.researchService.updateSite(this.studyExchangeId, this.siteExchangeId, this.site)
        .subscribe(() => {
          this.ngOnInit();
          this.toastr.success("Changes saved", "Success!");
        });
    }
  }

  onSendToSirb() {
    if(this.validatePage()){
       //Send data to the modal
       this.sharedService.studyExchangeId = this.studyExchangeId;
       this.sharedService.siteExchangeId = this.siteExchangeId;
       this.sharedService.site = this.site;
       //Open the modal
       this.modal.open(NotifyDialogComponent, this.modalOptions).result.then((result)=>{
        //Check closing reason
        if(result === "Data sent"){
          //Reload the component to get the new data.
          this.ngOnInit();
        }
      });
    }
  }

  onClose() {
    this.router.navigate(['/research/studies']);
  }

  validatePage() : boolean {
    this.pageValidationErrors = [];
    
    //#region other attachment validation: draft doc required, no blank rows
    let triggeredAttachmentDraftMessage = false;
    let autoPopulatedDraftName =false;
    let blankAttachments = [];
    for(let att of this.site.attachments){
      if (!att.delete) {
        //if anything filled out
        if (att.id || att.category || att.draftVersion || att.draftName || att.finalVersion || att.finalName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") { //and the draft file is missing
            if (!triggeredAttachmentDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Other attachments");
              triggeredAttachmentDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") { // Draft is filled out  
          // Auto-populate the draftName with filename for display, though service end point handle it.
          autoPopulatedDraftName=true; 
          att.draftName = att.draft.fileName;       
          continue;
        } 
        else { //nothing filled out, go ahead and just remove it
          //except we cant remove it now or we'll mess up the iterator. Remove them all when we're done here.
          blankAttachments.push(att);
        }
      }
      //else deleted, do nothing to validate it, the server will remove it
    }
    for(let blank of blankAttachments){
      this.site.attachments.splice(this.site.attachments.indexOf(blank), 1);
    }
    //if we removed rows from the grid or auto populated name, instruct it to refresh the display.
    if(blankAttachments.length > 0 || autoPopulatedDraftName){
      this.attachmentGridOptions.api.setRowData(this.site.attachments);
      this.attachmentGridOptions.setGridColumnWidths();
    }
    //#endregion

    //#region ConsentForms validation: draft doc required, no blank rows
    let triggeredConsentFormsDraftMessage = false;
    let blankConsentForms = [];
    autoPopulatedDraftName = false;
    for (let att of this.site.consentForms) {
      if (!att.delete) {
        if (att.id || att.draftVersion || att.draftName || att.finalVersion || att.finalName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") {
            if (!triggeredConsentFormsDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Consent forms");
              triggeredConsentFormsDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") {     
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        }
        else {
          blankConsentForms.push(att);
        }
      }
    }
    for(let blank of blankConsentForms){
      this.site.consentForms.splice(this.site.consentForms.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankConsentForms.length > 0 || autoPopulatedDraftName) {
      this.consentFormsGridOptions.api.setRowData(this.site.consentForms);
      this.consentFormsGridOptions.setGridColumnWidths();
    }
    //#endregion

    //#region RecruitmentMaterials: draft doc required, no blank rows
    let triggeredRecruitmentDraftMessage = false;
    let blankRecruitmentMaterials = [];
    autoPopulatedDraftName = false;
    for (let att of this.site.recruitmentMaterials) {
      if (!att.delete) {
        if (att.id || att.draftVersion || att.draftName || att.finalVersion || att.finalName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") {
            if (!triggeredRecruitmentDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Recruitment materials");
              triggeredRecruitmentDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") {        
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        }
        else {
          blankRecruitmentMaterials.push(att);
        }
      }
    }
    for(let blank of blankRecruitmentMaterials){
      this.site.recruitmentMaterials.splice(this.site.recruitmentMaterials.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankRecruitmentMaterials.length > 0 || autoPopulatedDraftName) {
      this.recruitmentMaterialsGridOptions.api.setRowData(this.site.recruitmentMaterials);
      this.recruitmentMaterialsGridOptions.setGridColumnWidths();
    }
    //#endregion

    //#region funding source validation: no blank rows
    let blankFundingSources = [];
    for(let fs of this.site.fundingSources){
      if(!fs.delete){
        //if nothing filled out and no id, then its a blank, new row. Remove that junk.
        //again, save the removal until the end to avoid angering the iterator
        if(!(fs.id || fs.name || (fs.documents && fs.documents.length > 0))){
          blankFundingSources.push(fs);
        }
      }
    }

    for(let blank of blankFundingSources){
      this.site.fundingSources.splice(this.site.fundingSources.indexOf(blank), 1);
    }
    if (blankFundingSources.length > 0) {
      this.fundingSourceGridOptions.api.setRowData(this.site.fundingSources);
      this.fundingSourceGridOptions.setGridColumnWidths();
    }
    //#endregion

    if (triggeredAttachmentDraftMessage || triggeredConsentFormsDraftMessage || triggeredRecruitmentDraftMessage) {
      this.tabs.select('attachment-tab');
      return false;
    }
    return true;
  }

  //always-on filtering of which grid rows are visible
  isExternalFilterPresent(): boolean {
    return true;
  }

  //test funding source row to determine if it should be visible in the grid
  doesExternalFilterPass(node) {
    return !node.data.delete;
  }

  //must use a function to achieve dynamic editability in ag-grid cells
  getCanEdit(){
    return this.canEdit;
  }

  //add new row to site.fundingSources, then inform the grid that there's been an update
  addFundingSourceRow() {
    let newRow = {
      delete: false,
      id: "",
      name: "",
      documents: [],
      finalDocuments: [],
      fundingSourceInitialSiteId: ""
    };

    this.site.fundingSources.push(newRow);
    this.fundingSourceGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.fundingSourceGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.fundingSources.length-1,
      colKey: 'name'
    });
  }

  //add new row to site.Attachments, then inform the grid that there's been an update
  addAttachmentRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.site.attachments.push(newRow);
    this.attachmentGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.attachmentGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.attachments.length-1,
      colKey: 'draftName'
    });
  }

  //add new row to site.Attachments, then inform the grid that there's been an update
  addConsentFormsRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.site.consentForms.push(newRow);
    this.consentFormsGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.consentFormsGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.consentForms.length-1,
      colKey: 'draftName'
    });
  }

  addRecruitmentMaterialsRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.site.recruitmentMaterials.push(newRow);
    this.recruitmentMaterialsGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.recruitmentMaterialsGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.recruitmentMaterials.length-1,
      colKey: 'draftName'
    });
  }

//display the funding sources of the initial study (local funding sources)

onStudyFundingSourceGridReady(params) {
  this.studyFundingSourceGridOptions.api = params.api;
  this.studyFundingSourceGridOptions.columnApi = params.columnApi;
  if (this.site != null) {
    this.studyFundingSourceGridOptions.api.setRowData(this.site.studyFundingSources);
    this.studyFundingSourceGridOptions.setGridColumnWidths();
  }
}


  //When grid is displayed following successful retrieval of site data, connect grid data source
  onFundingSourceGridReady(params) {
    this.fundingSourceGridOptions.api = params.api;
    this.fundingSourceGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.fundingSourceGridOptions.api.setRowData(this.site.fundingSources);
      this.fundingSourceGridOptions.setGridColumnWidths();
    }
  }

  onRniGridReady(params) {
    this.rniGridOptions.api = params.api;
    this.rniGridOptions.columnApi = params.columnApi;
    if (this.site != null) {
      this.rniGridOptions.api.setRowData(this.site.rni);
      this.rniGridOptions.setGridColumnWidths();
    }
  }

  onModGridReady(params) {
    this.modGridOptions.api = params.api;
    this.modGridOptions.columnApi = params.columnApi;
    if (this.site != null) {
      this.modGridOptions.api.setRowData(this.site.mods);
      this.modGridOptions.setGridColumnWidths();
    }
  }

  onAttachmentGridReady(params) {
    this.attachmentGridOptions.api = params.api;
    this.attachmentGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.attachmentGridOptions.api.setRowData(this.site.attachments);
      this.attachmentGridOptions.setGridColumnWidths();
    }
  }

  onConsentFormsGridReady(params) {
    this.consentFormsGridOptions.api = params.api;
    this.consentFormsGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.consentFormsGridOptions.api.setRowData(this.site.consentForms);
      this.consentFormsGridOptions.setGridColumnWidths();
    }
  }

  onRecruitmentMaterialsReady(params) {
    this.recruitmentMaterialsGridOptions.api = params.api;
    this.recruitmentMaterialsGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.recruitmentMaterialsGridOptions.api.setRowData(this.site.recruitmentMaterials);
      this.recruitmentMaterialsGridOptions.setGridColumnWidths();
    }
  }

  onClickShowCR(){
    const modalRef = this.modalService.open(ContinuingReviewComponent, { windowClass: "modal-custom-xl" });
    modalRef.componentInstance.studyExchangeId = this.studyExchangeId;
    modalRef.componentInstance.siteExchangeId = this.siteExchangeId;
  }

  onFirstDataRendered(params, optionsObj){
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }

  // Removes the rows from the DOM and draws them again from scratch
  RefreshGridView() {
    //recreate document cellRenderer for revision upload without page refresh 
    if (this.site != null) {
      if (this.consentFormsGridOptions.api) {
        this.consentFormsGridOptions.api.setRowData(this.site.consentForms);
        this.consentFormsGridOptions.api.redrawRows();
      }

      if (this.recruitmentMaterialsGridOptions.api) {
        this.recruitmentMaterialsGridOptions.api.setRowData(this.site.recruitmentMaterials);
        this.recruitmentMaterialsGridOptions.api.redrawRows();
      }

      if (this.attachmentGridOptions.api) {
        this.attachmentGridOptions.api.setRowData(this.site.attachments);
        this.attachmentGridOptions.api.redrawRows();
      }

      if (this.fundingSourceGridOptions.api) {
        this.fundingSourceGridOptions.api.setRowData(this.site.fundingSources);
        this.fundingSourceGridOptions.api.redrawRows();
      }
    }
  }
}

import { Role } from './role';

export interface SignedInUserDto {
    id: string;
    name: string;
    email: string;
    organizationId: number;
    organizationName: string;
    roles: Role[];
    createdAt: Date;
    lastSignInAt: Date;
}

export class SignedInUser {

    id: string;
    name: string;
    email: string;
    organizationId: number;
    organizationName: string;
    roles: Role[];
    createdAt: Date;
    lastSignInAt: Date;

    constructor(args?: SignedInUserDto) {
        if (args) {
            this.id = args.id;
            this.name = args.name;
            this.email = args.email;
            this.organizationId = args.organizationId;
            this.organizationName = args.organizationName;
            this.roles = args.roles;
            this.createdAt = args.createdAt;
            this.lastSignInAt = args.lastSignInAt;
        }
    }

    isInRole(role: Role) {
        return this.roles.includes(role);
    }

    isInAnyRole(roles: Role[]) {
        return roles.some(role => this.isInRole(role));
    }
}
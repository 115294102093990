import { Component, AfterViewInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbRatingConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  providers: [NgbRatingConfig]
})
export class FeedbackDialogComponent implements AfterViewInit {

  feedbackForm: FormGroup;

  // reference to the textarea marked with #comment
  @ViewChild("comment") commentField: ElementRef;

  constructor(
    public modal: NgbActiveModal, 
    private renderer: Renderer,
    fb: FormBuilder, 
    ratingConfig: NgbRatingConfig) {

    ratingConfig.max = 5; 
    this.feedbackForm = fb.group({
      rating: [0],
      comment: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    // use the renderer to interact with the actual DOM Element.
    this.renderer.invokeElementMethod(this.commentField.nativeElement, 'focus');
  }

}

import { Component } from '@angular/core';

@Component({
  template: '<router-outlet></router-outlet>',
  styles: [`
    :host {
      position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00568c;
      background-image: url('assets/images/cubes.png');
      background-size: cover;
    }`,
  ]
})
export class CenteredLayoutComponent { }

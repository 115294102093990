import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, AfterViewInit, ViewChild, ElementRef, Renderer, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Site } from 'src/app/models/site';

import { ResearchService, FeedbackService } from '../../data-services';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-notify-dialog',
  templateUrl: './notify-dialog.component.html',
  styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent implements AfterViewInit {

  notifyForm: FormGroup;

  loadComplete: boolean = true;
  isSending: boolean = false;
  notify: boolean = false;

  @ViewChild("checkNotify") check: ElementRef;

  studyExchangeId: string;
  siteExchangeId: string;
  site: Site

  constructor(public modal: NgbActiveModal,
    private renderer: Renderer,
    private researchService: ResearchService,
    public fb: FormBuilder,
    private feedbackService: FeedbackService,
    private toastr: ToastrService,
    private sharedService: SharedService) {
    //Init form to prevent errors
    this.notifyForm = this.fb.group({});
  }

  ngAfterViewInit() {
    // use the renderer to interact with the actual DOM Element.
    this.renderer.invokeElementMethod(this.check.nativeElement, 'focus');

    //Get data from the site to send it to the API
    this.studyExchangeId = this.sharedService.studyExchangeId;
    this.siteExchangeId = this.sharedService.siteExchangeId;
    this.site = this.sharedService.site;
  }

  onSubmit() {
    if (this.notify) {
      this.isSending = true;
      this.researchService.sendToSirb(this.studyExchangeId, this.siteExchangeId, this.site)
        .subscribe(() => {
          //Call api to send notification email when send to sIRB was success
            this.feedbackService.notifyStudyTeamLead(this.studyExchangeId).subscribe(()=>{
            this.toastr.success("Changes saved and sent", "Success!");
            this.isSending = false;
            this.modal.close("Data sent")
          }),
          (err) => {
            this.isSending = false;
            this.modal.close("Data not sent")
          }
          
        }),
        (err) => {
          this.isSending = false;
          this.modal.close("Data not sent")
        };
    }
    else {
      //Only send to sIRB without email notification
      this.isSending = true;
      this.researchService.sendToSirb(this.studyExchangeId, this.siteExchangeId, this.site)
        .subscribe(() => {
          this.toastr.success("Changes saved and sent", "Success!");
          this.isSending = false;
          this.modal.close("Data sent")
        }),
        (err) => {
          this.isSending = false;
          this.modal.close("Data not sent")
        };
    }
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserDto, User, Organization, OrganizationDto } from '../models';
import { DataServicesModule } from './data-services.module';
import { ToastrService } from 'ngx-toastr';

export interface OrganizationSearchResult {
  id?: number;
  name: string;
  exchangeId: string;
  assuranceNumber: string;
}

export interface OrganizationSearchResponse {
  results: OrganizationSearchResult[];
}

export interface GetOrganizationsResponse {
  organizations: OrganizationDto[]
}

export interface GetOrganizationUsersResponse {
  users: UserDto[]
}

export interface OrganizationSearchRequest {
  term: string;
  local?: boolean;
  exchange?: boolean;
  ohrp?: boolean;
}

@Injectable({
  providedIn: DataServicesModule
})
export class OrganizationService {

  constructor(private http: HttpClient) { }

  getOrganization(id: string|number): Observable<Organization> {
    return this.http.get<OrganizationDto>(`/api/organizations/${id}`)
      .pipe(map(dto => new Organization(dto)));
  }

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<GetOrganizationsResponse>('/api/organizations')
      .pipe(map((response) => response.organizations.map(dto => new Organization(dto))));
  }

  approveAccess(id: string|number) {
    return this.http.post(`/api/organizations/${id}/approve`, {})
  }

  denyAccess(id: string|number) {
    return this.http.post(`/api/organizations/${id}/deny`, {});
  }

  getUsers(organizationId: number): Observable<User[]> {
    return this.http.get<GetOrganizationUsersResponse>(`/api/organizations/${organizationId}/users`)
      .pipe(
        map(resp => resp.users),
        map(users => users.map(u => new User(u)))
      );
  }

  search(request: OrganizationSearchRequest): Observable<OrganizationSearchResult[]> {
    
    var params = {
      q: request.term,
      local: request.local ? request.local.toString() : 'false',
      exchange: request.exchange ? request.exchange.toString() : 'false',
      ohrp: request.ohrp ? request.ohrp.toString() : 'false'
    };

    return this.http.get<OrganizationSearchResponse>('/api/organizations/search', { params })
      .pipe(map(response => response.results));
  }
}

import { Component, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth';
import { finalize, audit } from 'rxjs/operators';
import { Role } from 'src/app/models';
import { SignedInUser } from 'src/app/models/signed-in-user';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  signInForm: FormGroup;
  returnUrl: string;
  submitted: boolean;
  invalidAttempt: boolean;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer,
    formBuilder: FormBuilder) {

    this.signInForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: [false]
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  submit() {
    this.submitted = true;
    this.invalidAttempt = false;
    this.authService.signIn(this.signInForm.value)
      .pipe(
        finalize(() => {
          this.submitted = false;
          this.signInForm.patchValue({ 'password': '' });
        })
      )
      .subscribe(
        data => {

          var user = new SignedInUser(data.user);

          if (this.authService.requirePasswordChange) {
            this.router.navigate(['/change-password'], { queryParamsHandling: 'preserve' });
            return;
          }

          if (this.authService.isApproved === false) {
            this.router.navigate(['/accounts/approval-pending'], { queryParamsHandling: 'preserve' });
            return;
          }

          // if no return url is specified, then go to the default page for the role
          if (!this.returnUrl)
          {
            if (user.isInRole(Role.Administrator)) {
              this.router.navigate(['organizations']);
              return;
            }
            if (user.isInRole(Role.AccountManager)) {
              this.router.navigate(['organizations', user.organizationId]);
              return;
            }
            if (user.isInRole(Role.RelianceCoordinator)) {
              this.router.navigate(['research', 'invitations']);
              return;
            }
            if (user.isInRole(Role.StudyStaff)) {
              this.router.navigate(['research', 'studies']);
              return;
            }

            // not in one of these roles, go home
            this.router.navigate(['/']);
            return;
          }

          this.router.navigateByUrl(this.returnUrl);
        }, 
        error => {
          console.log(error);
          this.invalidAttempt = true;
        });
  }
}


import { Role } from './role';

export interface UserDto {
    dateCreated: Date;
    dateUpdated: Date;
    dateLastLogin: Date;
    email: string;
    firstName: string;
    id: string;
    isApproved: boolean;
    isLocked: boolean;
    lastName: string;
    name: string;
    organizationId: number;
    organizationName: string;
    phoneNumber: string;
    roles: Role[];
}

export class User {

    dateCreated: Date;
    dateUpdated: Date;
    dateLastLogin: Date;
    email: string;
    firstName: string;
    id: string;
    isApproved: boolean;
    isLocked: boolean;
    lastName: string;
    name: string;
    organizationId: number;
    organizationName: string;
    phoneNumber: string;
    roles: Role[] = [];
    

    constructor(args?: Partial<UserDto>) {
        if (args) {
            this.id = args.id;
            this.name = args.name;
            this.firstName = args.firstName;
            this.lastName = args.lastName;
            this.email = args.email;
            this.phoneNumber = args.phoneNumber;
            this.isLocked = args.isLocked;
            this.roles.push(...args.roles);
            this.isApproved = args.isApproved;
            this.dateCreated = args.dateCreated;
            this.dateUpdated = args.dateUpdated;
            this.dateLastLogin = args.dateLastLogin;
            this.organizationId = args.organizationId;
            this.organizationName = args.organizationName;
        }
    }

    isInRole(role: Role) {
        return this.roles.includes(role);
    }
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResearchService } from '../../data-services';
import { Study } from '../../models/study';
import { ToastrService } from 'ngx-toastr';
import { AgGridDefaults, DocumentDownloadListCellComponent, DocumentDownloadCellComponent } from '../../shared'
import { Attachment } from 'src/app/models/attachment';

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.scss']
})

export class StudyDetailsComponent implements OnInit {
  
  studyExchangeId : string;
  study : Study;
  loadComplete: boolean = false;
  saving: boolean = false;
  declining: boolean = false;
  InReview: boolean = false;
  alertClosed: boolean = false;

  drugGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Brand", field: "brandName" },
      { headerName: "Generic", field: "genericName" },
      { headerName: "Type", field:"drugType"},
      { headerName: "Other Type Description", field:"otherDrugTypeDescription"},
      {
        headerName: "Documents", field: "documents", autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      }
    ],
    frameworkComponents: {
      documentDownloadListRenderer: DocumentDownloadListCellComponent
    }
  });

  indGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Number", field: "number" },
      { headerName: "Holder", field: "holder" }
    ]
  });

  deviceGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Name", field: "name" },
      { headerName: "Is Humanitarian Use?", field: "isHumanitarianUseDevice" },
      {
        headerName: "Documents", field: "documents", autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      }
    ],
    frameworkComponents: {
      documentDownloadListRenderer: DocumentDownloadListCellComponent
    }
  });

  ideGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Number", field: "number" },
      { headerName: "Holder", field: "holder" }
    ]
  });

  fundingSourceGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Name", field: "name"},
      {
        headerName: "Draft", field: "documents", autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      },
      {
        headerName: "Final", field: "finalDocuments", autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      }
    ],
    frameworkComponents: {
      documentDownloadListRenderer: DocumentDownloadListCellComponent
    }
  });

  consentFormGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Name", field: "draftName" },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer"
      },
      { headerName: "Version", field: "draftVersion" },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion" }
    ],
    frameworkComponents: {
      documentDownloadRenderer: DocumentDownloadCellComponent
    }
  });

  recruitmentMaterialGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Name", field: "draftName" },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer"
      },
      { headerName: "Version", field: "draftVersion" },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion" }
    ],
    frameworkComponents: {
      documentDownloadRenderer: DocumentDownloadCellComponent
    }
  });

  attachmentGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "Name", field: "draftName" },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer"
      },
      { headerName: "Version", field: "draftVersion" },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion" }
    ],
    frameworkComponents: {
      documentDownloadRenderer: DocumentDownloadCellComponent
    }
  });

  constructor(
    private route : ActivatedRoute,
    private researchService: ResearchService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    //pull values from the url using param name assigned in app-routing module
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.researchService.getStudyDetails(this.studyExchangeId)
    .subscribe(
      (study) => {
        this.loadComplete = true;
        this.study = study;
        this.InReview = this.study.status !== "Approved";
    });
  }

  accept(study: Study) {
    this.saving = true;
    this.researchService.acceptInvitation(study.exchangeContainerId).subscribe(
      (response: any)=> {
        this.study.siteExchangeId = response.siteExchangeId;
        this.toastr.success(`The Invitation to participate in ${study.title} has been accepted.`, "Invitation accepted");
        this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.study.siteExchangeId])
    });   
  }
 
  decline(study: Study) {
    this.declining = true;
    this.researchService.declineInvitation(study.exchangeContainerId).subscribe(
      ()=> {
        this.toastr.warning(`The invitation to participate in ${study.title} has been declined.`, "Invitation declined");
        this.saving = false;
        this.router.navigate(['/research/invitations']);
    });
  }

  onFirstDataRendered(params, optionsObj) {
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }

  onDrugGridReady(params) {
    this.drugGridOptions.api = params.api;
    this.drugGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.drugGridOptions.api.setRowData(this.study.drugs);
      this.drugGridOptions.setGridColumnWidths();
    }
  }

  onIndGridReady(params) {
    this.indGridOptions.api = params.api;
    this.indGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.indGridOptions.api.setRowData(this.study.investigationalNewDrugs);
      this.indGridOptions.setGridColumnWidths();
    }
  }

  onDeviceGridReady(params) {
    this.deviceGridOptions.api = params.api;
    this.deviceGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.deviceGridOptions.api.setRowData(this.study.devices);
      this.deviceGridOptions.setGridColumnWidths();
    }
  }

  onIdeGridReady(params) {
    this.ideGridOptions.api = params.api;
    this.ideGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.ideGridOptions.api.setRowData(this.study.investigationalDeviceExemptions);
      this.ideGridOptions.setGridColumnWidths();
    }
  }

  onFundingSourceGridReady(params) {
    this.fundingSourceGridOptions.api = params.api;
    this.fundingSourceGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.fundingSourceGridOptions.api.setRowData(this.study.fundingSources);
      this.fundingSourceGridOptions.setGridColumnWidths();
    }
  }

  onConsentFormGridReady(params) {
    this.consentFormGridOptions.api = params.api;
    this.consentFormGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.consentFormGridOptions.api.setRowData(this.study.consentFormTemplates);
      this.consentFormGridOptions.setGridColumnWidths();
    }
  }

  onRecruitmentMaterialGridReady(params) {
    this.consentFormGridOptions.api = params.api;
    this.consentFormGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.consentFormGridOptions.api.setRowData(this.study.recruitmentMaterialTemplates);
      this.consentFormGridOptions.setGridColumnWidths();
    }
  }

  onAttachmentGridReady(params) {
    this.attachmentGridOptions.api = params.api;
    this.attachmentGridOptions.columnApi = params.columnApi;
    if (this.study != null) {
      this.attachmentGridOptions.api.setRowData(this.study.attachments);
      this.attachmentGridOptions.setGridColumnWidths();
    }
  }

  onProtocolDocument(attachment: Attachment) {
    // if there is a Final protocol document, show it, otherwise show the draft document
    if (attachment.final && attachment.final.itemId && attachment.final.itemId !== "") {
      return attachment.final;
    }

    return attachment.draft;
  }
}

import { Component, OnInit } from '@angular/core';
import { DocumentDownloadComponent } from '../';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-document-download-list-cell',
  templateUrl: './document-download-list-cell.component.html',
  styleUrls: ['./document-download-list-cell.component.scss']
})
export class DocumentDownloadListCellComponent implements OnInit {
  params: any;

  constructor() { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }
}
import { Component, OnInit, Input } from '@angular/core';
import { ReviewDates } from '../../models/reviewDates';

@Component({
  selector: 'app-review-dates',
  templateUrl: './review-dates.component.html',
  styleUrls: ['./review-dates.component.scss']
})
export class ReviewDatesComponent implements OnInit {

  @Input() reviewDates: ReviewDates;

  constructor() { }

  ngOnInit() {
  }

}

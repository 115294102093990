import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { UsersModule } from '../users';

import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { OrganizationSearchTypeaheadComponent } from './organization-search-typeahead/organization-search-typeahead.component';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [
    OrganizationDetailsComponent,
    OrganizationListComponent,
    OrganizationSearchTypeaheadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    ToastrModule,
    UsersModule,
    SharedModule
  ],
  exports: [
    OrganizationSearchTypeaheadComponent
  ]
})
export class OrganizationsModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Role, ROLE_METADATA, User } from '../../models';
import { UserService } from '../../data-services';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth';

/**
 * This component is sed to edit the roles of a user.
 */
@Component({
  selector: 'app-user-role-editor',
  templateUrl: './user-role-editor.component.html',
  styleUrls: ['./user-role-editor.component.scss']
})
export class UserRoleEditorComponent implements OnInit {

  /**
   * The user that is targeted for role changes.
   */
  @Input() user: User;

  /**
   * Shows the roles as abbreviations if true, otherwise displays full text
   */
  @Input() abbreviations: boolean;

  /**
   * Meta data for building the UI and tooltip
   */
  roleMetadata = ROLE_METADATA;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService) { }

  ngOnInit() {
    var currentUser = this.authService.currentUser;
    if (!currentUser) {
      this.roleMetadata = [];
    }
    if (!currentUser.isInRole(Role.Administrator)) {
      this.roleMetadata = ROLE_METADATA.slice(1);
    }
  }

  toggleRole(user: User, role: Role) {

    var i = user.roles.findIndex(r => r === role);
    if (i == -1) {
      
      console.debug('assign role', role);
      this.userService.assignRole(user.id, role)
        .subscribe(() => {
          user.roles.push(role);
        }, 
        err => {},
        () => this.toastr.success(`The ${role} role was assigned to ${user.name}.`, "Success!"));

      return;
    }
    
    console.debug('unassign role', role);
    this.userService.unassignRole(user.id, role)
      .subscribe(() => {
        user.roles.splice(i, 1);
      },
      err => {},
      () => this.toastr.success(`The ${role} role was removed from ${user.name}.`, "Success!"));

    return;
  }

  getRoleTooltip(user: User, role: Role) {
    return user.isInRole(role) ? "Unassign" : "Assign";
  }

  

}


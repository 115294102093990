import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Site } from 'src/app/models/site';
import { NgForm, ControlContainer } from '@angular/forms';
import { UserSearchTypeaheadComponent } from 'src/app/users/user-search-typeahead/user-search-typeahead.component';
import { Modification, ModEnrollmentStatus, ModSubjectNotifications } from 'src/app/models/mod';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSearchResult } from 'src/app/data-services';

@Component({
  selector: 'app-modification-editor',
  templateUrl: './modification-editor.component.html',
  styleUrls: ['./modification-editor.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class ModificationEditorComponent implements OnInit {

  @Input() site: Site;
  @Input() modification: Modification;
  @Input() parentForm: NgForm;
  @Input() enrollmentStatus: ModEnrollmentStatus;
  @Input() subjectNotifications: ModSubjectNotifications;
  @ViewChild('typeahead') typeahead: UserSearchTypeaheadComponent;
  modalRef: NgbModalRef;
  
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  onClickShowSearch(searchTarget, targetIndex?) {
    this.modalRef = this.modalService.open(UserSearchTypeaheadComponent);
    switch (searchTarget) {
      case null:
      case "investigator":
        this.modalRef.componentInstance.label = "Search for Investigator by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.principalInvestigator = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
      case "primaryContact":
        this.modalRef.componentInstance.label = "Search for Primary Contact by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.primaryContact = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
      case "proxy":
        this.modalRef.componentInstance.label = "Search for PI Proxy by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.piProxies[targetIndex] = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
    }
  }
  
}

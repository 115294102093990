import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../data-services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  showProgress = false;
  showConfirmation = false;
  showError = false;
  showAccountDoesNotExist = false;
  isApproved;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService
  ) { }

  ngOnInit() {

    // set the value of the reset code if it was supplied via the query string

    var user = this.route.snapshot.paramMap.get('userId');
    var code = this.route.snapshot.queryParamMap.get('code');
    
    if (user && code) {
      this.showProgress = true;
      this.accountService.confirmEmail(user, code)
        .pipe(finalize(() => this.showProgress = false))
        .subscribe((rsp) => {
          this.showConfirmation = true;
          this.isApproved = rsp.isApproved;
        },
        err => {
          if (err.errors && err.errors['']) {
            if (err.errors[''].includes('AccountDoesNotExist')) {
              this.showAccountDoesNotExist = true;
            }
          }
        });
    }
    else {
      this.showError = true;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashComponent } from './splash/splash.component';
import { SharedModule } from '../shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    SplashComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule
  ]
})
export class HomeModule { }

import { Component, OnInit } from '@angular/core';
import { StudyListItem } from '../../models';
import { ResearchService} from '../../data-services';
import { AgGridDefaults, RouterLinkCellComponent } from '../../shared';


@Component({
  selector: 'app-study-list',
  templateUrl: './study-list.component.html',
  styleUrls: ['./study-list.component.scss']
})

export class StudyListComponent implements OnInit {

  studies: StudyListItem[] = [];
  loadComplete: boolean = false;
  hasResearch: boolean = false;
  alertClosed: boolean = false;
  
  researchGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "IRB of Record", field: "irbOfRecord" },
      { headerName: "Study ID", field: "studyId", minWidth: 135, cellRenderer: "routerLinkRenderer", 
        cellRendererParams: { routerLink: ['/research/studies/', '{{exchangeContainerId}}', 'sites', '{{siteExchangeId}}'], displayAttribute: 'studyId' }
      },
      { headerName: "Study Title", field: "title" },
      { headerName: "Site Status", field: "siteStatus" },
      { headerName: "Local PI", field: "sitePI.lastName", minWidth: 100 },
      { headerName: "Last Updated", field: "lastUpdated", type : ["date"] }
    ],
    frameworkComponents: {
      routerLinkRenderer: RouterLinkCellComponent
    }
  });

  constructor(private researchService: ResearchService) { }

  ngOnInit() {
    this.researchService.getStudies()
    .subscribe(
      (stus) => {
        this.loadComplete = true;
        this.studies = stus;
        this.hasResearch = this.studies.length > 0;
    });
  }

  //When grid is displayed following successful retrieval of site data, connect grid data source
  onResearchGridReady(params) {
    this.researchGridOptions.api = params.api;
    this.researchGridOptions.columnApi = params.columnApi;
    this.researchGridOptions.api.setRowData(this.studies);
    this.researchGridOptions.setGridColumnWidths();
  }

  onFirstDataRendered(params, optionsObj) {
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }
}

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { RniDto } from '../../models/rni';
import { ResearchService } from '../../data-services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportableNewInformationEditorComponent } from '../reportable-new-information-editor/reportable-new-information-editor.component';
import { ComponentCanDeactivate } from 'src/app/shared';

@Component({
  selector: 'app-reportable-new-information-create',
  templateUrl: './reportable-new-information-create.component.html',
  styleUrls: ['./reportable-new-information-create.component.scss']
})
export class ReportableNewInformationCreateComponent implements OnInit, ComponentCanDeactivate {

  // Child refernce to indicate the service response.
  @ViewChild(ReportableNewInformationEditorComponent) rniEditor: ReportableNewInformationEditorComponent;

  /**
   * If (and only if) true, allow the user to navigate to a new page without warning of unsaved changes in the page
   */
  allowNavigation: boolean = false;
  /**
  *  The study exchange item Id.
  */
  studyExchangeId: string;

  /**
  *  The rni exchange item Id.
  */
  rniExchangeId: string;

  /**
  *  The site exchange item Id.
  */
  siteExchangeId: string;

  /**
   *  The rni.
   */
  rni: RniDto;

  /**
  * Selections supported by the IRB Exchange for reportable new information category
  */
  categories = ['RISK', 'HARM', 'NON-COMPLIANCE', 'AUDIT', 'REPORT', 'RESEARCHER-ERROR', 'CONFIDENTIALITY',
    'UNREVIEWED-CHANGE', 'INCARCERATION', 'SUBJECT-COMPLAINT', 'SUSPENSION', 'ADVERSE-DEVICE-EFFECT', 'VA-SAE'];

  constructor(private researchService: ResearchService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.siteExchangeId = this.route.snapshot.paramMap.get('siteId');

    this.researchService.getNewRni(this.studyExchangeId)
      .subscribe((rni) => {
        this.rni = rni;
      });
  }

  onSubmit(rni: RniDto) {
    rni.categories = this.processCategoriesPost(rni)
    this.researchService.createRni(this.studyExchangeId, rni)
      .pipe(finalize(() => {
        this.rniEditor.onSubmitted();
      }))
      .subscribe((response: any) => {
        if ("undefined" !== typeof response && "undefined" !== typeof response.rniExchangeId) {
          let parts = response.rniExchangeId.split('/');
          if (parts.length > 0) {
            let partialId = parts[parts.length - 1];
            this.toastr.success("Changes saved", "Success!");
            this.allowNavigation = true;
            this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.siteExchangeId, 'rni', partialId]);
            return;
          }
        }//else undefined or zero length
        this.toastr.error("Error navigating to the New Information Report, returning to Site Details", "Error");
        console.log("Error: response.rniExchangeId missing or invalid. Response = " + JSON.stringify(response));
        this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.siteExchangeId]);
      });
  }

  processCategoriesPost(rni: RniDto) {
    return rni.categories
      .map((v, i) => v ? this.categories[i] : null)
      .filter(v => v !== null);
  }

  //use canDeactivate as a handler for the window.beforeUnload event
  @HostListener('window:beforeunload')
  canDeactivate(){
    //This component is only used for new, unsaved RNIs. If this component is in use, then we have unsaved changes.
    return this.allowNavigation;
  }
}

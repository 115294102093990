import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserContactInfo } from '../../data-services';
import { MessageBoxService, MessageBoxButtons, MessageBoxIcon, MessageBoxResult } from 'src/app/shared';

@Component({
  selector: 'app-user-contact-info-editor',
  templateUrl: './user-contact-info-editor.component.html',
  styleUrls: ['./user-contact-info-editor.component.scss']
})
export class UserContactInfoEditorComponent implements OnInit {

  @Input() user: UserContactInfo;
  @Input() layout: string = "default";
  @Input() editSelf: boolean = false;

  @Output() save = new EventEmitter<UserContactInfo>();

  original: UserContactInfo;

  submitted: boolean;
  theForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private messageBox: MessageBoxService) {

    this.theForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phoneNumber: ['', Validators.required]
    });

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.user) {
      var current = changes.user.currentValue;

      this.original = {
        firstName: current.firstName,
        lastName: current.lastName,
        phoneNumber: current.phoneNumber,
        email: current.email
      };

      var editable = Object.assign({}, this.original);

      this.theForm.reset();
      this.theForm.setValue(editable);
      this.submitted = false;
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.theForm.invalid) {
      return;
    }

    if (this.original.email !== this.email.value) {
      this.messageBox.open({
        message: this.getEmailConfirmationMessage(),
        title: 'Are you sure?',
        buttons: MessageBoxButtons.YesNo,
        icon: MessageBoxIcon.Warning,
        dismissable: false
      }).subscribe((result: MessageBoxResult) => {

        if (result == MessageBoxResult.Yes) {
          this.save.next(this.theForm.value);
        } else {
          this.email.setValue(this.original.email);
        }

      });
    }
    else {
      this.save.next(this.theForm.value);
    }
  }

  get firstName() {
    return this.theForm.controls['firstName'];
  }

  get lastName() {
    return this.theForm.controls['lastName'];
  }

  get email() {
    return this.theForm.controls['email'];
  }

  get phoneNumber() {
    return this.theForm.controls['phoneNumber'];
  }

  private getEmailConfirmationMessage(): string {
    if (this.editSelf) {
      return 'Your email address is used to link your research from sIRB systems to the Huron IRB Exchange. In order to maintain this link, you will need to notify all sIRB institutions of any changes made to your email address so they can update accordingly.';
    }
    return "A user's email address is used to link their research from sIRB systems to the Huron IRB Exchange. In order to maintain this link, all sIRB institutions will need to be notified of changes made to any user's email address so they can update accordingly.";
  }

  isEquivalent(a: any, b: any): boolean {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }


}

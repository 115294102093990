import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Role } from '../models';
import { SignedInUser } from '../models/signed-in-user';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


interface SignInRequest {
  email: string,
  password: string;
  remember: boolean;
}

interface SignInResponse {
  user: SignedInUser;
  token: string;
  expiresAt: number;
  passwordMustBeChanged: boolean;
  isApproved: boolean;
}

interface CheckStatusResponse {
  user: SignedInUser;
  passwordMustBeChanged: boolean;
  isApproved: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private signedOutSource = new Subject<any>();
  private signedInSource = new Subject<SignedInUser>();

  signedOut$ = this.signedOutSource.asObservable();
  signedIn$ = this.signedInSource.asObservable();

  constructor(private http: HttpClient, private modalService:NgbModal) { }

  get isAuthenticated() {
    return Date.now() < this.expiresAt;
  }

  get accessToken(): string {
    return localStorage.getItem('token');
  }

  get expiresAt(): number {
    var expiresAt = localStorage.getItem('expiresAt');
    return expiresAt ? parseInt(expiresAt) : 0;
  }

  get currentUser(): SignedInUser {
    
    if (!this.isAuthenticated) {
      return null;
    }
    
    var currentUser = localStorage.getItem('currentUser');
    return currentUser !== null ? new SignedInUser(JSON.parse(currentUser)) : null;
  }

  get requirePasswordChange(): boolean {
    var val = localStorage.getItem('passwordMustBeChanged');
    return val ? (val === "true") : false;
  }

  get isApproved() {
    var val = localStorage.getItem('isApproved');
    return val ? (val === "true") : false;  
  }

  hasRole(role: Role): boolean {
    if (this.currentUser === null) {
      return false;
    }
    return this.currentUser.roles.indexOf(role) !== -1;
  }

  handleRefresh(token: string) {
    // destructure the token; the header and signature segments aren't used
    var [_h, encodedPayload, _s] = token.split('.');
    var jsonPayload = atob(encodedPayload.replace(/-/g,'+').replace(/_/g, '/'));
    var payload = JSON.parse(jsonPayload);
    
    // the exp claim contains the expiration as the unix epoch (seconds)
    // conver this to milliseconds.
    var expiresAt = payload.exp * 1000;  
    
    localStorage.setItem('token', token);
    localStorage.setItem('expiresAt', expiresAt.toString());
  }

  signIn(request: SignInRequest) {
    return this.http.post<SignInResponse>('/api/auth/sign-in', request, { withCredentials: true })
      .pipe(tap(data => {
        
        localStorage.setItem('token', data.token);
        localStorage.setItem('expiresAt', data.expiresAt.toString());
        localStorage.setItem('currentUser', JSON.stringify(data.user));
        localStorage.setItem('passwordMustBeChanged', data.passwordMustBeChanged.toString());
        localStorage.setItem('isApproved', data.isApproved.toString());

      }),
      tap(data => this.signedInSource.next(data.user)));
  }

  signOut() {
    this.modalService.dismissAll();
    var currentUser = this.currentUser;
        
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('passwordMustBeChanged');
    localStorage.removeItem('isApproved');

    this.signedOutSource.next(currentUser);
  }

  markPasswordChanged() {
    localStorage.removeItem('passwordMustBeChanged');
  }

  checkStatus(): Observable<CheckStatusResponse> {
    return this.http.get<CheckStatusResponse>('/api/auth/check-status')
      .pipe(tap(data => {
        localStorage.setItem('currentUser', JSON.stringify(data.user));
        localStorage.setItem('passwordMustBeChanged', data.passwordMustBeChanged.toString());
        localStorage.setItem('isApproved', data.isApproved.toString());
      }));
  }

  updateUser(user: SignedInUser) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
}

import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessageBoxComponent, MessageBoxButtons, MessageBoxIcon, MessageBoxResult } from './message-box/message-box.component';
import { Observable } from 'rxjs';

const CONFIRM_DEFAULT_TITLE = "Are you sure?"

export interface MessageBoxOptions {
  message: string,
  title?: string,
  buttons?: MessageBoxButtons,
  icon?: MessageBoxIcon,
  dismissable?: boolean
}

@Injectable({
  providedIn: "root"
})
export class MessageBoxService {

  constructor(private modal: NgbModal) {}

  alert(message: string) {
    return this.open({ message, title: null, buttons: MessageBoxButtons.Ok });
  }

  confirm(message: string, title: string = CONFIRM_DEFAULT_TITLE) {
    return this.open({ message, title, buttons: MessageBoxButtons.YesNo, dismissable: false });
  }

  open(options: MessageBoxOptions): Observable<MessageBoxResult> {

    let message = options.message;
    let title = options.title;
    let buttons = options.buttons || MessageBoxButtons.OkCancel;
    let icon = options.icon || MessageBoxIcon.None;
    let dismissable = options.dismissable == null ? true : options.dismissable;

    return Observable.create((observer) => {

      var modalOptions: NgbModalOptions = {
        backdrop: dismissable ? true : 'static'
      };

      var modalRef = this.modal.open(MessageBoxComponent, modalOptions);
      var instance = modalRef.componentInstance;
      instance.message = message;
      instance.title = title;
      instance.buttons = buttons;
      instance.icon = icon;
      instance.dismissable = dismissable;
      
      modalRef.componentInstance.message = message;

      modalRef.result
        .then(
          value => observer.next(value),
          reason => observer.next(MessageBoxResult.Dismiss)
        )
        .then(() => observer.complete());

    });

  }


}
import { Component, OnInit } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import { NgStyle } from '@angular/common';


interface FaqItem
{
  question: string;
  answer: string;
  showAnswer: boolean;
}

// In the future, we should store the FAQ questions
// and answers in the database, so that we can modify
// the list without having to deply new code.
const FAQS: FaqItem[] = [
  { 
    question: 'How much does the Huron IRB Exchange cost my institution?',
    answer: 'As a Participating Site (pSite), usage of the Huron IRB Exchange is <strong><u>free</u></strong>. You simply need to create an account for your institution and any users you would like to have access, and you can start working with the Single IRBs of Record (sIRBs) immediately.',
    showAnswer: false
  },
  {
    question: 'How do I get started?',
    answer: `
      <ul>
        <li>The first step is to <a href="/signup">register your institution</a> to the Huron IRB Exchange.</li>
        <li>After your institution has been registered and confirmed, an Account Manager will be assigned to your institution. More than one individual can have this role and it can be changed at any time. Users with the Account Manager role can approve new user accounts for your institution and assign roles to each user.</li>
        <li>
          <p>New user accounts can be created for your institution using two different options:</p>
          <ul>
            <li>Users can go through the <a href="/signup">registration link</a> to request an account.</li>
            <li>A user with the Account Manager role at each institution can manually create accounts for the appropriate users.</li>
          </ul>
          <br />
        </li>
        <li>As soon as your institution has been registered and accounts created, your Reliance Coordinators and Study Staff can begin their work on the multi-site studies you have been invited to participate in.</li>
      </ul>`,
    showAnswer: false
  },
  {
    question: 'Who are the intended users of the Huron IRB Exchange?',
    answer: `
      <p>User can have more than one role on the Huron IRB Exchange. The 3 roles that can be assigned to a user are:</p>
      <ul>
        <li><strong>Account Manager</strong>: A user with this role can create, approve, and deny user accounts and then add and remove roles from those accounts in the system.</li>
        <li><strong>Reliance Coordinator</strong>: A user with this role is given "global" access to your institution's multi-site studies, as well as act to accept or deny those invitations. After invitation acceptance, the Reliance Coordinator can <strong><u>view and edit all studies</u></strong> for your organization.</li>
        <li><strong>Study Staff</strong>: A user with this role is given access only to studies where they are assigned as the PI or PI proxy. Study Staff can accept or decline the invitation to participate in a study. After invitation acceptance, Study Staff can view and edit the details of any multi-site study to which they are assigned. Study Staff <strong><u>do not have "global" access</u></strong> to all studies across your organization.</li>
      </ul>`,
    showAnswer: false
  },
  {
    question: 'What are some general expectations of each user role in the Huron IRB Exchange?',
    answer: `
    <ul>
      <li><strong>Account Manager</strong>: These users are responsible for ensuring the correct individuals at your institution have access to the Huron IRB Exchange. Account Managers should add and remove accounts and confirm that each account is assigned the correct role.</li>
      <li><strong>Reliance Coordinator</strong>: These users help facilitate the invitation acceptance process and keep track of the sites at your institution in order to ensure appropriate and timely action is taken by your Study Staff. Reliance Coordinators can also serve as a liaison to (or possibly as a member of) the IRB office at your institution. There will likely be details that need to be transferred from the Huron IRB Exchange to your institution's IRB system/process, which the Reliance Coordinator will have the ability to access and document.</li>
      <li><strong>Study Staff</strong>: These users are typically responsible for making the decision to participate in a multi-site study, and once the invitation is accepted, they are stewards of the critical information that needs to be documented and sent to the sIRB for review. Study Staff are responsible for providing the initial site information to the sIRB, as well as any additional information related to Modifications, Continuing Review, or Reportable New Information.
    </ul>`,
    showAnswer: false
  },
  {
    question: 'What IRB Systems are compatible with the Huron IRB Exchange?',
    answer: 'The Huron IRB Exchange can integrate with any IRB system. The latest version of Huron&apos;s IRB solution comes with integration to the Huron IRB Exchange out of the box. Other versions of Huron&apos;s IRB solution or any other IRB solution can integrate with the Huron IRB Exchange using <a href="https://docs.huronirbexchange.com" target="_blank">details provided here</a>.',
    showAnswer: false
  },
  {
    question: 'Who do I contact for support/questions?',
    answer: `
    <ul>
      <li>To assist with functionality questions, we have embedded training through the Huron IRB Exchange in order to assist users with accomplishing the steps they need to take.</li>
      <li>If you experience an issue with the Huron IRB Exchange website, please contact Huron using the contact Huron link.</li>
      <li>If you have questions about the information you receive from an IRB of Record, or a specific issue related to a study, you may need to contact the IRB of Record to resolve the issue.</li>
    </ul>`,
    showAnswer: false
  }
]


@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  iframeHtml: any;
  private videoUrl = 'https://player.vimeo.com/video/269961804';

  faqs : FaqItem[] = FAQS;


  constructor(private embedVideo: EmbedVideoService) { }

  ngOnInit() {
    this.iframeHtml = this.embedVideo.embed_vimeo("269961804", {
      query: { portrait: 0 },
      attr: { width: 550, height: 310 }
    });

  }

  get contactUs(): string {
    // I know this looks ugly, but its done this way for a reason.
    // Build the email address and bind it to the ui via script, so it's not easily scraped by spammers.
    var email = 'huronconsultinggroup.com';
    email = 'HuronIRBExchange' + '@' + email;
    email = 'mailto: ' + email;
    return email;
  }

}

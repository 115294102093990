import { Component, OnInit, ViewChild, ViewChildren, TemplateRef, QueryList } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { PasswordValidation, ValidationProblemDetails } from 'src/app/shared';
import { OrganizationSearchResult, AccountService } from 'src/app/data-services';
import { OrganizationSearchTypeaheadComponent } from 'src/app/organizations';
import { Step, StepListComponent } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/models';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  @ViewChild(StepListComponent) stepList: StepListComponent;
  
  // Since the search typeahead component is in a nested template, it is 
  // not possible to get a reference to the component via a template 
  // reference variable.  As a workaround, we use the ViewChildren query,
  // then subscribe to its changes.  When a user moves to the Institution
  // search view, the subscription will get updated and the we assigned it
  // to the typeahead variable.
  @ViewChildren(OrganizationSearchTypeaheadComponent) typeaheadQuery: QueryList<OrganizationSearchTypeaheadComponent>;
  typeahead: OrganizationSearchTypeaheadComponent;

  steps: Step[] = [
    new Step('Introduction'),
    new Step('Institution search'),
    new Step('Institution information'),
    new Step('Terms of use'),
    new Step('User information'),
    new Step('Confirmation'),
    new Step('Terms of use - readonly', true)
  ];

  @ViewChildren(TemplateRef) stepTemplates: QueryList<TemplateRef<any>>;
  templates: TemplateRef<any>[];

  currentTemplate: TemplateRef<any>;

  /**
   * The registration form.
   */
  signUpForm: FormGroup;

  /**
   * A flag that a user must check when registering a new organization.
   */
  authorizedRep: boolean;

  /**
   * A flag that indicates that an attempt to submit the registration form has occured.
   */
  submitted: boolean;

  /**
   * A flag that indicates that the registration form has been submitted to the server and waiting for a response.
   */
  registering: boolean;

  /**
   * The registered user.
   */
  user: User;

  newOrg: boolean;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,    
    private toastr: ToastrService
  ) {
    this.signUpForm = this.formBuilder.group({
      organizationId: [''],
      organizationName: ['', Validators.required],
      assuranceNumber: [''],
      email: ['', Validators.compose([Validators.required, Validators.email, this.singleLabelDomain()])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(13), PasswordValidation.minUnique(6) ])],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required]
    }, { validator: Validators.compose([PasswordValidation.MatchPassword(), this.requireOneOf('organizationId','organizationName')]) });
  }

  ngOnInit() {
    console.log("[RegistrationComponent::ngOnInit()]")
    
  }

  ngAfterViewInit() {
    console.log("[RegistrationComponent::ngAfterViewInit()]")
    this.templates = this.stepTemplates.toArray();

    setTimeout(() => {
      this.currentTemplate = this.templates[this.stepList.currentIndex];
    });

    this.typeaheadQuery.changes.subscribe(changes => {
      this.typeahead = changes.first;
    });
  }

  cannotFindInstitution(e: MouseEvent) {
    e.preventDefault();
    this.newOrg = true;
    this.stepList.moveTo(2);
    this.currentTemplate = this.templates[this.stepList.currentIndex];
  }

  viewTermsOfUse() {
    this.stepList.moveTo(6, false);
    this.currentTemplate = this.templates[this.templates.length-1];
  }

  next() {

    if (this.stepList.currentIndex === 1) {
      // if the organization is set, then the organization exists
      if (this.organizationAlreadyExists) {
        this.stepList.moveTo(4);
        this.currentTemplate = this.templates[this.stepList.currentIndex];
        return;
      }
    }

    this.stepList.moveNext();
    this.currentTemplate = this.templates[this.stepList.currentIndex];
  }

  get disableNext(): boolean {

    if (this.stepList.currentIndex === 1) {
      return this.typeahead == null || this.isEmptyInputValue(this.typeahead.term) || (this.isEmptyInputValue(this.organizationId.value) && this.isEmptyInputValue(this.organizationName.value));
    }
    if (this.stepList.currentIndex === 2) {
      return this.organizationName.invalid;
    }
    if (this.stepList.currentIndex === 3) {
      return !this.authorizedRep;
    }
    if (this.stepList.currentIndex >= 4) {
      return true;
    }

    return !this.stepList.canMoveNext();
  }

  get disablePrevious(): boolean {

    if (this.stepList.currentIndex >= 5) {
      return true;
    }

    return !this.stepList.canMovePrevious();
  }

  previous() {
    this.stepList.movePrevious();
    this.currentTemplate = this.templates[this.stepList.currentIndex];
  }


  /**
   * Accessor for the organization id form control.
   */
  get organizationId() {
    return this.signUpForm.controls['organizationId'];
  }

  /**
   * Accessor for the organization name form control.
   */
  get organizationName() {
    return this.signUpForm.controls['organizationName'];
  }

  /**
   * Accessor for the first name form control.
   */
  get firstName() {
    return this.signUpForm.controls['firstName'];
  }

  /**
   * Accessor for the last name form control.
   */
  get lastName() {
    return this.signUpForm.controls['lastName'];
  }

  /**
   * Accessor for the phone number form control.
   */
  get phoneNumber() {
    return this.signUpForm.controls['phoneNumber'];
  }

  /**
   * Accessor for the email form control.
   */
  get email() {
    return this.signUpForm.controls['email'];
  }
  
  /**
   * Accessor for the password form control.
   */
  get password() {
    return this.signUpForm.controls['password'];
  }

  /**
   * Accessor for the password confirmation form control.
   */
  get confirmPassword() {
    return this.signUpForm.controls['confirmPassword'];
  }

  
  onSubmit() {
    
    this.submitted = true;

    if (this.signUpForm.invalid) {
      return;
    }

    this.registering = true;


    this.accountService.register(this.signUpForm.value)
      .pipe(finalize(() => {
        this.registering = false;
      }))
      .subscribe((user: User) => {
        this.user = user;

        this.stepList.moveNext();
        this.currentTemplate = this.templates[this.stepList.currentIndex];
      },
      (err: ValidationProblemDetails) => {
        if (err.errors && err.errors['']) {
          
          if (err.errors[''].includes('PasswordMismatch')) {
            this.password.setErrors({ mismatch: true });
          }

          if (err.errors[''].includes('BlackListedPassword')) {
            this.password.setErrors({ blacklisted: true });
          }
        }
      });
  }

  /**
   * This is called when a user selects an institution from the typeahead list
   * 
   * @param event The event received from the NgbTypeahead control.
   */
  onOrganizationSelected(event: OrganizationSearchResult) {
    console.debug("selected", event);
    this.signUpForm.patchValue({ 
      organizationId: event.id,
      organizationName: event.name,
      assuranceNumber: event.assuranceNumber
    });
  }

  clearOrganizationFormFields() {
    console.debug('clearing organization selection');
    this.signUpForm.patchValue({ organizationId: null, organizationName: null, assuranceNumber: null });
  }

  get organizationAlreadyExists(): boolean {
    return !this.isEmptyInputValue(this.organizationId.value);
  }

  reset() {
    this.submitted = false;
    this.user = null;    
    this.signUpForm.reset();
    this.stepList.reset();
    this.currentTemplate = this.templates[this.stepList.currentIndex];
  }

  /**
   * Instructs the server to resend the email confirmation message to the newly registered user.
   * 
   * @param event The DOM event that triggered this function call.
   */
  resend(event: MouseEvent) {
    event.preventDefault(); // prevent navigation when link is clicked
    this.accountService.sendEmailConfirmation(this.user.id).subscribe(() => {
      this.toastr.success('Confirmation email sent.');
    });
  }

  /**
   * A custom valiation which requires at least of the specified controls to have a value.
   * 
   * @param controls A collection of controls to check
   */
  private requireOneOf(...controls: string[]): ValidatorFn
  {
    return (ac: AbstractControl) => {
      var result = controls.some(controlName => {
        return false == this.isEmptyInputValue(ac.get(controlName));
      });

      return result ? null : { requireOneOf: true };
    }
  }

  private isEmptyInputValue(value) {
    return value == null || value.length === 0;
  }

  private singleLabelDomain(): ValidatorFn {
    const regex = /\.[a-zA-Z]{2,6}$/
    return (ac: AbstractControl) => {
        return regex.test(ac.value) ? null : { "sld": true };
    }
  } 

}

import { Component, AfterViewInit, ViewChild, ElementRef, Renderer, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { Study, StudyListItem } from '../../models';
import { ResearchService, FeedbackService } from '../../data-services';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpComponentDialog implements AfterViewInit {

  feedbackForm: FormGroup;
  selectedOption: Number = 0;
  nullStudies = new BehaviorSubject<boolean>(true);

  studies: StudyListItem[] = [];
  loadComplete: boolean = true;
  selectedStudy: StudyListItem;
  isSending: boolean = false;
  studyId: String;
  study: Study;
  hasProxies: boolean;
  proxies: String[] = [];
  valid: boolean;
  currentPage:string;

  @ViewChild("mySelect") select: ElementRef;

  constructor(public modal: NgbActiveModal, private renderer: Renderer, private researchService: ResearchService, public fb: FormBuilder, private route: Router, private feedbackService: FeedbackService, private toastr: ToastrService) {
    //Init form to prevent errors
    this.feedbackForm = this.fb.group({});
  }



  ngAfterViewInit() {
    // use the renderer to interact with the actual DOM Element.
    this.renderer.invokeElementMethod(this.select.nativeElement, 'focus');

    //Get the studies list to show in the dropdown
    this.loadComplete = false;
    this.researchService.getStudies()
      .subscribe(
        (stus) => {
          this.loadComplete = true;
          this.studies = stus;
          //Change the variable value so the listeners can get the new value
          this.nullStudies.next(this.studies.length == 0);
        });
    this.currentPage = this.getCurrentPage();
  }

  onChange(value: Number) {
    this.selectedOption = value;

    if (this.selectedOption == 1) {
      //Close modal
      this.modal.dismiss("Option 1 selected");

      //Get element with thah ID to perform click event.
      let element: HTMLElement = document.getElementById("widgetIcon");
      element.click();
    }
    if (this.selectedOption == 2) {
      //Suscribe to the variable changes
      this.getStudyCount().subscribe(x => {
        this.valid = x;
        //Setup form validation to validate that one study is selected and comment field is not null
        this.feedbackForm = this.fb.group({
          comment: ["", Validators.required],
          selectStudy: [{ value: "", disabled: this.valid}, Validators.required]
        });
      })
    }
    if (this.selectedOption == 3) {
      this.valid = false;
      //Setup form validation to validate that comment field is not null
      this.feedbackForm = this.fb.group({
        comment: ["", Validators.required]
      });
    }
  }

  onSubmit(data) {
    this.isSending = true;

    if (this.selectedOption == 2) {
      //Get value from the select and find the selected study in the list
      var studyId = data.selectStudy;
      this.selectedStudy = this.studies.find(x => x.studyId == studyId);
      //Call API to send email to PI and Primary Contact
      this.onSendStudyEmail(data.comment, this.selectedStudy)
    }
    if (this.selectedOption == 3) {
      //Call API to send email to support
      this.onSendSupportRequest(data.comment, this.currentPage);
    }
  }

  //Method to send a support request to Huron
  onSendSupportRequest(comment: String, selectedPage: String) {
    this.isSending = true;
    this.feedbackService.askSupport(comment, selectedPage)
      .subscribe(
        () => {
          this.isSending = false;
          this.modal.dismiss("Support sent");
          this.toastr.success("Support email sent.", "Success");
        },
        (err) => {
          this.isSending = false;
        }
      )
  }

  //Method to send the email to PI and Primary Contact
  onSendStudyEmail(comment: String, study: StudyListItem) {
    this.feedbackForm.controls.selectStudy.disable();
    this.isSending = true;
    this.feedbackService.sendStudyEmail(comment, study)
      .subscribe(
        () => {
          this.isSending = false;
          this.modal.dismiss("Email sent to study PI and Primary Contact")
          this.toastr.success("Email sent to the IRB of Record.", "Success");
        },
        (err) => {
          this.isSending = false;
        }
      )
  }

  //Method to get the value when the selected option changes
  studyChanged(value: String) {
    this.studyId = value;
    var currentStudy = this.studies.find(x => x.studyId == this.studyId);
    this.study = null;
    this.proxies = [];
    this.researchService.getStudyDetails(currentStudy.exchangeContainerId)
      .subscribe(
        (study) => {
          this.study = study;
          this.hasProxies = this.study.piProxies.length > 0;
          //Concat first and last name and push it to the proxies array
          this.study.piProxies.forEach(x => {
            this.proxies.push(`${x.firstName} ${x.lastName}`);
          });
        });
  }

  //Method to suscribe the data changes
  getStudyCount() {
    return this.nullStudies.asObservable();
  }

   getCurrentPage():string{
    //Get the route from the URL
    let route: string = this.route.url;

    //Split the route to get the url page.
    var splitted = route.split('/');
    route = splitted[1];

    //If url is organizations
    if (route.toUpperCase() == "ORGANIZATIONS") {
      //Check if it's organizations page
      if (splitted.length == 2)
        return "Organizations"
      //Check if it's users page
      if (splitted.length == 3)
        return "Users"
    }

    //If url is research
    if (route.toUpperCase() == "RESEARCH") {
      //Check if it's research page study list
      if (splitted.length == 3 && splitted.includes("studies"))
        return "Research"
      //Check if it's site details page
      if (splitted.length == 6)
        return "Site Details"
      //Check if it's study details page
      if (splitted.length == 4)
        return "Study Details"
      //Check if it's site invitations page
      if (splitted.length == 3 && splitted.includes("invitations"))
        return "Invitations"
      //Check if it's mod page
      if (splitted.includes("mod"))
        return "Modification"
      //Check if it's rni page
      if (splitted.includes("rni"))
        return "RNI"
    }

    //If url is accounts
    if (route.toUpperCase() == "ACCOUNTS") {
      return "Account"
    }
    //If url is homepage
    if (route == "") {
      return "Home"
    }

  }

}

import { Component, OnInit } from '@angular/core';
import { UserService, UserContactInfo } from '../../data-services';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss']
})
export class ProfileEditorComponent implements OnInit {

  user: User;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService) { }

  ngOnInit() {
    var userId = this.route.parent.snapshot.paramMap.get('accountId');
    this.loadUser(userId);
  }

  updateContactInfo(contactInfo: UserContactInfo) {
    this.userService.updateContactInfo(this.user.id, contactInfo)
      .subscribe(() => {
        this.toastr.success(`${this.user.firstName}'s account has been updated successfully`, "Success!");
        this.loadUser(this.user.id);
      });
  }

  loadUser(id: string) {
    this.userService.getUser(id).subscribe(u => {
      this.user = u;
    })
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppcuesService } from './shared/appcues.service';
import { AuthService } from './auth';
import { Subscription, timer } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Role } from './models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  title = 'irb-exchange-ui';

  private signedInSubscription: Subscription;
  private signedOutSubscription: Subscription;
  private automaticSignOutSubscription: Subscription;

  constructor(
    private auth: AuthService,
    private appcues: AppcuesService,
    private router: Router) {

  }

  ngOnInit(): void {

    // Notify appcues to update the current page and check if there's 
    // any content that should be shown.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.appcues.page();
      }
    })
    
    this.signedInSubscription = this.auth.signedIn$.subscribe(user => {
      // notify appcues to track user upon sign in
      this.identifyUser()
      this.setupAutomaticSignOut();
    });

    // notify appcues stop tracking the user when signed out
    this.signedOutSubscription = this.auth.signedOut$.subscribe(user => this.appcues.reset());
    
    // if already signed in, track the current user.
    if (this.auth.isAuthenticated) {
      this.identifyUser();
      this.setupAutomaticSignOut();
    } else {
      //this.appcues.anonymous();
    }
  }

  ngOnDestroy(): void {
    this.signedInSubscription.unsubscribe();
    this.signedOutSubscription.unsubscribe();
    this.automaticSignOutSubscription.unsubscribe();
  }

  private setupAutomaticSignOut() {
    
    // unsubscribe if a subscription exists
    if (this.automaticSignOutSubscription && this.automaticSignOutSubscription.closed == false) {
      this.automaticSignOutSubscription.unsubscribe();
    }

    this.automaticSignOutSubscription = timer(this.auth.expiresAt - Date.now()).subscribe(() => {
      // Very likely that this token has been refreshed since this timer was initially setup.
      // If the user is still authenticated, re-initialize this timer, otherwise sign out.
      if (this.auth.isAuthenticated) {
        this.setupAutomaticSignOut();
      } else {
        this.auth.signOut();
      }
    });
  }

  private identifyUser() {

    const currentUser = this.auth.currentUser;

    this.appcues.identify(currentUser.id, {
      name: currentUser.name,
      email: currentUser.email,
      organizationId: currentUser.organizationId,
      organizationName: currentUser.organizationName,
      isStudyStaff: currentUser.isInRole(Role.StudyStaff),
      isRelianceCoordinator: currentUser.isInRole(Role.RelianceCoordinator),
      isAccountManager: currentUser.isInRole(Role.AccountManager),
      isApproved: this.auth.isApproved,
      createdAt: currentUser.createdAt,
      lastSignInAt: currentUser.lastSignInAt
    });

  }

}

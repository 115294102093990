import { Component, OnInit, ViewChild } from '@angular/core';
import { User, Organization, Role } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../../data-services';
import { FormControl, FormBuilder } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserDialogComponent, UserListComponent } from '../../users';
import { AppcuesService } from 'src/app/shared/appcues.service';
import { AuthService } from 'src/app/auth';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit {
  @ViewChild('usersList') usersList:UserListComponent;
  /**
   * The organization
   */
  organization: Organization;
  
  /**
   * The collection of users within the organization.
   */
  users: User[];

  /**
   * 
   */
  filterForm = this.fb.group({
    name: [''],
    pendingApproval: [false],
    disabled: [false]
  });

  /**
   * The filter input control.
   */
  usersFilter = new FormControl('usersFilter');
  
  /**
   * The filtered collection of users. This is bound to the user list view child.
   */
  filteredUsers: User[];

  constructor(
    private route: ActivatedRoute,
    private orgService: OrganizationService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private appcues: AppcuesService,
    private auth: AuthService,
    private toastr: ToastrService,
    private router: Router) { }

  get isAdmin() {
    return this.auth.hasRole(Role.Administrator);
  }

  /**
   * Gets the value of the disabled user filter. When true, the user
   * list will only display users which are currently disabled, otherwise
   * the filter will not be applied to the user list.
   */
  get disabled() {
    return this.filterForm.value.disabled;
  }
  /**
   * Sets the value of the disabled user filter flag.
   */
  set disabled(value: boolean) {
    this.filterForm.patchValue({ disabled: value });
  }

  /**
   * Get the value of the pending approval user filter. When true, the user
   * list will only display users which are currently pending approval, 
   * otherwise the filter will not be applied to the user list.
   */
  get pendingApproval() {
    return this.filterForm.value.pendingApproval;
  }
  /**
   * Sets the value of the pending approval user filter.
   */
  set pendingApproval(value: boolean) {
    this.filterForm.patchValue({ pendingApproval: value });
  }

  ngOnInit() {

    var orgId = this.route.snapshot.paramMap.get('orgId')

    this.orgService.getOrganization(orgId)
      .subscribe(org => this.organization = org);

    this.orgService.getUsers(this.route.snapshot.params.orgId)
      .subscribe(users => {
        this.users = users;
        this.filteredUsers = users;
        if(this.usersList){
          this.usersList.onDataUpdated(this.filteredUsers);
        }
      });

    
    this.filterForm.valueChanges
      .pipe(
        tap(value => console.debug(`users filter`, value)),
        map(value => { 
          var term = value.name.trim().toLowerCase();
          return this.users
            .filter(u => this.pendingApproval ? !u.isApproved : true)
            .filter(u => this.disabled ? u.isLocked : true)
            .filter(u => {
              return u.name.toLowerCase().indexOf(term) != -1;
            })
        }), // match users by name 
        tap(users => console.debug('filtered users:', users)),
      )
      .subscribe(users => {
        this.filteredUsers = users;
        this.usersList.onDataUpdated(this.filteredUsers);
      });
  }

  togglePending() {
    var control = this.filterForm.controls['pending'];
    control.setValue(!control.value);
  }

  onAddUser() {

    // notify appcues when the add user button is clicked.

    this.appcues.track("Add user clicked");

    const modalRef = this.modalService.open(CreateUserDialogComponent, { backdrop: 'static'});
    modalRef.componentInstance.organizationId.value = this.organization.id;
    modalRef.componentInstance.organizationName.value = this.organization.name;
    modalRef.result.then((user: User) => {
      
      // find location to insert the user into the list.
      var i = this.users.findIndex(u => {
        return (user.lastName < u.lastName) || (user.lastName == u.lastName && user.firstName < u.firstName);
      });

      // insert the user
      this.users.splice(i, 0, user);
      this.usersList.onDataUpdated(this.filteredUsers);
    }).catch(() => { /* cancelled by the user */ });
  }

  approve() {

    this.orgService.approveAccess(this.organization.id)
      .subscribe(data => {
        this.organization.isApproved = true;
        this.toastr.success(`${this.organization.name} has been approved to access the system.`, 'Success');
      })

  }

  deny() {
    this.orgService.denyAccess(this.organization.id)
      .subscribe(data => {
        this.toastr.success(`${this.organization.name} has been denied access and removed from the system.`, 'Success');
        this.router.navigate(['../', { relativeTo: this.route } ]);
      });
  }

}

import { Component, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/data-services';
import { Subscription, timer, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const POLLING_INTERVAL = 10 * 60 * 1000; // 10 minutes

@Component({
  selector: 'app-pending-approval-count',
  templateUrl: './pending-approval-count.component.html',
  styleUrls: ['./pending-approval-count.component.scss']
})
export class PendingApprovalCountComponent implements OnDestroy {

  count: number;

  pollSubscription: Subscription;
  actionSubscription: Subscription;

  constructor(private userService: UserService) { 
    
    this.pollSubscription = timer(0, POLLING_INTERVAL)
      .pipe(switchMap(() => this.userService.getPendingApprovalCount(true)))
      .subscribe(value => this.count = value);

    // decrement the count when an a user is approved or denied
    this.actionSubscription = merge(
      this.userService.userApproved$,
      this.userService.userDenied$)
      .subscribe(_ => this.count--); 
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
    this.pollSubscription.unsubscribe();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, timer, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const POLLING_FREQUENCY = 30000;

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent implements OnInit, OnDestroy {
    
  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    var returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.subscription = timer(0, POLLING_FREQUENCY).pipe(switchMap(() => this.authService.checkStatus()))
      .subscribe(status => {
        if (status.isApproved) {
          this.router.navigateByUrl(returnUrl);
        }
      })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
}

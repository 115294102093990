import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ExamplesRoutingModule } from './examples-routing.module';
import { DatePipeExampleComponent } from './date-pipe-example/date-pipe-example.component';
import { ForbiddenExampleComponent } from './forbidden-example/forbidden-example.component';
import { OrganizationSearchTypeaheadExampleComponent } from './organization-search-typeahead-example/organization-search-typeahead-example.component';
import { OrganizationsModule } from '../organizations';
import { LoadingExampleComponent } from './loading-example/loading-example.component';
import { SharedModule } from '../shared';
import { MessageBoxExampleComponent } from './message-box-example/message-box-example.component';

@NgModule({
  declarations: [DatePipeExampleComponent, ForbiddenExampleComponent, OrganizationSearchTypeaheadExampleComponent, LoadingExampleComponent, MessageBoxExampleComponent],
  imports: [
    CommonModule,
    FormsModule,
    ExamplesRoutingModule,
    OrganizationsModule,

    SharedModule
  ]
})
export class ExamplesModule { }

import { Component, OnInit } from '@angular/core';
import { OrganizationSearchResult } from '../../data-services';

@Component({
  selector: 'app-organization-search-typeahead-example',
  templateUrl: './organization-search-typeahead-example.component.html',
  styleUrls: ['./organization-search-typeahead-example.component.scss']
})
export class OrganizationSearchTypeaheadExampleComponent implements OnInit {

  selected: OrganizationSearchResult;

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.selected = null;
  }

  onSelect(org: OrganizationSearchResult) {
    this.selected = org;
  }
}

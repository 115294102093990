import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { FeedbackService, UserService } from '../../data-services';
import { Role } from 'src/app/models';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HelpComponentDialog } from '../help-dialog/help-dialog.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  isOpen = false;

  showOrganizations: boolean = false;
  showUsers: boolean = false;
  showInvitations: boolean = false;
  showResearch: boolean = false;
  name: string;

  private subscriptions: Subscription[] = [];

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private router: Router,
    private feedbackService: FeedbackService,
    private toastr: ToastrService,
    private modal: NgbModal) { }

  ngOnInit() {


    this.buildMenu();

    // rebuild the nav menu when a user signs out
    this.subscriptions.push(this.auth.signedOut$.subscribe(() => {
      this.buildMenu();
      this.router.navigate(['/']);
    }));

    this.subscriptions.push(this.auth.signedIn$.subscribe(user => {
      this.name = user.name;
    }));

    this.subscriptions.push(this.userService.userContactInfoUpdated$.subscribe(userContactInfo => {
      this.name = `${userContactInfo.firstName} ${userContactInfo.lastName}`;
    }));

    if (this.auth.isAuthenticated) {
      this.userService.getUser(this.auth.currentUser.id).subscribe(user => {
        this.name = user.name;
      })
    }

    this.subscriptions.push(this.userService.userRoleAssigned$
      .pipe(filter(value => value.id === this.auth.currentUser.id))
      .subscribe(e => {
        var user = this.auth.currentUser;
        user.roles.push(e.role);
        this.auth.updateUser(user);
        this.buildMenu();
      }));

    this.subscriptions.push(this.userService.userRoleUnassigned$
      .pipe(filter(value => value.id === this.auth.currentUser.id))
      .subscribe(e => {
        var user = this.auth.currentUser;
        user.roles = user.roles.filter((value) => value != e.role);
        this.auth.updateUser(user);
        this.buildMenu();
      }));

  }

  ngOnDestroy() {
    for(var i=0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }

  openFeedbackDialog() {
    this.modal.open(FeedbackDialogComponent).result.then(data => {

      this.feedbackService.provideFeedback(data.comment, data.rating)
        .subscribe(() => this.toastr.success("Your feedback is greatly appreciated.", "Thank you!"));

    }).catch(err => {
      console.debug('feedback canceled', err);
    })
  }

  openHelpDialog() {
    this.modal.open(HelpComponentDialog);
  }
  
  toggle() {
    this.isOpen = !this.isOpen;
  }

  signOut() {
    this.router.navigate(['/signOut']);
  }

  
  buildMenu() {

    var currentUser = this.auth.currentUser;
    
    if (currentUser) {
      this.showOrganizations = currentUser.isInRole(Role.Administrator);
      this.showUsers = currentUser.isInAnyRole([Role.Administrator, Role.AccountManager]);
      this.showResearch = currentUser.isInAnyRole([Role.RelianceCoordinator, Role.StudyStaff]);
      this.showInvitations = currentUser.isInAnyRole([Role.RelianceCoordinator, Role.StudyStaff]);
    } 
    else {
      this.showOrganizations = false;
      this.showUsers = false;
      this.showResearch = false;
      this.showInvitations = false;
    }
  }
}

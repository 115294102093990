import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-router-link-cell',
  templateUrl: './router-link-cell.component.html',
  styleUrls: ['./router-link-cell.component.scss']
})
export class RouterLinkCellComponent implements AgRendererComponent  {

  constructor() { }

  params: any;
  routerLinkArray = [];
  displayValue = "";

  agInit(params: any): void {
    this.params = params;
    this.setRouterLinkArray();
    this.setDisplay();
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setRouterLinkArray();
    this.setDisplay();
    return true;
  }

  setRouterLinkArray(){
    this.routerLinkArray = [];
    this.routerLinkArray = this.params.routerLink.map(e => {
      if(e.startsWith('{{') && e.endsWith('}}')){
        return this.params.data[e.substring(2, e.length-2)];
      }else{
        return e;
      }
    });
  }

  setDisplay(){
    this.displayValue = this.params.data[this.params.displayAttribute];
  }
}

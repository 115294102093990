import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppcuesService {

  private Appcues: any;

  constructor(@Inject(DOCUMENT) document: Document)  { 
    this.Appcues = (<any>document.defaultView).Appcues;
  }

  anonymous() {
    this.Appcues.anonymous();
  }

  identify(userId: string, properties?: {}) {
    this.Appcues.identify(userId, properties);
  }

  page() {
    this.Appcues.page();
  }

  track(event: string, properties?: {}) {
    this.Appcues.track(event, properties);
  }

  user(): Observable<any> {
    return from(this.Appcues.user());
  }

  reset() {
    this.Appcues.reset();
  }

  
}

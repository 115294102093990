import { OnInit, Component, Inject } from '@angular/core';
import { AppcuesService } from '../appcues.service';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'appcues-widget',
  templateUrl: './appcues-widget.component.html',
  styleUrls: ['./appcues-widget.component.scss']
})
export class AppcuesWidgetComponent implements OnInit {

  private AppcuesWidget: any;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private appcues: AppcuesService
  ) { 
    this.AppcuesWidget = (<any>document.defaultView).AppcuesWidget;
  }

  ngOnInit() {

    var options = {
      header: null,
      footer: null,
      position: null
    };

    this.appcues.user().subscribe(user => {
      this.AppcuesWidget(user).init("#widget", options);
    });
  }
}

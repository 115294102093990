import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanDeactivate } from '@angular/router';
import { Injectable} from '@angular/core';
import { MessageBoxResult, MessageBoxIcon, MessageBoxButtons } from './message-box/message-box.component';
import { MessageBoxService } from './message-box.service';
import { AuthService } from 'src/app/auth';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private messageBox: MessageBoxService, private auth: AuthService) { }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if the user is alredy signed out, just allow deactivation. They're probably being redirected to home or login.
    // if there are no pending changes, just allow deactivation. else confirm first    
    if (!this.auth.isAuthenticated || component.canDeactivate()){
      return true;
    }
    else return this.messageBox.open({
      message: 'You have unsaved changes to this page. Press No to go back, or Yes to proceed and lose these changes.',
      title: 'Abandon Changes?',
      icon: MessageBoxIcon.Warning,
      buttons: MessageBoxButtons.YesNo,
      dismissable: true
    }).pipe(map(x => x == MessageBoxResult.Yes));
  }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../data-services';
import { finalize } from 'rxjs/operators';
import { PasswordValidation, ValidationProblemDetails } from 'src/app/shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  showConfirmation = false;
  submitting = false;
  codeInputType = 'hidden';
  errorMessage = null;
  invalidToken =false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private accountService: AccountService
  ) { 

    this.resetPasswordForm = this.fb.group({
      code: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(13), PasswordValidation.minUnique(6)])],
      confirmPassword: ['', Validators.required]
    }, { validator: Validators.compose([PasswordValidation.MatchPassword()]) });

  }

  ngOnInit() {
    
    // set the value of the reset code if it was supplied via the query string
    var code = this.route.snapshot.queryParamMap.get('code');
    if (code) {
      this.resetPasswordForm.patchValue({ code: code });  
    }

    // set the value of the email if supplied via query string
    var email = this.route.snapshot.queryParamMap.get('email');
    if (email) {
      this.resetPasswordForm.patchValue({ email: email });
    }

    // Auto-confirm email if not confirmed by user
    this.accountService.autoConfirmEmail(email)
    .subscribe();

  }

  get preventSubmit() {
    return this.submitting;
  }

  onSubmit() {
    this.submitting = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.accountService.resetPassword(this.resetPasswordForm.value)
      .pipe(finalize(() => this.submitting = false)) 
      .subscribe(() => {
        this.showConfirmation = true;
      }, 
      (err: ValidationProblemDetails) => {
        if (err.errors && err.errors['']) {
          
          if (err.errors[''].includes('PasswordMismatch')) {
            this.password.setErrors({ mismatch: true });
          }

          if (err.errors[''].includes('BlackListedPassword')) {
            this.password.setErrors({ blacklisted: true });
          }

          if (err.errors[''].includes('InvalidToken')) {
            this.invalidToken =true;
          }
        }
      });
  }

  /**
  * Accessor for the password form control.
  */
  get password() {
    return this.resetPasswordForm.controls['password'];
  }

  /**
  * Accessor for the password confirmation form control.
  */
  get confirmPassword() {
    return this.resetPasswordForm.controls['confirmPassword'];
  }

  /**
  * Accessor for the email form control.
  */
  get email() {
    return this.resetPasswordForm.controls['email'];
  }

}

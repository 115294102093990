import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { MarkdownModule } from 'ngx-markdown';

import { OrganizationsModule } from '../organizations';
import { UsersModule } from '../users';

import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { SharedModule } from '../shared';
import { RegistrationComponent } from './registration/registration.component';
import { SignOutComponent } from './sign-out/sign-out.component';


@NgModule({
  declarations: [
    AccountDetailsComponent,
    AccountManagerComponent,
    ChangePasswordComponent,
    ConfirmEmailComponent,
    ForgotPasswordComponent,
    PendingApprovalComponent,
    ProfileEditorComponent,
    ResetPasswordComponent,
    SignInComponent,
    SignUpComponent,
    ForceChangePasswordComponent,
    RegistrationComponent,
    SignOutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MarkdownModule.forChild(),

    NgbModule,
    ToastrModule,

    OrganizationsModule,
    UsersModule,
    SharedModule
  ]
})
export class AccountsModule { }

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Site, SiteStatus } from 'src/app/models/site';
import { DeleteRowComponent, DocumentUploadListCellComponent, DocumentDownloadListCellComponent, DocumentUploadCellComponent, AgGridDefaults, DocumentDownloadCellComponent } from '../../shared';
import { Router, ActivatedRoute } from '@angular/router';
import { ModEnrollmentStatus, ModSubjectNotifications, Modification, EnrollmentStatus, SubjectNotifications, ModificationStatus } from '../../models/mod';
import { UserSearchResult } from 'src/app/data-services';
import { NgbTabset, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { UserSearchTypeaheadComponent } from 'src/app/users/user-search-typeahead/user-search-typeahead.component';
import { ObjectUtils } from 'src/app/shared/object-utils';

@Component({
  selector: 'app-site-modification-editor',
  templateUrl: './site-modification-editor.component.html',
  styleUrls: ['./site-modification-editor.component.scss']
})
export class SiteModificationEditorComponent implements OnInit {
  @ViewChild('tabs') tabs: NgbTabset;
  @ViewChild('modForm') modForm: NgForm;
  @ViewChild('typeahead') typeahead: UserSearchTypeaheadComponent;
  modalRef: NgbModalRef;
  
  /**
   *  The modification, being passed on to this component from it's parent.
   */
  @Input() modification: Modification;

  /**
  *  The form submit event.
  */
  @Output() submitMod = new EventEmitter<Modification>();

  /**
   *  The modification status
   */
  modificationStatus = ModificationStatus;

  studyExchangeId: string;
  siteExchangeId: string;
  site: Site;
  enrollmentStatus: ModEnrollmentStatus;
  subjectNotifications: ModSubjectNotifications;
  saving: boolean = false;
  sending: boolean = false;
  canEdit: boolean = true;
  merging: boolean = false;
  canReview: boolean = false;
  pageValidationErrors: string[] = [];
  private submitAction: string;

  //stores unaletered copy of the data for use in change detection
  originalModification: any;
  originalNotifications: any;
  originalEnrollment: any;

  //funding source grid configuration
  fundingSourceGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"]},
      { headerName: "Name", field: "name", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "documents", editable: x => this.getCanEdit(), sortable: false, filter: false, autoHeight: true,
        cellEditor: "documentUploadEditor",
        cellRenderer: "documentDownloadListRenderer"
      },
      {
        headerName: "Final", field: "finalDocuments", editable: false, autoHeight: true, type: ["noSort"],
        cellRenderer: "documentDownloadListRenderer"
      }
    ],
    frameworkComponents: {
      documentUploadEditor: DocumentUploadListCellComponent,
      documentDownloadListRenderer: DocumentDownloadListCellComponent,
      deleteRowRenderer: DeleteRowComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  attachmentGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", sortable: false, filter: false,
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() },
      {
        headerName: "Final", field: "final", sortable: false, filter: false,
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion",  type: ["nonEditable"] }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent,
      documentDownloadRenderer: DocumentDownloadCellComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  private consentFormsGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion", type: ["nonEditable"] }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent,
      documentDownloadRenderer: DocumentDownloadCellComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  private recruitmentMaterialsGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() },
      {
        headerName: "Final", field: "final", type: ["noSort"],
        cellRenderer: "documentDownloadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "finalVersion",  type: ["nonEditable"] }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent,
      documentDownloadRenderer: DocumentDownloadCellComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit() {
    //pull values from the url using param name assigned in app-routing module
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.siteExchangeId = this.route.snapshot.paramMap.get('siteId');

    this.initForm(this.modification);
  }

  /**
  * To be called by parent component on availability of data, or within this component from ngOnInit(). Data needs to be reinitialized from
  * the results of a new GET following successful POST of changes, resetting to a clean state which matches the server data.
  */
  initForm(mod: Modification){
    this.modification = mod;
    this.site = this.modification.draftSite;

    //Add empty Person object to the PI Proxies array to provide something for our form to bind to. The array contains 0-N proxies, 0 won't work for our html template.
    if (this.site.piProxies == null || this.site.piProxies.length == 0) {
      this.site.piProxies = [{
        firstName: "",
        lastName: "",
        email: ""
      }];
    }

    this.ProcessEnrollmentStatusGet();
    this.ProcessNotificationSubjectGet();

    //stash an unaltered copy of the form data, completely independant from the rni and from editable form data, to use in change detection
    this.originalModification = ObjectUtils.deepCopy(this.modification);
    this.originalEnrollment = ObjectUtils.deepCopy(this.enrollmentStatus);
    this.originalNotifications = ObjectUtils.deepCopy(this.subjectNotifications);

    this.setPermissions();
    this.applyPermissionsToGrids();
    this.RefreshGridView();
  }

  setPermissions() {
    //Hide/show of page content
    if (this.modification.reviewInformation != null && this.modification.reviewInformation.correspondenceLetter != null &&
      this.modification.reviewInformation.correspondenceLetter.fileName != null) {
      this.canReview = true;
    }
    //List of editable states
    switch (this.modification.status) {
      case SiteStatus.draft:
      case SiteStatus.pending:
        this.canEdit = true;
        break;
      default:
        this.canEdit = false;
        break;
    }
  }

  applyPermissionsToGrids() {
    if (this.fundingSourceGridOptions.columnApi) {
      this.fundingSourceGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if (this.attachmentGridOptions.columnApi) {
      this.attachmentGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if(this.consentFormsGridOptions.columnApi){
      this.consentFormsGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if(this.recruitmentMaterialsGridOptions.columnApi){
      this.recruitmentMaterialsGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
  }

  // click events
  onSaveDraft() {
    this.submitAction = "save";
  }

  onSaveAndSend() {
    this.submitAction = "saveAndSend";
  }

  // POST
  onSubmit() {
    if (this.submitAction === "save") {
      this.onSave();
    } else if (this.submitAction === "saveAndSend") {
      this.onSendToSirb();
    }
  }

  onSendToSirb() {
    if(this.validatePage()){
      this.sending = true;
      this.ProcessEnrollmentStatus();
      this.ProcessNotificationSubject();
      this.modification.grantAccessToIrb = true;
      this.submitMod.emit(this.modification);
    }
  }

  onSave() {
    if(this.validatePage()){
      this.saving = true;
      this.ProcessEnrollmentStatus();
      this.ProcessNotificationSubject();
      this.modification.grantAccessToIrb = false;
      this.submitMod.emit(this.modification);
    }
  }

  onClose() {
    this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.siteExchangeId], { fragment: 'mod-tab' });
  }

  //page validation function to capture errors in grid data entry or perform other delayed validation.
  //Don't use this for simple input fields which are always visible, use reactive form validators instead
  validatePage(): boolean {
    this.pageValidationErrors = [];
    //#region attachment validation: draft doc required, no blank rows
    let triggeredAttachmentDraftMessage = false;
    let autoPopulatedDraftName = false;
    let blankAttachments = [];
    for (let att of this.site.attachments) {
      if (!att.delete) {
        //if anything filled out
        if (att.id || att.category || att.draftVersion || att.draftName || att.finalVersion || att.finalName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") { //and the draft file is missing
            if (!triggeredAttachmentDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Attachment");
              triggeredAttachmentDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") { // Draft is filled out  
          // Auto-populate the draftName with filename for display, though service end point handle it.
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        } 
        else { //nothing filled out, go ahead and just remove it
          //except we cant remove it now or we'll mess up the iterator. Remove them all when we're done here.
          blankAttachments.push(att);
        }
      }
      //else deleted, do nothing to validate it, the server will remove it
    }
    for (let blank of blankAttachments) {
      this.site.attachments.splice(this.site.attachments.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankAttachments.length > 0 || autoPopulatedDraftName) {
      this.attachmentGridOptions.api.setRowData(this.site.attachments);
      this.attachmentGridOptions.setGridColumnWidths();
    }
    //#endregion
    //#region ConsentForms validation: draft doc required, no blank rows
    let triggeredConsentFormsDraftMessage = false;
    let blankConsentForms = [];
    autoPopulatedDraftName = false;
    for(let att of this.site.consentForms){
      if(!att.delete){
        if (att.id || att.draftVersion || att.draftName || att.finalVersion || att.finalName){
          if(att.draft == null || att.draft.fileName == null || att.draft.fileName == ""){ 
            if(!triggeredConsentFormsDraftMessage){
              this.pageValidationErrors.push("A Draft file must be selected for each Consent forms");
              triggeredConsentFormsDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") {       
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        } 
        else{
          blankConsentForms.push(att);
        }
      }
    }
    for(let blank of blankConsentForms){
      this.site.consentForms.splice(this.site.consentForms.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankConsentForms.length > 0 || autoPopulatedDraftName) {
      this.consentFormsGridOptions.api.setRowData(this.site.consentForms);
      this.consentFormsGridOptions.setGridColumnWidths();
    }
    //#endregion

    //#region RecruitmentMaterials: draft doc required, no blank rows
    let triggeredRecruitmentDraftMessage = false;
    let blankRecruitmentMaterials = [];
    autoPopulatedDraftName = false;
    for(let att of this.site.recruitmentMaterials){
      if(!att.delete){
        if (att.id || att.draftVersion || att.draftName || att.finalVersion || att.finalName){
          if(att.draft == null || att.draft.fileName == null || att.draft.fileName == ""){ 
            if(!triggeredRecruitmentDraftMessage){
              this.pageValidationErrors.push("A Draft file must be selected for each Recruitment materials");
              triggeredRecruitmentDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") {       
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        } 
        else{
          blankRecruitmentMaterials.push(att);
        }
      }
    }
    for(let blank of blankRecruitmentMaterials){
      this.site.recruitmentMaterials.splice(this.site.recruitmentMaterials.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankRecruitmentMaterials.length > 0 || autoPopulatedDraftName) {
      this.recruitmentMaterialsGridOptions.api.setRowData(this.site.recruitmentMaterials);
      this.recruitmentMaterialsGridOptions.setGridColumnWidths();
    }
    //#endregion

    //#region funding source validation: no blank rows
    let blankFundingSources = [];
    for (let fs of this.site.fundingSources) {
      if (!fs.delete) {
        //if nothing filled out and no id, then its a blank, new row. Remove that junk.
        //again, save the removal until the end to avoid angering the iterator
        if (!(fs.id || fs.name || (fs.documents && fs.documents.length > 0))) {
          blankFundingSources.push(fs);
        }
      }
    }

    for (let blank of blankFundingSources) {
      this.site.fundingSources.splice(this.site.fundingSources.indexOf(blank), 1);
    }
    if (blankFundingSources.length > 0) {
      this.fundingSourceGridOptions.api.setRowData(this.site.fundingSources);
      this.fundingSourceGridOptions.setGridColumnWidths();
    }
    //#endregion

    if (triggeredAttachmentDraftMessage || triggeredConsentFormsDraftMessage || triggeredRecruitmentDraftMessage) {
      this.tabs.select('attachment-tab');
      return false;
    }
    return true;
  }

  ProcessEnrollmentStatusGet() {

    this.enrollmentStatus = {
      NONE_ENROLLED: false,
      CURRENT_ENROLLED: false,
      ENROLLMENT_CLOSED: false,
      INTERVENTIONS_COMPLETED: false,
      INFO_COLLECTION_COMPLETE: false
    }

    for (var enrollment of this.modification.enrollmentStatus) {
      switch (enrollment) {
        case EnrollmentStatus.NONE_ENROLLED:
          this.enrollmentStatus.NONE_ENROLLED = true;
          break;
        case EnrollmentStatus.CURRENT_ENROLLED:
          this.enrollmentStatus.CURRENT_ENROLLED = true;
          break;
        case EnrollmentStatus.ENROLLMENT_CLOSED:
          this.enrollmentStatus.ENROLLMENT_CLOSED = true;
          break;
        case EnrollmentStatus.INTERVENTIONS_COMPLETED:
          this.enrollmentStatus.INTERVENTIONS_COMPLETED = true;
          break;
        case EnrollmentStatus.INFO_COLLECTION_COMPLETE:
          this.enrollmentStatus.INFO_COLLECTION_COMPLETE = true;
          break;
        default:
      }
    }
  }

  ProcessNotificationSubjectGet() {

    this.subjectNotifications = {
      CURRENT_SUBJECTS: false,
      FORMER_SUBJECTS: false
    }

    for (var subject of this.modification.subjectNotifications) {
      switch (subject) {
        case SubjectNotifications.CURRENT_SUBJECTS:
          this.subjectNotifications.CURRENT_SUBJECTS = true;
          break;
        case SubjectNotifications.FORMER_SUBJECTS:
          this.subjectNotifications.FORMER_SUBJECTS = true;
          break;
      }
    }
  }

  //POST
  ProcessEnrollmentStatus() {

    this.modification.enrollmentStatus = [];
    if (this.enrollmentStatus.CURRENT_ENROLLED) {
      this.modification.enrollmentStatus.push(EnrollmentStatus.CURRENT_ENROLLED);
    }
    if (this.enrollmentStatus.NONE_ENROLLED) {
      this.modification.enrollmentStatus.push(EnrollmentStatus.NONE_ENROLLED);
    }
    if (this.enrollmentStatus.ENROLLMENT_CLOSED) {
      this.modification.enrollmentStatus.push(EnrollmentStatus.ENROLLMENT_CLOSED);
    }
    if (this.enrollmentStatus.INFO_COLLECTION_COMPLETE) {
      this.modification.enrollmentStatus.push(EnrollmentStatus.INFO_COLLECTION_COMPLETE);
    }
    if (this.enrollmentStatus.INTERVENTIONS_COMPLETED) {
      this.modification.enrollmentStatus.push(EnrollmentStatus.INTERVENTIONS_COMPLETED);
    }

  }

  //POST
  ProcessNotificationSubject() {

    this.modification.subjectNotifications = [];
    if (this.subjectNotifications.CURRENT_SUBJECTS) {
      this.modification.subjectNotifications.push(SubjectNotifications.CURRENT_SUBJECTS);
    }
    if (this.subjectNotifications.FORMER_SUBJECTS) {
      this.modification.subjectNotifications.push(SubjectNotifications.FORMER_SUBJECTS);
    }
  }

  //add new row to site.fundingSources, then inform the grid that there's been an update
  addFundingSourceRow() {
    let newRow = {
      delete: false,
      id: "",
      name: "",
      documents: [],
      finalDocuments: [],
      fundingSourceInitialSiteId: ""
    };

    this.site.fundingSources.push(newRow);
    this.fundingSourceGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.fundingSourceGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.fundingSources.length - 1,
      colKey: 'name'
    });
  }

  //add new row to site.Attachments, then inform the grid that there's been an update
  addAttachmentRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.site.attachments.push(newRow);
    this.attachmentGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.attachmentGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.attachments.length - 1,
      colKey: 'draftName'
    });
  }

  //add new row to site.consentForms, then inform the grid that there's been an update
  addConsentFormsRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.site.consentForms.push(newRow);
    this.consentFormsGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.consentFormsGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.consentForms.length-1,
      colKey: 'draftName'
    });
  }

  //add new row to site.recruitmentMaterials, then inform the grid that there's been an update
  addRecruitmentMaterialsRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.site.recruitmentMaterials.push(newRow);
    this.recruitmentMaterialsGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.recruitmentMaterialsGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.site.recruitmentMaterials.length-1,
      colKey: 'draftName'
    });
  }

  //When grid is displayed following successful retrieval of site data, connect grid data source
  onFundingSourceGridReady(params) {
    this.fundingSourceGridOptions.api = params.api;
    this.fundingSourceGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      for (let fs of this.site.fundingSources) {
        if (fs.documents == null) {
          fs.documents = [];
        }
      }
      this.fundingSourceGridOptions.api.setRowData(this.site.fundingSources);
      this.fundingSourceGridOptions.setGridColumnWidths();
    }
  }

  onAttachmentGridReady(params) {
    this.attachmentGridOptions.api = params.api;
    this.attachmentGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.attachmentGridOptions.api.setRowData(this.site.attachments);
      this.attachmentGridOptions.setGridColumnWidths();
    }
  }

  onConsentFormsGridReady(params) {
    this.consentFormsGridOptions.api = params.api;
    this.consentFormsGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.consentFormsGridOptions.api.setRowData(this.site.consentForms);
      this.consentFormsGridOptions.setGridColumnWidths();
    }
  }

  onRecruitmentMaterialsReady(params) {
    this.recruitmentMaterialsGridOptions.api = params.api;
    this.recruitmentMaterialsGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.site != null) {
      this.recruitmentMaterialsGridOptions.api.setRowData(this.site.recruitmentMaterials);
      this.recruitmentMaterialsGridOptions.setGridColumnWidths();
    }
  }

  onClickShowSearch(searchTarget, targetIndex?) {
    this.modalRef = this.modalService.open(UserSearchTypeaheadComponent);
    switch (searchTarget) {
      case null:
      case "investigator":
        this.modalRef.componentInstance.label = "Search for Investigator by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.principalInvestigator = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
      case "primaryContact":
        this.modalRef.componentInstance.label = "Search for Primary Contact by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.primaryContact = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
      case "proxy":
        this.modalRef.componentInstance.label = "Search for PI Proxy by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.piProxies[targetIndex] = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
    }
  }

  //always-on filtering of which grid rows are visible
  isExternalFilterPresent(): boolean {
    return true;
  }

  //test funding source row to determine if it should be visible in the grid
  doesExternalFilterPass(node) {
    return !node.data.delete;
  }

  //must use a function to achieve dynamic editability in ag-grid cells
  getCanEdit() {
    return this.canEdit;
  }

  // Called from parent component to indicate service response and enable the form buttons.
  submitted(): void {
    this.sending = false;
    this.saving = false;
  }

  onFirstDataRendered(params, optionsObj) {
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }

  canDeactivate() {
    var curValues = this.modification;
    //if changes made, return false to the parent component to trigger a warning/confirmation dialog via pending-changes.guard
    let diffObj = ObjectUtils.getDifference(this.originalModification, curValues);
    if(Object.keys(diffObj).length != 0) return false;

    var curEnrollment = this.enrollmentStatus;
    diffObj = ObjectUtils.getDifference(this.originalEnrollment, curEnrollment);
    if(Object.keys(diffObj).length != 0) return false; 

    var curNotification = this.subjectNotifications;
    diffObj = ObjectUtils.getDifference(this.originalNotifications, curNotification);
    if (Object.keys(diffObj).length != 0) return false;
    
    //else
    return true;
  }

  // Removes the rows from the DOM and draws them again from scratch
  RefreshGridView() {
    //recreate document cellRenderer for revision upload without page refresh 
    if (this.site != null) {
      if (this.consentFormsGridOptions.api) {
        this.consentFormsGridOptions.api.setRowData(this.site.consentForms);
        this.consentFormsGridOptions.api.redrawRows();
      }

      if (this.recruitmentMaterialsGridOptions.api) {
        this.recruitmentMaterialsGridOptions.api.setRowData(this.site.recruitmentMaterials);
        this.recruitmentMaterialsGridOptions.api.redrawRows();
      }

      if (this.attachmentGridOptions.api) {
        this.attachmentGridOptions.api.setRowData(this.site.attachments);
        this.attachmentGridOptions.api.redrawRows();
      }

      if (this.fundingSourceGridOptions.api) {
        this.fundingSourceGridOptions.api.setRowData(this.site.fundingSources);
        this.fundingSourceGridOptions.api.redrawRows();
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role, User, UserDto } from '../models';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DataServicesModule } from './data-services.module';

export interface UserContactInfo {
  firstName: string;
  lastName: string;
  phoneNumber: number;
  email: string;
}

interface UserPendingApprovalCountResponse {
  count: number;
}

export interface UserRoleAssigned {
  id: string;
  role: Role;
}

export interface UserRoleUnassigned {
  id: string;
  role: Role;
}

export interface UserSearchRequest {
  term: string;
}

export interface UserSearchResult {
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserSearchResponse {
  results: UserSearchResult[];
}


@Injectable({
  providedIn: DataServicesModule
})
export class UserService {

  private userApprovedSource = new Subject<string>();
  private userDeniedSource = new Subject<string>();
  private userContactInfoUpdatedSource = new Subject<UserContactInfo>();
  private userRoleAssignedSource = new Subject<UserRoleAssigned>();
  private userRoleUnassignedSource = new Subject<UserRoleUnassigned>();
  
  userApproved$ = this.userApprovedSource.asObservable();
  userDenied$ = this.userDeniedSource.asObservable();
  userContactInfoUpdated$ = this.userContactInfoUpdatedSource.asObservable();
  userRoleAssigned$ = this.userRoleAssignedSource.asObservable();
  userRoleUnassigned$ = this.userRoleUnassignedSource.asObservable();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService) { 
  }

  approveUser(id: string) {
    return this.http.post(`/api/users/${id}/approve`, {})
      .pipe(tap(_ => this.userApprovedSource.next(id)));
  }

  denyUser(id: string) {
    return this.http.post(`/api/users/${id}/deny`, {}).pipe(
      tap(_ => this.userDeniedSource.next(id)),
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('UserAccountAlreadyApproved')) {
            this.toastr.error("Sorry, you are not allowed to deny an account that has already been approved.", "Unauthorized");
          }
        }
        return throwError(err);
      })
    )
  }

  deleteUser(id: string) {
    return this.http.post(`/api/users/${id}/delete`, {}).pipe(
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('CannotDeleteOwnAccount')) {
            this.toastr.error("Sorry, you are not allowed to delete your own account.", "Unauthorized");
          }
          if (err.errors[''].includes('CannotDeleteAdminAccount')) {
            this.toastr.error("Sorry, you are not allowed to delete an administrator account.", "Unauthorized");
          }
          if (err.errors[''].includes('CannotDeleteOrganizationOwner')) {
            this.toastr.error("Sorry, you are not allowed to delete the account which owns the organization.", "Unauthorized");
          }
          if (err.errors[''].includes('CannotDeleteLastAccountManager')) {
            this.toastr.error("Sorry, you are not allowed to delete the only account manager account for an organization.", "Unauthorized");
          }
        }
        return throwError(err);
      })
    );
  }

  lockUser(id: string) {
    return this.http.post(`/api/users/${id}/lock`, {}).pipe(
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('CannotLockOwnAccount')) {
            this.toastr.error("Sorry, you are not allowed to lock your own account.", "Unauthorized")
          }
        }
        return throwError(err);
      })
    );
  }

  unlockUser(id: string) {
    return this.http.post(`/api/users/${id}/unlock`, {});
  }

  assignRole(id: string, role: Role) {
    return this.http.post(`/api/users/${id}/assign-role`, { role }).pipe(tap(() => {
      this.userRoleAssignedSource.next({ id, role });
    }));
  }

  unassignRole(id: string, role: Role) {
    return this.http.post(`/api/users/${id}/unassign-role`, { role }).pipe(tap(() => {
      this.userRoleUnassignedSource.next({ id, role });
    }));
  }

  updateContactInfo(id: string, contactInfo: UserContactInfo) {
    return this.http.post(`/api/users/${id}`, contactInfo).pipe(tap(_ => this.userContactInfoUpdatedSource.next(contactInfo)));
  }

  getUser(id: string): Observable<User> {
    return this.http.get<UserDto>(`/api/users/${id}`)
      .pipe(map(dto => new User(dto)));
  }

  getPendingApprovalCount(skipRefresh: boolean = false): Observable<number> {

    var headers = {};
    if (skipRefresh) {
      headers['X-NoRefreshToken'] = 'true'
    }

    return this.http.get<UserPendingApprovalCountResponse>('/api/users/pending-approval-count', { headers: headers } ).pipe(map(response => response.count));
  }

  search(request: UserSearchRequest): Observable<UserSearchResult[]> {
    // Encode query string unsafe charecters. 
    let term = encodeURIComponent(request.term);
    var params = {
      q: term
    };

    return this.http.get<UserSearchResponse>('/api/users/search', { params })
      .pipe(map(response => response.results));
  }
}
import { Injectable } from "@angular/core";
import { Site } from "../models/site";

@Injectable({
    providedIn: 'root'
})

export class SharedService {
    studyExchangeId:string; 
    siteExchangeId: string;
    site: Site;

    constructor(){

    }
}
import { Component, OnInit, Input } from '@angular/core';
import { CrReport } from 'src/app/models/cr-report';
import { ResearchService } from 'src/app/data-services';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridDefaults, DocumentUploadCellComponent, DeleteRowComponent } from 'src/app/shared';


@Component({
  selector: 'app-continuing-review',
  templateUrl: './continuing-review.component.html',
  styleUrls: ['./continuing-review.component.scss']
})
export class ContinuingReviewComponent implements OnInit {
  @Input() studyExchangeId;
  @Input() siteExchangeId;
  saving: boolean;
  canEdit: boolean = true;
  report: CrReport;
  pageValidationErrors: string[] = [];

  supportingDocGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: true },
      {
        headerName: "Draft", field: "draft", type: ["noSort"],
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  constructor(
    private researchService: ResearchService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal) { 
    }

  ngOnInit() {
    //we do not load the previous values into this form, we start blank every time and post new values.
    this.report = new CrReport;
  }

  onSave() {
    if (this.validatePage()) {
      this.saving = true;
      this.researchService.updateContinuingReview(this.studyExchangeId, this.siteExchangeId, this.report)
        .subscribe(() => {
          this.toastr.success("Report Sent", "Success!");
          this.saving = false;
          this.activeModal.close('Submit success');
        });
    }
    return;
  }

  onCancel() {
    this.activeModal.close('Close click');
  }

  //always-on filtering of which grid rows are visible
  isExternalFilterPresent(): boolean {
    return true;
  }

  //test funding source row to determine if it should be visible in the grid
  doesExternalFilterPass(node) {
    return !node.data.delete;
  } 

  addSupportingDocumentRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.report.supportingDocuments.push(newRow);
    this.supportingDocGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.supportingDocGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.report.supportingDocuments.length - 1,
      colKey: 'draftName'
    });
  }

  onSupportingDocumentGridReady(params) {
    this.supportingDocGridOptions.api = params.api;
    this.supportingDocGridOptions.columnApi = params.columnApi;
    if (this.report != null) {
      this.supportingDocGridOptions.api.setRowData(this.report.supportingDocuments);
      this.supportingDocGridOptions.setGridColumnWidths();
    }
  }

  onFirstDataRendered(params, optionsObj){
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }

  validatePage() : boolean {
    this.pageValidationErrors = [];
    
    // supporting document validation: draft doc required, no blank rows
    let triggeredDocDraftMessage = false;
    let autoPopulatedDraftName =false;
    let blankAttachments = [];
    for(let att of this.report.supportingDocuments){
      if (!att.delete) {
        //if anything filled out
        if  (att.draftName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") { //and the draft file is missing
            if (!triggeredDocDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Supporting documents");
              triggeredDocDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !=="") { // Draft is filled out  
          // Auto-populate the draftName with filename for display, though service end point handle it.
          autoPopulatedDraftName=true; 
          att.draftName = att.draft.fileName;       
          continue;
        } 
        else { //nothing filled out, go ahead and just remove it
          //except we cant remove it now or we'll mess up the iterator. Remove them all when we're done here.
          blankAttachments.push(att);
        }
      }
      //else deleted, do nothing to validate it, the server will remove it
    }
    for(let blank of blankAttachments){
      this.report.supportingDocuments.splice(this.report.supportingDocuments.indexOf(blank), 1);
    }
    //if we removed rows from the grid or auto populated name, instruct it to refresh the display.
    if(blankAttachments.length > 0 || autoPopulatedDraftName){
      this.supportingDocGridOptions.api.setRowData(this.report.supportingDocuments);
      this.supportingDocGridOptions.setGridColumnWidths();
    }

    if (triggeredDocDraftMessage) {
      return false;
    }
    return true;
  }
}

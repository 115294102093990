import { Component, OnInit, Output, ViewChild, EventEmitter, Input, forwardRef, Renderer2 } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSearchResult, UserService } from '../../data-services';
import { Observable } from 'rxjs';
import { finalize, switchMap, tap, distinctUntilChanged, debounceTime, filter, map } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'app-user-search-typeahead',
  templateUrl: './user-search-typeahead.component.html',
  styleUrls: ['./user-search-typeahead.component.scss']
})

export class UserSearchTypeaheadComponent implements OnInit {

  @Input() placeholder: string = "Search ...";
  @Input() label: string;

  /**
   * The search term.
   */
  @Input() term: string;

  @Output('selectUser') select = new EventEmitter<UserSearchResult>();

  /**
   * This event is fired just before the component makes a request to the
   * server to find matching organization. The search term is supplied as
   * the event data.
   */
  @Output('beforeSearch') beforeSearch = new EventEmitter<string>();

  /**
   * A refrence to the typeahead component used in the view template.
   */
  @ViewChild('typeahead') typeahead: NgbTypeahead;

  /**
   * The observable function used to produce the search results
   */
  search: (text: Observable<string>) => Observable<UserSearchResult[]>

  /**
   * A flag to indicate a request has been made to the server and is waiting
   * on a response.
   */
  searching: boolean = false;

  constructor(
    private userService: UserService, 
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.search = (text: Observable<string>) => text.pipe(
      map(t => t.trim()),
      tap(() => this.typeahead.dismissPopup()),
      filter(t => t.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      tap(t => this.beforeSearch.emit(t)),
      tap(() => this.searching = true),
      switchMap(t => this.userService.search({ term: t }).pipe(
        finalize(() => this.searching = false))),
    );
  }

  onSelected(event: NgbTypeaheadSelectItemEvent) {
    this.activeModal.close(event.item as UserSearchResult);
  }

  onClickCancel(e?:any){
    this.activeModal.dismiss();
  }

  /**
   * This is used for format the search results into a display string for the typeahead input control.
   * 
   * @param usr A user search result object.
   */
  format(usr: UserSearchResult) {
    let strBuilder = [];
    if(usr.firstName != null && usr.firstName != ""){
      strBuilder.push(usr.firstName);
    }
    if(usr.lastName != null && usr.lastName != ""){
      if(strBuilder.length > 0){
        strBuilder.push(' ');
      }
      strBuilder.push(usr.lastName);
    }
    strBuilder.push('(');
    strBuilder.push(usr.email);
    strBuilder.push(')');
    return strBuilder.join('');
  }

}

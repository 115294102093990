import { User } from './user';

export interface OrganizationDto {
    id: number
    name: string
    exchangeId: string
    thumbprint: string
    owner: User
    isApproved: boolean;
}

export class Organization implements OrganizationDto {
    id: number
    name: string
    exchangeId: string
    thumbprint: string
    owner: User
    isApproved: boolean;

    constructor(args: Partial<OrganizationDto>) {
        if (args) {
            this.id = args.id;
            this.name = args.name;
            this.exchangeId = args.exchangeId;
            this.thumbprint = args.thumbprint;
            this.owner = args.owner;
            this.isApproved = args.isApproved;
        }
    }
}
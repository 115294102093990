import { Component, OnDestroy } from '@angular/core';
import { ResearchService } from 'src/app/data-services';
import { timer, Subscription, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const POLLING_INTERVAL = 10 * 60 * 1000;  // every 10 minutes

@Component({
  selector: 'app-invitation-count',
  templateUrl: './invitation-count.component.html',
  styleUrls: ['./invitation-count.component.scss']
})
export class InvitationCountComponent implements OnDestroy {

  count: number;

  private pollSubscription: Subscription;
  private actionSubscription: Subscription;
  
  constructor(private researchService: ResearchService) { 

    // update the invitation count every 10 minutes
    this.pollSubscription = timer(0, POLLING_INTERVAL)
      .pipe(switchMap(() => this.researchService.getInvitationCount(true)))
      .subscribe(value => this.count = value);

    // decrement the count when an invitation has been accepted or declined
    this.actionSubscription = merge(
      this.researchService.invitationAccepted$,
      this.researchService.invitationDeclined$)
      .subscribe(_ => this.count--);    
  }

  ngOnDestroy() {
    this.pollSubscription.unsubscribe();
    this.actionSubscription.unsubscribe();
  }

}

import { Person } from './person';
import { ReviewInformation } from './reviewInformation';
import { ReviewDates } from './reviewDates';
import { FundingSource } from './fundingSource';
import { RniListItem } from './rni';
import { ModListItem } from './mod';
import { Attachment } from './attachment';

export interface Site {
    attachments: Attachment[];
    consentForms: Attachment[];
    recruitmentMaterials:Attachment[];
    description: string;
    studyFundingSources: FundingSource[];
    fundingSources: FundingSource[];
    principalInvestigator: Person;
    piProxies: Person[];
    primaryContact: Person;
    reviewInformation: ReviewInformation;
    reviewDates: ReviewDates;   
    siteId: string;
    status: string;
    rni: RniListItem[];
    mods: ModListItem[];
    studyTitle: string;
    studyStatus: string;
    lastUpdated: Date;
}

export enum SiteStatus {
    draft = "Draft",
    pending = "Pending sIRB Review",
    active = "Active",
    inactive = "Inactive",
    closed = "Closed",
    suspended = "Suspended",
    terminated = "Terminated",
    discarded = "Discarded"
}

import { ActionPlan } from './actionPlan';
import { Attachment } from './attachment';
import { Person } from './person';

export interface RniDto {
    name: string;
    state: string;
    principalInvestigator: Person;
    sitePrincipalInvestigator: Person;
    description: string;
    reportedBy: string;
    isIncreasedRiskOrSafetyIssue: boolean;
    requiresProtocolRevision: boolean;
    requiresConsentFormRevision: boolean;
    dateOfAwareness: Date;
    actionResponseId: string;
    actionResponseNotes: string;
    categories: string[];
    supportingDocuments: Attachment[];
    actionResponseDocuments: Attachment[];
    actionPlan: ActionPlan;
};

export interface RniListItem {
    id: string;
    name: string;
    dateOfAwareness: Date;
    status: string;
}

/** 
 * The rni status.
 */
export enum RniState {
    Draft = "Draft",
    PendingSirbReview = "Pending sIRB Review",
    ActionRequired = "Action Required",
    Complete = "Complete",
    Acknowledged = "Acknowledged"
}

/**
 * Selections supported by the IRB Exchange for reportable new information category
 */
export enum RniCategories {
    RISK = "RISK",
    HARM = "HARM",
    NON_COMPLIANCE = "NON-COMPLIANCE",
    AUDIT = "AUDIT",
    REPORT = "REPORT",
    RESEARCHER_ERROR = "RESEARCHER-ERROR",
    CONFIDENTIALITY = "CONFIDENTIALITY",
    UNREVIEWED_CHANGE = "UNREVIEWED-CHANGE",
    INCARCERATION = "INCARCERATION",
    SUBJECT_COMPLAINT = "SUBJECT-COMPLAINT",
    SUSPENSION = "SUSPENSION",
    ADVERSE_DEVICE_EFFECT = "ADVERSE-DEVICE-EFFECT",
    VA_SAE = "VA-SAE",
}

export interface Categories {
    RISK: boolean;
    HARM: boolean;
    NON_COMPLIANCE: boolean;
    AUDIT: boolean;
    REPORT: boolean;
    RESEARCHER_ERROR: boolean;
    CONFIDENTIALITY: boolean;
    UNREVIEWED_CHANGE: boolean;
    INCARCERATION: boolean;
    SUBJECT_COMPLAINT: boolean;
    SUSPENSION: boolean;
    ADVERSE_DEVICE_EFFECT: boolean;
    VA_SAE: boolean;
}
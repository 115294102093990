import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../models';
import { RouterLinkCellComponent, AgGridDefaults } from 'src/app/shared';
import { DeleteUserRowComponent } from '../delete-user-row/delete-user-row.component';
import { UserRowActionsEditorComponent } from '../user-row-actions-editor/user-row-actions-editor.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input() users: User[];

  //grid configuration
  usersGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRow", type: ["deleteRow"] },
      {
        headerName: "Name",
        field: "name",
        cellRenderer: "routerLinkRenderer", 
        cellRendererParams: { routerLink: ['/accounts/', '{{id}}'], displayAttribute: 'name' }
      },
      { colId: "email", headerName: "Email", field: "email" },
      { colId: "roles", headerName: "Roles", field: "roles" },
      { colId: "actions", headerName: "Actions", cellRenderer: "userActionEditor", type: ["noSort"] }
    ],
    frameworkComponents: {
      deleteRow: DeleteUserRowComponent,
      userActionEditor: UserRowActionsEditorComponent,
      routerLinkRenderer: RouterLinkCellComponent
    }
  });

  constructor() {}

  ngOnInit() {
  }

  //on first grid render
  onUsersGridReady(params) {
    this.usersGridOptions.api = params.api;
    this.usersGridOptions.columnApi = params.columnApi;
    if (this.users != null && this.usersGridOptions.api != null) {
      this.usersGridOptions.api.setRowData(this.users);
      this.usersGridOptions.setGridColumnWidths();
    }
  }

  //on first row rendered in grid
  onFirstDataRendered(params, optionsObj) {
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }

  onDenyUser(user: User) {
    this.removeUserFromList(user);
  }
    
  onDeleteUser(user: User) {
    this.removeUserFromList(user);
  }

  private removeUserFromList(user: User) {
    var index = this.users.findIndex(u => u === user);
    if (index !== -1) {
      this.users.splice(index,1);
    }
  }

  public onDataUpdated(newData: User[]){
    //triggered on first load of data into the parent component, or on filter change, because the change detector is uncooperative on the @Input set of users
    this.users = newData;
    if (this.users != null && this.usersGridOptions.api != null) {
      this.usersGridOptions.api.setRowData(this.users);
    }
  }
}

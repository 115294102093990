import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/models';
import { UserService } from '../../data-services';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxService, MessageBoxIcon, MessageBoxButtons, MessageBoxResult } from 'src/app/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-delete-user-button',
  templateUrl: './delete-user-button.component.html',
  styleUrls: ['./delete-user-button.component.scss']
})
export class DeleteUserButtonComponent implements OnInit {

  @Input() user: User;
  @Input() iconOnly: boolean;
  @Output() delete = new EventEmitter<User>();

  private confirm: Observable<MessageBoxResult>;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private messageBox: MessageBoxService) { }

  ngOnInit() {
    
    this.confirm = this.messageBox.open({
      message: 'Are you sure?',
      title: `Delete ${this.user.name}`,
      icon: MessageBoxIcon.Warning,
      buttons: MessageBoxButtons.YesNo,
      dismissable: false
    });

  }

  deleteUser() {

    this.confirm.subscribe(value => {
      if (value == MessageBoxResult.Yes) {
        this.userService.deleteUser(this.user.id)
          .subscribe(data => {
            this.toastr.success(`${this.user.name}'s account was deleted.`, 'Success');
            this.delete.next(this.user);
          })
      }
    });

  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { ROLE_METADATA } from '../../models';

@Component({
  selector: 'app-user-role-info-tooltip',
  templateUrl: './user-role-info-tooltip.component.html',
  styleUrls: ['./user-role-info-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserRoleInfoTooltipComponent {

  /**
   * The role metadata without the Administrator
   */
  roleMetadata = ROLE_METADATA.slice(1);

}

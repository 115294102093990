import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// 3rd party modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule, GlobalConfig } from 'ngx-toastr';
import { MarkdownModule } from 'ngx-markdown';
import { FileSaverModule } from 'ngx-filesaver';
import { EmbedVideo } from 'ngx-embed-video';

// Application Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExamplesModule } from './examples/examples.module';
import { SharedModule, ErrorResponseInterceptor, PendingChangesGuard, NoCacheInterceptor } from './shared';
import { AuthModule, JwtTokenInterceptor } from './auth';
import { HomeModule } from './home';
import { UsersModule } from './users';
import { OrganizationsModule } from './organizations';
import { AccountsModule } from './accounts';
import { ResearchModule } from './research';
import { DataServicesModule } from './data-services';


const TOASTR_CONFIG: Partial<GlobalConfig> = {
  extendedTimeOut: 5000,
  timeOut: 10000,
  positionClass: 'toast-bottom-right'
};

let devImports = environment.production ? [] : [ ExamplesModule ];

@NgModule({
  declarations: [AppComponent],
  imports: [
    // built in 
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // 3rd party
    NgbModule,
    ToastrModule.forRoot(TOASTR_CONFIG),
    FileSaverModule,
    MarkdownModule.forRoot(),
    EmbedVideo.forRoot(),

    // Project modules (non-routing)
    AuthModule,
    SharedModule,
    HomeModule,
    UsersModule,
    OrganizationsModule,
    AccountsModule,
    ResearchModule,
    DataServicesModule,
    
    ...devImports,

    // Project routing modules - definition order is important
    AppRoutingModule
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorResponseInterceptor, multi: true },

    PendingChangesGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { RniDto, RniState } from '../../models/rni';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentUploadCellComponent, AgGridDefaults } from '../../shared';
import { DeleteRowComponent } from 'src/app/shared/delete-row/delete-row.component';
import { NgbTabset, NgbDate, NgbDateParserFormatter, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateUSParserFormatter } from '../../shared/date-parsers/ngb-date-US-parser-formatter';
import { UserSearchTypeaheadComponent } from 'src/app/users/user-search-typeahead/user-search-typeahead.component';
import { UserSearchResult } from 'src/app/data-services';
import { ObjectUtils } from 'src/app/shared/object-utils';

@Component({
  selector: 'app-reportable-new-information-editor',
  templateUrl: './reportable-new-information-editor.component.html',
  styleUrls: ['./reportable-new-information-editor.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateUSParserFormatter }]
})
export class ReportableNewInformationEditorComponent implements OnInit {
  @ViewChild('tabs') tabs: NgbTabset;
  @ViewChild('typeahead') typeahead: UserSearchTypeaheadComponent;
  modalRef: NgbModalRef;

  canEdit: boolean = true;
  saving: boolean = false;
  sending: boolean = false;
  submitted: boolean = false;
  reviewTabVisible: boolean = false;
  canActionPlan: boolean = false;
  pageValidationErrors: string[] = [];
  private submitAction: string;

  supportingDocGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", sortable: false, filter: false,
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  actionResponseDocGridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { colId: "deleteRow", cellRenderer: "deleteRowRenderer", type: ["deleteRow"] },
      { headerName: "Name", field: "draftName", editable: x => this.getCanEdit() },
      {
        headerName: "Draft", field: "draft", sortable: false, filter: false,
        cellRenderer: "documentUploadRenderer", cellRendererParams: { contextObj: this }
      },
      { headerName: "Version", field: "draftVersion", editable: x => this.getCanEdit() }
    ],
    frameworkComponents: {
      documentUploadRenderer: DocumentUploadCellComponent,
      deleteRowRenderer: DeleteRowComponent
    },
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass
  });

  /**
  * Categories selections supported by the IRB Exchange.
  */
  categories = ['RISK', 'HARM', 'NON-COMPLIANCE', 'AUDIT', 'REPORT', 'RESEARCHER-ERROR', 'CONFIDENTIALITY',
    'UNREVIEWED-CHANGE', 'INCARCERATION', 'SUBJECT-COMPLAINT', 'SUSPENSION', 'ADVERSE-DEVICE-EFFECT', 'VA-SAE'];
  /**
  * Determination selections supported by the IRB Exchange
  */
  determinations = ['ADDITIONAL_INFO', 'UNANTICIPATED', 'SUS_OR_TERM', 'SERIOUS', 'CONTINUING', 'NONCOMPLIANCE',
    'ALLEGATION', 'NONE	', 'ADDITIONAL_REVIEW', 'PSITE_REVIEW', 'LOCAL_REVIEW_VIA_SIRB']

  /**
  * The RNI form.
  */
  rniForm: FormGroup;

  /**
   * Unaltered copy of the form data from init time, used in detection of changes
   */
  originalForm;

  /**
   *  The rni, being passed on to this component from it's parent.
   */
  @Input() rni: RniDto;

  /**
   *  The form submit event.
   */
  @Output() submitRni = new EventEmitter<RniDto>();

  /**
   *  Reference to the RniState enum, in case its needed in the ngIf's of the template
   */
  RniState = RniState;

  /**
 *  The study exchange item Id.
 */
  studyExchangeId: string;

  /**
  *  The study exchange item Id.
  */
  siteExchangeId: string;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal) {

    // Create a new array with a form control for each category
    const categoryControls = this.categories.map(c => new FormControl(false));

    // Create a new array with a form control for each irb determination
    const determinationControls = this.determinations.map(c => new FormControl({ value: null, disabled: true }));

    this.rniForm = this.formBuilder.group({
      name: ['', Validators.required],
      dateOfAwareness: ['', Validators.required],
      reportedBy: ['', Validators.required],
      description: ['', Validators.required],
      principalInvestigator: ['', Validators.required],
      sitePrincipalInvestigator: ['', Validators.required],
      isIncreasedRiskOrSafetyIssue: [],
      requiresProtocolRevision: [],
      requiresConsentFormRevision: [],
      state: [],
      categories: new FormArray(categoryControls),
      determinations: new FormArray(determinationControls),
      grantAccessToIrb: [],
      actionResponseNotes: [],
      supportingDocuments: [],
      actionResponseDocuments: []
    });
  }

  ngOnInit() {
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.siteExchangeId = this.route.snapshot.paramMap.get('siteId');
    this.initForm(this.rni);
  }

  /**
   * To be called by parent component on availability of data, or within this component from ngOnInit(). Data needs to be reinitialized from 
   * the results of a new GET following successful POST of changes, resetting to a clean state which matches the server data.
   * @param rni the rni object on which to base the current form data
   */
  public initForm(rni){
    var awarenessDate = new Date(rni.dateOfAwareness);

    this.setPermissions();
    this.applyPermissionsToGrids();
    //editable form data, partially independant from the rni object, partially sharing data through object references
    var editable = {
      name: rni.name,
      dateOfAwareness: new NgbDate(
        awarenessDate.getFullYear(),
        awarenessDate.getMonth() + 1, // Month is zero indexed.
        awarenessDate.getDate()),
      reportedBy: rni.reportedBy,
      description: rni.description,
      isIncreasedRiskOrSafetyIssue: rni.isIncreasedRiskOrSafetyIssue,
      requiresProtocolRevision: rni.requiresProtocolRevision,
      requiresConsentFormRevision: rni.requiresConsentFormRevision,
      principalInvestigator: rni.principalInvestigator,
      sitePrincipalInvestigator: rni.sitePrincipalInvestigator,
      state: rni.state,
      grantAccessToIrb: true,
      actionResponseNotes: rni.actionResponseNotes,
      categories: this.processCategoriesGet(rni),
      determinations: this.processDeterminationsGet(rni),
      supportingDocuments: rni.supportingDocuments,
      actionResponseDocuments: rni.actionResponseDocuments
    }
    //stash an unaltered copy of the form data, completely independant from the rni and from editable form data, to use in change detection
    this.originalForm = ObjectUtils.deepCopy(editable);
    this.rniForm.setValue(editable);
    this.RefreshGridView(rni);
  }

  setPermissions() {
    switch (this.rni.state) {
      case RniState.Complete:
      case RniState.ActionRequired:
      case RniState.PendingSirbReview:
      case RniState.Acknowledged:
        this.reviewTabVisible = true;
      case RniState.Draft:
        break;
      default:
        break;
    }

    switch (this.rni.state) {
      case RniState.Draft:
      case RniState.PendingSirbReview:
      case RniState.ActionRequired:
        this.canEdit = true;
        break;
      default:
        this.canEdit = false;
        break;
    }

    if (this.rni.actionPlan) {
      this.canActionPlan = true;
    }
  }

  applyPermissionsToGrids() {
    if (this.actionResponseDocGridOptions.columnApi) {
      this.actionResponseDocGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
    if (this.supportingDocGridOptions.columnApi) {
      this.supportingDocGridOptions.columnApi.setColumnVisible("deleteRow", this.canEdit);
    }
  }

  // Click events
  onSaveDraft() {
    this.submitAction = "save";
  }

  onSaveAndSend() {
    this.submitAction = "saveAndSend";
  }

  // POST
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.rniForm.invalid) {
      return;
    }

    if (this.submitAction === "save") {
      this.onSave();
    } else if (this.submitAction === "saveAndSend") {
      this.onSendToSirb();
    }
  }

  onSendToSirb() {
    if (this.validatePage()) {
      this.sending = true;
      this.rniForm.patchValue({
        grantAccessToIrb: true,
        supportingDocument: this.rni.supportingDocuments,
        actionResponseDocuments: this.rni.actionResponseDocuments
      });
      //patch the proper dateTime formatted dateOfAwareness into the posted value without altering the form
      this.submitRni.emit(Object.assign({}, this.rniForm.value, { dateOfAwareness: this.getAwarenessDateTime() }));
    }
  }

  onSave() {
    if (this.validatePage()) {
      this.saving = true;
      this.rniForm.patchValue({
        grantAccessToIrb: false,
        supportingDocument: this.rni.supportingDocuments,
        actionResponseDocuments: this.rni.actionResponseDocuments
      });
      //patch the proper dateTime formatted dateOfAwareness into the posted value without altering the form
      this.submitRni.emit(Object.assign({}, this.rniForm.value, { dateOfAwareness: this.getAwarenessDateTime() }));
    }
  }

  onClose() {
    this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.siteExchangeId], { fragment: 'rni-tab' });
  }

  //page validation function to capture errors in grid data entry or perform other delayed validation.
  //Don't use this for simple input fields which are always visible, use reactive form validators instead
  validatePage(): boolean {
    this.pageValidationErrors = [];
    //#region supporting document validation: draft doc required, no blank rows
    let triggeredSupportingDocDraftMessage = false;
    let autoPopulatedDraftName = false;
    let blankSupportingDocuments = [];
    for (let att of this.rni.supportingDocuments) {
      if (!att.delete) {
        //if anything filled out
        if (att.id || att.category || att.draftVersion || att.draftName || att.finalVersion || att.finalName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") { //and the draft file is missing
            if (!triggeredSupportingDocDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Supporting Document");
              triggeredSupportingDocDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !== "") { // Draft is filled out  
          // Auto-populate the draftName with filename for display, though service end point handle it.
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        }
        else { //nothing filled out, go ahead and just remove it
          //except we cant remove it now or we'll mess up the iterator. Remove them all when we're done here.
          blankSupportingDocuments.push(att);
        }
      }
      //else deleted, do nothing to validate it, the server will remove it
    }
    for (let blank of blankSupportingDocuments) {
      this.rni.supportingDocuments.splice(this.rni.supportingDocuments.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankSupportingDocuments.length > 0 || autoPopulatedDraftName) {
      this.supportingDocGridOptions.api.setRowData(this.rni.supportingDocuments);
      this.supportingDocGridOptions.setGridColumnWidths();
    }
    //#endregion
    //#region action plan response document validation: draft required, no blanks rows
    let triggeredActionPlanDocDraftMessage = false;
    let blankActionPlanDocs = [];
    autoPopulatedDraftName = false;
    for (let att of this.rni.actionResponseDocuments) {
      if (!att.delete) {
        //if anything filled out
        if (att.id || att.category || att.draftVersion || att.draftName || att.finalVersion || att.finalName) {
          if (att.draft == null || att.draft.fileName == null || att.draft.fileName == "") { //and the draft file is missing
            if (!triggeredActionPlanDocDraftMessage) {
              this.pageValidationErrors.push("A Draft file must be selected for each Action Plan Response Document");
              triggeredActionPlanDocDraftMessage = true;
              continue;
            }
          }
        } else if (att.draft && att.draft.fileName && att.draft.fileName !== "") { // Draft is filled out 
          autoPopulatedDraftName = true;
          att.draftName = att.draft.fileName;
          continue;
        }
        else { //nothing filled out, go ahead and just remove it
          //except we cant remove it now or we'll mess up the iterator. Remove them all when we're done here.
          blankActionPlanDocs.push(att);
        }
      }
      //else deleted, do nothing to validate it, the server will remove it
    }
    for (let blank of blankActionPlanDocs) {
      this.rni.actionResponseDocuments.splice(this.rni.actionResponseDocuments.indexOf(blank), 1);
    }
    //if we removed rows from the grid, instruct it to refresh the display.
    if (blankActionPlanDocs.length > 0 || autoPopulatedDraftName) {
      this.actionResponseDocGridOptions.api.setRowData(this.rni.actionResponseDocuments);
      this.actionResponseDocGridOptions.setGridColumnWidths();
    }
    //#endregion 
    if (triggeredSupportingDocDraftMessage) {
      this.tabs.select('supporting-documents-tab');
      return false;
    } else if (triggeredActionPlanDocDraftMessage) {
      this.tabs.select('action-plan-tab');
      return false;
    }//else
    return true;
  }

  // Call from parent component to indicate service response and enable the form buttons.
  onSubmitted(): void {
    this.sending = false;
    this.saving = false;
  }

  processCategoriesGet(rni: RniDto) {
    var mappedCategories = [];
    for (let i = 0; i < this.categories.length; i++) {
      if (rni.categories.indexOf(this.categories[i]) == -1) {
        mappedCategories[i] = false;
      } else {
        mappedCategories[i] = true;
      }
    }

    return mappedCategories;
  }

  processDeterminationsGet(rni: RniDto) {
    if (this.rni != null && this.rni.actionPlan != null && this.rni.actionPlan.determinationIds != null) {
      var mappedDetermination = [];
      for (let i = 0; i < this.determinations.length; i++) {
        if (rni.actionPlan.determinationIds.indexOf(this.determinations[i]) == -1) {
          mappedDetermination[i] = false;
        } else {
          mappedDetermination[i] = true;
        }
      }
      return mappedDetermination;
    }

    return this.determinations.map(d => false);
  }

  addSupportingDocumentRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.rni.supportingDocuments.push(newRow);
    this.supportingDocGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.supportingDocGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.rni.supportingDocuments.length - 1,
      colKey: 'draftName'
    });
  }

  onSupportingDocumentsGridReady(params) {
    this.supportingDocGridOptions.api = params.api;
    this.supportingDocGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.rni != null) {
      this.supportingDocGridOptions.api.setRowData(this.rni.supportingDocuments);
      this.supportingDocGridOptions.setGridColumnWidths();
    }
  }

  addActionResponseDocumentRow() {
    let newRow = {
      id: "",
      category: "",
      delete: false,
      draft: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      draftName: "",
      draftVersion: "",
      final: {
        studyId: this.studyExchangeId,
        itemId: "",
        fileName: "",
        fileToUpload: null,
        fileContent: ""
      },
      finalName: "",
      finalVersion: ""
    }

    this.rni.actionResponseDocuments.push(newRow);
    this.actionResponseDocGridOptions.api.updateRowData({
      add: [newRow]
    });
    this.actionResponseDocGridOptions.api.startEditingCell({ //open the editor for the first editable cell in the row
      rowIndex: this.rni.actionResponseDocuments.length - 1,
      colKey: 'draftName'
    });
  }

  onActionResponseDocumentGridReady(params) {
    this.actionResponseDocGridOptions.api = params.api;
    this.actionResponseDocGridOptions.columnApi = params.columnApi;
    this.applyPermissionsToGrids();
    if (this.rni != null) {
      this.actionResponseDocGridOptions.api.setRowData(this.rni.actionResponseDocuments);
      this.actionResponseDocGridOptions.setGridColumnWidths();
    }
  }

  onClickShowSearch() {
    this.modalRef = this.modalService.open(UserSearchTypeaheadComponent);
    this.modalRef.componentInstance.label = "Search for Reported by Last Name or Email";
    this.modalRef.result.then((user: UserSearchResult) => {
      this.rniForm.patchValue({
        reportedBy: user.firstName + ' ' + user.lastName
      });
    }).catch(() => { /* cancelled by the user */ });
  }

  //always-on filtering of which grid rows are visible
  isExternalFilterPresent(): boolean {
    return true;
  }

  //test funding source row to determine if it should be visible in the grid
  doesExternalFilterPass(node) {
    return !node.data.delete;
  }

  //must use a function to achieve dynamic editability in ag-grid cells
  getCanEdit() {
    return this.canEdit;
  }

  // Adds the locale's timezone offset to ngb date.
  getAwarenessDateTime() {
    let ngbDate: NgbDate = this.rniForm.get('dateOfAwareness').value;
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  onFirstDataRendered(params, optionsObj) {
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }

  canDeactivate() {
    //.value excludes disabled inputs, but since the data is still present, that would introduce a false positive in change detection
    var curValues = this.rniForm.getRawValue();
    //if changes made, return false to the parent component, to trigger a warning/confirmation dialog via pending-changes.guard
    let diffObj = ObjectUtils.getDifference(this.originalForm, curValues);
    return (Object.keys(diffObj).length == 0);
  }

  // Removes the rows from the DOM and draws them again from scratch
  RefreshGridView(rni) {
    //recreate document cellRenderer for revision upload without page refresh 
    if (rni != null) {
      if (this.actionResponseDocGridOptions.api) {
        this.actionResponseDocGridOptions.api.setRowData(rni.actionResponseDocuments);
        this.actionResponseDocGridOptions.api.redrawRows();
      }

      if (this.supportingDocGridOptions.api) {
        this.supportingDocGridOptions.api.setRowData(rni.supportingDocuments);
        this.supportingDocGridOptions.api.redrawRows();
      }
    }
  }
}

import { Component, OnInit, ViewChild, HostListener} from '@angular/core';
import { Modification } from '../../models/mod';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchService } from '../../data-services';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SiteModificationEditorComponent } from '../site-modification-editor/site-modification-editor.component';
import { ComponentCanDeactivate } from 'src/app/shared';

@Component({
  selector: 'app-site-modification',
  templateUrl: './site-modification.component.html',
  styleUrls: ['./site-modification.component.scss']
})
export class SiteModificationComponent implements OnInit, ComponentCanDeactivate {

  // Child refernce to indicate the service response.
  @ViewChild(SiteModificationEditorComponent) modEditor: SiteModificationEditorComponent;

  modification: Modification;
  studyExchangeId: string;
  modExchangeId: string;

  constructor(private route: ActivatedRoute,
    private researchService: ResearchService,
    private toastr: ToastrService) { }

  ngOnInit() {

    //pull values from the url using param name assigned in app-routing module
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.modExchangeId = this.route.snapshot.paramMap.get('modId');

    this.researchService.getModification(this.modExchangeId, this.studyExchangeId)
      .subscribe((modification) => {
        this.modification = modification;
        if (this.modEditor != null){
          this.modEditor.initForm(this.modification);
        }
      });
  }

  OnSave(modification: Modification) {
    this.researchService.updateModification(this.modExchangeId, this.studyExchangeId, modification)
      .pipe(finalize(() => {
        this.modEditor.submitted();
      }))
      .subscribe((response: any) => {
        this.ngOnInit();
        this.toastr.success("Changes saved and sent", "Success!");
      });
  }

  //use canDeactivate as a handler for the window.beforeUnload event
  @HostListener('window:beforeunload')
  canDeactivate(){
    return this.modEditor.canDeactivate();
  }

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ValidationProblemDetails } from './problem-details';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    // if (error.status === 403) {
                    //     this.router.navigate(['/403']);
                    // }

                    if (error.status === 501) {
                        this.toastr.warning("That's not implemented yet...", "Whoops!");
                    } else if (error.status === 400) {
                        // Assuming context specific errors are handled in service class.
                        // so avoiding duplicate messages.
                    } else if (error.status === 401) {
                        this.toastr.warning("Unauthorized access", "Unauthorized!",
                            { timeOut: 7000, extendedTimeOut: 2000 });
                    } else if (error.status === 403) {
                        this.toastr.warning("Access is forbidden to the requested resource", "Access denied!",
                            { timeOut: 7000, extendedTimeOut: 2000 });
                    } else {
                        this.toastr.error("An error occurred while processing your request", "Error!",
                            { timeOut: 7000, extendedTimeOut: 2000 });
                    }

                    return throwError(error.error as ValidationProblemDetails);
                } else if (error instanceof ErrorEvent) {
                    // Client-side error
                    this.toastr.error("An error occurred while processing your request", "Error!",
                    { timeOut: 7000, extendedTimeOut: 2000 });
                }

                return throwError(error);
            })
        );
    }
}

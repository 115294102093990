import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchService } from 'src/app/data-services';
import { ToastrService } from 'ngx-toastr';
import { Modification } from 'src/app/models/mod';
import { finalize } from 'rxjs/operators';
import { SiteModificationEditorComponent } from '../site-modification-editor/site-modification-editor.component';
import { ComponentCanDeactivate } from 'src/app/shared';

@Component({
  selector: 'app-site-modification-create',
  templateUrl: './site-modification-create.component.html',
  styleUrls: ['./site-modification-create.component.scss']
})
export class SiteModificationCreateComponent implements OnInit, ComponentCanDeactivate {

  // Child refernce to indicate the service response.
  @ViewChild(SiteModificationEditorComponent) modEditor:SiteModificationEditorComponent;

  modification: Modification;
  siteExchangeId: string;
  studyExchangeId: string;  
  allowNavigation: boolean = false;

  constructor(private route: ActivatedRoute,
    private researchService: ResearchService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    //pull values from the url using param name assigned in app-routing module
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    this.siteExchangeId = this.route.snapshot.paramMap.get('siteId');

    this.researchService.getNewModification(this.studyExchangeId)
      .subscribe((modification) => {
        this.modification = modification;
      });
  }

  OnSave(modification: Modification) {
    this.researchService.createModification(this.studyExchangeId, modification)
      .pipe(finalize(() => {
        this.modEditor.submitted();
      }))
      .subscribe((response: any) => {
        if("undefined" !== typeof response && "undefined" !== typeof response.modExchangeId){
          let parts = response.modExchangeId.split('/');
          if(parts.length > 0){
            let partialModId = parts[parts.length-1];
            this.toastr.success("Changes saved", "Success!");
            this.allowNavigation = true;
            this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.siteExchangeId, 'mod', partialModId]);
            return;
          }
        }//else undefined or zero length
        this.toastr.error("Error navigating to the new modification, returning to Site Details", "Error");
        console.log("Error: response.modExchangeId missing or invalid. Response = " + JSON.stringify(response));
        this.router.navigate(['/research/studies', this.studyExchangeId, 'sites', this.siteExchangeId]);
      });
  }

  //use canDeactivate as a handler for the window.beforeUnload event
  @HostListener('window:beforeunload')
  canDeactivate(){
    return this.allowNavigation;
  }
}



import { Component, OnInit, Input } from '@angular/core';
import { Site } from 'src/app/models/site';
import { Modification, ModEnrollmentStatus, ModSubjectNotifications } from 'src/app/models/mod';

@Component({
  selector: 'app-modification-reader',
  templateUrl: './modification-reader.component.html',
  styleUrls: ['./modification-reader.component.scss']
})
export class ModificationReaderComponent implements OnInit {

  @Input() site: Site;
  @Input() modification: Modification;
  @Input() enrollmentStatus: ModEnrollmentStatus;
  @Input() subjectNotifications: ModSubjectNotifications;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { DocumentDownloadComponent } from '../';

@Component({
  selector: 'app-document-download-cell',
  templateUrl: './document-download-cell.component.html',
  styleUrls: ['./document-download-cell.component.scss']
})
export class DocumentDownloadCellComponent implements OnInit {
  params: any;

  constructor() { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    
  }
}

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { RniDto } from '../../models/rni';
import { ResearchService } from '../../data-services';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportableNewInformationEditorComponent } from '../reportable-new-information-editor/reportable-new-information-editor.component';
import { ComponentCanDeactivate } from 'src/app/shared/pending-changes.guard';
import { ObjectUtils } from 'src/app/shared/object-utils';

@Component({
  selector: 'app-reportable-new-information',
  templateUrl: './reportable-new-information.component.html',
  styleUrls: ['./reportable-new-information.component.scss']
})
export class ReportableNewInformationComponent implements OnInit, ComponentCanDeactivate {

  // Child refernce to indicate the service response.
  @ViewChild(ReportableNewInformationEditorComponent) rniEditor: ReportableNewInformationEditorComponent;

  /**
  *  The study exchange item Id.
  */
  studyExchangeId: string;

  /**
   *  The rni exchange item Id
   */
  rniExchangeId: string;

  /**
   *  The rni.
   */
  rni: RniDto;

  /**
   * Selections supported by the IRB Exchange for reportable new information category
   */
  categories = ['RISK', 'HARM', 'NON-COMPLIANCE', 'AUDIT', 'REPORT', 'RESEARCHER-ERROR', 'CONFIDENTIALITY',
    'UNREVIEWED-CHANGE', 'INCARCERATION', 'SUBJECT-COMPLAINT', 'SUSPENSION', 'ADVERSE-DEVICE-EFFECT', 'VA-SAE'];

  constructor(private researchService: ResearchService,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
  }
  
  ngOnInit() {
    this.rniExchangeId = this.route.snapshot.paramMap.get('rniId');
    this.studyExchangeId = this.route.snapshot.paramMap.get('studyId');
    
    this.initData();
  }

  initData(){
    this.researchService.getRni(this.rniExchangeId, this.studyExchangeId)
      .subscribe((rni) => {
        this.rni = rni;
        if (this.rniEditor != null) {
          this.rniEditor.initForm(this.rni);
        }
      });
  }

  onSubmit(rni: RniDto) {
    rni.categories = this.processCategoriesPost(rni);
    this.researchService.updateRni(this.rniExchangeId, this.studyExchangeId, rni)
      .pipe(finalize(() => {
        this.rniEditor.onSubmitted();
      }))
      .subscribe(() => {
        this.initData();
        this.toastr.success("Changes saved and sent", "Success!");
      });;
  }

  processCategoriesPost(rni: RniDto) {
    return rni.categories
      .map((v, i) => v ? this.categories[i] : null)
      .filter(v => v !== null);
  }

  //use canDeactivate as a handler for the window.beforeUnload event
  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.rniEditor.canDeactivate();
  }
}

export class ObjectUtils {
  
  public static deepCopy(obj: any): any {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = ObjectUtils.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = ObjectUtils.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  private static deepDiffMapper() {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function (obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)){
          let temp =  {
            type: this.compareValues(obj1, obj2),
            data: (obj1 === undefined) ? obj2 : obj1
          };
          if(temp.type !== this.VALUE_UNCHANGED){
            return temp;
          } else return null;
        }

        var diff = {};
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }

          var value2 = undefined;
          if ('undefined' != typeof (obj2[key])) {
            value2 = obj2[key];
          }

          diff[key] = this.map(obj1[key], value2);
          if(diff[key] == null){
            delete diff[key];
          }else if (typeof diff[key] == 'object' && Object.keys(diff[key]).length == 0){
            delete diff[key];
          }
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || ('undefined' != typeof (obj1[key]))) {
            continue;
          }

          diff[key] = this.map(undefined, obj2[key]);
          if(diff[key].hasOwnProperty("type") && diff[key].hasOwnProperty("data")){
            if (diff[key].type == this.VALUE_CREATED){
              if(typeof diff[key].data == 'object' && diff[key].data.hasOwnProperty("delete")){
                if(diff[key].data.delete == true){
                  delete diff[key];
                }
              }
            }
          }
        }

        return diff;

      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if ('undefined' == typeof (value1)) {
          return this.VALUE_CREATED;
        }
        if ('undefined' == typeof (value2)) {
          return this.VALUE_DELETED;
        }

        return this.VALUE_UPDATED;
      },
      isFunction: function (obj) {
        return {}.toString.apply(obj) === '[object Function]';
      },
      isArray: function (obj) {
        return {}.toString.apply(obj) === '[object Array]';
      },
      isDate: function (obj) {
        return {}.toString.apply(obj) === '[object Date]';
      },
      isObject: function (obj) {
        return {}.toString.apply(obj) === '[object Object]';
      },
      isValue: function (obj) {
        return !this.isObject(obj) && !this.isArray(obj);
      }
    }
  }

  public static getDifference(obj1: any, obj2: any){
    return ObjectUtils.deepDiffMapper().map(obj1, obj2);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { RniDto, RniCategories, Categories } from 'src/app/models/rni';

@Component({
  selector: 'app-rni-reader',
  templateUrl: './rni-reader.component.html',
  styleUrls: ['./rni-reader.component.scss']
})
export class RniReaderComponent implements OnInit {

  @Input() rni: RniDto;
  rniCategories = RniCategories;
  categories: Categories;

  constructor() { }

  ngOnInit() {
    this.processCategories();
  }

  processCategories() {
    this.categories = {
      RISK: false,
      HARM: false,
      NON_COMPLIANCE: false,
      AUDIT: false,
      REPORT: false,
      RESEARCHER_ERROR: false,
      CONFIDENTIALITY: false,
      UNREVIEWED_CHANGE: false,
      INCARCERATION: false,
      SUBJECT_COMPLAINT: false,
      SUSPENSION: false,
      ADVERSE_DEVICE_EFFECT: false,
      VA_SAE: false
    }

    for (var category of this.rni.categories) {
      switch (category) {
        case RniCategories.RISK:
          this.categories.RISK = true;
          break;
        case RniCategories.HARM:
          this.categories.HARM = true;
          break;
        case RniCategories.NON_COMPLIANCE:
          this.categories.NON_COMPLIANCE = true;
          break;
        case RniCategories.AUDIT:
          this.categories.AUDIT = true;
          break;
        case RniCategories.REPORT:
          this.categories.REPORT = true;
          break;
        case RniCategories.RESEARCHER_ERROR:
          this.categories.RESEARCHER_ERROR = true;
          break;
        case RniCategories.CONFIDENTIALITY:
          this.categories.CONFIDENTIALITY = true;
          break;
        case RniCategories.UNREVIEWED_CHANGE:
          this.categories.UNREVIEWED_CHANGE = true;
          break;
        case RniCategories.INCARCERATION:
          this.categories.INCARCERATION = true;
          break;
        case RniCategories.SUBJECT_COMPLAINT:
          this.categories.SUBJECT_COMPLAINT = true;
          break;
        case RniCategories.SUSPENSION:
          this.categories.SUSPENSION = true;
          break;
        case RniCategories.ADVERSE_DEVICE_EFFECT:
          this.categories.ADVERSE_DEVICE_EFFECT = true;
          break;
        case RniCategories.VA_SAE:
          this.categories.VA_SAE = true;
          break;
        default:
          break;
      }
    }
  }

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../shared';
import { AccountService, ChangePasswordRequest } from '../../data-services';
import { ToastrService } from 'ngx-toastr';
import { ValidationProblemDetails } from 'src/app/shared';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  theForm: FormGroup = this.formBuilder.group({
    currentPassword: ['', Validators.compose([Validators.required])],
    newPassword: ['', Validators.compose([Validators.required, Validators.minLength(13), PasswordValidation.minUnique(6)])],
    confirmPassword: ['', Validators.required]
  }, { validators: PasswordValidation.MatchPassword('newPassword', 'confirmPassword') });

  submitted = false;

  @Output() passwordChanged = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder, 
    private accountService: AccountService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit() {
    // the user tried to submit
    this.submitted = true;

    if (this.theForm.invalid) {
      return;
    }

    // copy the form value
    var request = this.theForm.value as ChangePasswordRequest;
    
    this.accountService.changePassword(request)
      .subscribe(() => {
        this.toastr.success("Your password has been changed.", "Success");
        this.passwordChanged.next({});
      },
      (err: ValidationProblemDetails) => {
        if (err.errors && err.errors['']) {
          
          if (err.errors[''].includes('PasswordMismatch')) {
            this.currentPassword.setErrors({ mismatch: true });
          }

          if (err.errors[''].includes('BlackListedPassword')) {
            this.newPassword.setErrors({ blacklisted: true });
          }

        }
      });
  }

  /**
   * Accessor for the current password form control.
   */
  get currentPassword() {
    return this.theForm.controls['currentPassword'];
  }

  /**
   * Accessor for the new password form control.
   */
  get newPassword() {
    return this.theForm.controls['newPassword'];
  }

  /**
   * Accessor for the confirm password form control.
   */
  get confirmPassword() {
    return this.theForm.controls['confirmPassword'];
  }
}

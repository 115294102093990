import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AgGridModule } from 'ag-grid-angular';

import { AuthModule } from '../auth';

import { UserListComponent } from './user-list/user-list.component';
import { UserRoleEditorComponent } from './user-role-editor/user-role-editor.component';
import { UserRoleInfoTooltipComponent } from './user-role-info-tooltip/user-role-info-tooltip.component';
import { UserContactInfoEditorComponent } from './user-contact-info-editor/user-contact-info-editor.component';
import { UserApprovalEditorComponent } from './user-approval-editor/user-approval-editor.component';
import { UserLockEditorComponent } from './user-lock-editor/user-lock-editor.component';
import { DeleteUserButtonComponent } from './delete-user-button/delete-user-button.component';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';
import { DeleteUserRowComponent } from './delete-user-row/delete-user-row.component';
import { RouterLinkCellComponent } from '../shared';
import { UserRowActionsEditorComponent } from './user-row-actions-editor/user-row-actions-editor.component';
import { UserSearchTypeaheadComponent } from './user-search-typeahead/user-search-typeahead.component';


@NgModule({
  declarations: [
    UserContactInfoEditorComponent,
    UserListComponent,
    UserRoleEditorComponent,
    UserRoleInfoTooltipComponent,
    UserApprovalEditorComponent,
    UserLockEditorComponent,
    DeleteUserButtonComponent,
    CreateUserDialogComponent,
    DeleteUserRowComponent,
    UserRowActionsEditorComponent,
    UserSearchTypeaheadComponent
  ],
  imports: [
    AgGridModule.withComponents([
      DeleteUserRowComponent,
      RouterLinkCellComponent,
      UserRowActionsEditorComponent
    ]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    
    NgbModule,
    ToastrModule,
    
    AuthModule
  ],
  exports: [
    DeleteUserButtonComponent,
    UserContactInfoEditorComponent,
    UserListComponent,
    UserRoleEditorComponent,
    UserApprovalEditorComponent,
    UserLockEditorComponent,
    UserSearchTypeaheadComponent
  ],
  entryComponents: [
    CreateUserDialogComponent
  ]
})
export class UsersModule { }

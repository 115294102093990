import { Component, OnInit, Input } from '@angular/core';
import { ReviewInformation } from '../../models/reviewInformation';

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss']
})
export class ReviewDetailsComponent implements OnInit {
  
  @Input() reviewInformation: ReviewInformation;
  modificationsRequired: boolean = false;
  hasLetter: boolean = false;
  
  constructor() { }

  ngOnInit() {
    if (this.reviewInformation.requiredModifications != null && this.reviewInformation.requiredModifications != '') {
      if (this.reviewInformation.status == 'Modifications Required to Secure "Approved"') {
        this.modificationsRequired = true;
      }
    }
    if (this.reviewInformation.correspondenceLetter != null && this.reviewInformation.correspondenceLetter.fileName != null) {
      this.hasLetter = true;
    }
  }
}

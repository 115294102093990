import { Attachment } from './attachment';

export class CrReport {
  comments: string;
  enrolledSinceLastApproval: number;
  totalEnrollment: number;
  //reportedDate: date;
  supportingDocuments: Attachment[];
  noSubjectsExperienced: boolean;
  anticipatedAdverse: boolean;
  noSubjectsWithdrew: boolean;
  noUnanticipated: boolean;
  noComplaints: boolean;
  noPublications: boolean;
  noInterim: boolean;
  noMultiCenter: boolean;
  noData: boolean;
  noRegulatory: boolean;
  noOther: boolean;
  inTheOpinion: boolean;
  allModifications: boolean;
  allProblems: boolean;
  noInternal: boolean;

  constructor(){
    this.supportingDocuments = [];
  }

}
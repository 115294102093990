import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../data-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models';
import { PasswordValidation, ValidationProblemDetails } from 'src/app/shared';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent implements OnInit {

  /**
   * The registration form.
   */
  theForm: FormGroup;

  /**
 * A flag that indicates the create account form has been submitted to the server.
 */
  submitted: boolean;

  registering: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private accountService: AccountService) 
  {
    this.theForm = this.formBuilder.group({
      organizationId: [''],
      organizationName: [''],
      assuranceNumber: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(13), PasswordValidation.minUnique(6) ])],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      approved: [true] // this will tell the server to pre-approve the account
    }, { validator: Validators.compose([PasswordValidation.MatchPassword(), this.requireOneOf('organizationId','organizationName')]) });
  }

  ngOnInit() {

  }

  /**
   * A custom valiation which requires at least of the specified controls to have a value.
   * 
   * @param controls A collection of controls to check
   */
  requireOneOf(...controls: string[]): ValidatorFn
  {
    return (ac: AbstractControl) => {
      var result = controls.some(controlName => {
        return false == this.isEmptyInputValue(ac.get(controlName));
      });

      return result ? null : { requireOneOf: true };
    }
  }

  isEmptyInputValue(value) {
    return value == null || value.length === 0;
  }

  onSubmit() {
    this.submitted = true;

    if (this.theForm.invalid) {
      return;
    }

    this.registering = true;

    this.accountService.register(this.theForm.value)
      .pipe(finalize(() => {
        this.registering = false;
      }))
      .subscribe((user: User) => {
        this.activeModal.close(user);
        this.toastr.success(`User has been added successfully`, "Success!")
      },
      (err: ValidationProblemDetails) => {
        if (err.errors && err.errors['']) {
          
          if (err.errors[''].includes('PasswordMismatch')) {
            this.password.setErrors({ mismatch: true });
          }

          if (err.errors[''].includes('BlackListedPassword')) {
            this.password.setErrors({ blacklisted: true });
          }
        }
      });
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Accessor for the organization id form control.
   */
  get organizationId() {
    return this.theForm.controls['organizationId'];
  }

  /**
   * Accessor for the organization name form control.
   */
  get organizationName() {
    return this.theForm.controls['organizationName'];
  }

  /**
   * Accessor for the first name form control.
   */
  get firstName() {
    return this.theForm.controls['firstName'];
  }

  /**
   * Accessor for the last name form control.
   */
  get lastName() {
    return this.theForm.controls['lastName'];
  }

  /**
   * Accessor for the phone number form control.
   */
  get phoneNumber() {
    return this.theForm.controls['phoneNumber'];
  }

  /**
   * Accessor for the email form control.
   */
  get email() {
    return this.theForm.controls['email'];
  }
  
  /**
   * Accessor for the password form control.
   */
  get password() {
    return this.theForm.controls['password'];
  }

  /**
   * Accessor for the password confirmation form control.
   */
  get confirmPassword() {
    return this.theForm.controls['confirmPassword'];
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataServicesModule } from './data-services.module';

@Injectable({
  providedIn: DataServicesModule
})
export class DiagnosticsService {

  constructor(private http : HttpClient) { 

  }

  forbidden() {
    return this.http.get("/api/diagnostics/forbidden");
  }
}

import { ReviewInformation } from './reviewInformation';
import { Site } from './site';

export interface ModListItem {
    id: string;
    name: string;
    summary: string;
    dateCreated: Date;
    status: string;
}

/**
 * The site modification supported by IRB Exchange.
 */
export interface Modification {
    name: string;
    dateCreated: string;
    enrollmentStatus: string[];
    subjectNotifications: string[];
    summary: string;
    status: string;
    draftSite: Site;
    grantAccessToIrb: boolean;
    reviewInformation: ReviewInformation;
}

/** 
 *  Selections supported by the IRB Exchange for site modification enrollment status
 */
export enum EnrollmentStatus {
    NONE_ENROLLED = "NONE_ENROLLED",
    CURRENT_ENROLLED = "CURRENT_ENROLLED",
    ENROLLMENT_CLOSED = "ENROLLMENT_CLOSED",
    INTERVENTIONS_COMPLETED = "INTERVENTIONS_COMPLETED",
    INFO_COLLECTION_COMPLETE = "INFO_COLLECTION_COMPLETE"
}

/**
 * Selections supported by the IRB Exchange for site modification subject notification
 */
export enum SubjectNotifications {
    CURRENT_SUBJECTS = "CURRENT_SUBJECTS",
    FORMER_SUBJECTS = "FORMER_SUBJECTS"
}

export interface ModEnrollmentStatus {
    NONE_ENROLLED: boolean;
    CURRENT_ENROLLED: boolean;
    ENROLLMENT_CLOSED: boolean;
    INTERVENTIONS_COMPLETED: boolean;
    INFO_COLLECTION_COMPLETE: boolean;
}

export interface ModSubjectNotifications {
    CURRENT_SUBJECTS: boolean;
    FORMER_SUBJECTS: boolean;
}

/** 
 * The MOD status.
 */
export enum ModificationStatus {
    Draft = "Draft",
    PendingSirbReview = "Pending sIRB Review",
    Approved = "Approved"
}

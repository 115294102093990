import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * The buttons
 */
export enum MessageBoxButtons {
  Ok = 1,
  Cancel = 2,
  OkCancel = 3,
  Yes = 4,
  No = 8,
  YesNo = 12,
  YesNoCancel = 14
}

/**
 * The icon the is displayed within the message box.
 */
export enum MessageBoxIcon {
  None = 0,
  Error = 1,
  Warning = 2,
  Information = 3
}

export enum MessageBoxResult {
  Dismiss = 0,
  Ok = 1,
  Cancel = 2,
  Yes = 3,
  No = 4
}

interface MessageBoxButton {
  text: string,
  value: MessageBoxResult
}

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() buttons: MessageBoxButtons = MessageBoxButtons.OkCancel;
  @Input() icon: MessageBoxIcon = MessageBoxIcon.None;
  @Input() dismissable: boolean;

  _buttons: MessageBoxButton[] = [];
  _iconClasses: object;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initButtons(this.buttons);
    this.initIcon(this.icon);
  }

  onClick(button: any) {
    this.activeModal.close(button.value);
  }

  onCrossClick() {
    this.activeModal.dismiss();
  }

  private initButtons(buttons: MessageBoxButtons)
  {
    // clear the list of buttons
    this._buttons.splice(0, this._buttons.length);

    if (buttons & MessageBoxButtons.Ok) {
      this._buttons.push({ text: 'OK', value: MessageBoxResult.Ok });
    }
    if (buttons & MessageBoxButtons.Yes) {
      this._buttons.push({ text: 'Yes', value: MessageBoxResult.Yes });
    }
    if (buttons & MessageBoxButtons.No) {
      this._buttons.push({ text: 'No', value: MessageBoxResult.No });
    }
    if (buttons & MessageBoxButtons.Cancel) {
      this._buttons.push({ text: 'Cancel', value: MessageBoxResult.Cancel });
    }

  }

  private initIcon(icon: MessageBoxIcon)
  {
    switch (icon) {
      case MessageBoxIcon.Error:
        this._iconClasses = { 
          'far': true,
          'fa-exclamation-square': true,
          'fa-2x': true,
          'text-danger': true
        };
        break;
      case MessageBoxIcon.Warning:
        this._iconClasses = {
          'far': true,
          'fa-exclamation-triangle': true,
          'fa-2x': true,
          'text-warning': true
        }
        break;
      case MessageBoxIcon.Information:
        this._iconClasses = {
          'far': true,
          'fa-info-circle': true,
          'fa-2x': true,
          'text-info': true
        }
        break;
      case MessageBoxIcon.None:
        this._iconClasses = { };
        break;
    }
  }

}



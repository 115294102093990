import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/models';
import { UserService } from '../../data-services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-approval-editor',
  templateUrl: './user-approval-editor.component.html',
  styleUrls: ['./user-approval-editor.component.scss']
})
export class UserApprovalEditorComponent implements OnInit {

  @Input() user: User;
  @Input() iconOnly: boolean;

  @Output() deny = new EventEmitter<User>();
  @Output() approve = new EventEmitter<User>();

  constructor(
    private userService: UserService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  approveUser() {
    this.userService.approveUser(this.user.id)
      .subscribe(() => {
        this.toastr.success(`${this.user.name}'s account has been approved.`, 'Success');
        this.user.isApproved = true;
        this.approve.next(this.user);
      });
  }

  denyUser() {
    this.userService.denyUser(this.user.id)
      .subscribe(() => {
        this.toastr.success(`${this.user.name}'s account has been denied.`, 'Success');
        this.user.isApproved = false;
        this.deny.next(this.user);
      });
  }
  
}

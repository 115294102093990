import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, throwError, Subject, timer, Subscription } from 'rxjs';
import { Study, StudyListItem } from '../models/study';
import { Site } from '../models/site';
import { DataServicesModule } from './data-services.module';
import { RniDto } from '../models/rni';
import { ToastrService } from 'ngx-toastr';
import { Modification } from '../models/mod';
import { CrReport } from '../models/cr-report';

interface GetStudiesResult {
  studies: StudyListItem[];
}

interface GetStudyDetailsResult {
  study: Study;
}

interface GetSiteDetailsResult {
  site: Site;
}

interface GetRniResults {
  rni: RniDto;
}

interface GetModificationResults {
  modification: Modification;
}

export interface UserInvitationsCountResponse {
  count: number;
}



@Injectable({
  providedIn: DataServicesModule
})
export class ResearchService {

  private invitationAcceptedSource = new Subject<string>();
  private invitationDeclinedSource = new Subject<string>();

  invitationAccepted$ = this.invitationAcceptedSource.asObservable();
  invitationDeclined$ = this.invitationDeclinedSource.asObservable();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService) {
  }

  getStudies(): Observable<StudyListItem[]> {
    return this.http.get<GetStudiesResult>('/api/research')
      .pipe(map((data) => data.studies));
  }

  getInvitations(): Observable<StudyListItem[]> {
    return this.http.get<GetStudiesResult>('/api/research/invitations')
      .pipe(map((data) => data.studies));
  }

  getInvitationCount(skipRefresh: boolean = false): Observable<number> {

    var headers = {};
    if (skipRefresh) {
      headers['X-NoRefreshToken'] = 'true'
    }

    return this.http.get<UserInvitationsCountResponse>(`/api/research/invitations-count`, { headers: headers }).pipe(
      map(response => response.count));
  }

  getStudyDetails(exchangeId: string): Observable<Study> {
    return this.http.get<GetStudyDetailsResult>(`/api/research/studyDetails/${exchangeId}`)
      .pipe(map((data) => data.study))
  }

  acceptInvitation(exchangeId: string) {
    return this.http.post(`/api/research/invitations/${exchangeId}/accept`, {})
      .pipe(tap(_ => this.invitationAcceptedSource.next(exchangeId)));
  }

  declineInvitation(exchangeId: string) {
    return this.http.post(`/api/research/invitations/${exchangeId}/decline`, {})
      .pipe(tap(_ => this.invitationDeclinedSource.next(exchangeId)));
  }

  getSiteDetails(studyExchangeId: string, siteExchangeId: string) {
    return this.http.get<GetSiteDetailsResult>(`/api/research/siteDetails/${studyExchangeId}/${siteExchangeId}`)
      .pipe(map((data) => data.site));
  }

  updateSite(studyExchangeId: string, siteExchangeId: string, site: Site) {
    return this.http.post(`api/research/updateSite/${studyExchangeId}/${siteExchangeId}`, site);
  }

  sendToSirb(studyExchangeId: string, siteExchangeId: string, site: Site) {
    return this.http.post(`api/research/sendToSirb/${studyExchangeId}/${siteExchangeId}`, site);
  }

  getRni(rniExchangeId: string, studyExchangeId: string) {
    return this.http.get<GetRniResults>(`/api/rni/${rniExchangeId}/${studyExchangeId}`)
      .pipe(map((data) => data.rni))
  }

  getNewRni(studyExchangeId: string) {
    return this.http.get<GetRniResults>(`/api/rni/${studyExchangeId}`)
      .pipe(map((data) => data.rni))
  }

  createRni(studyExchangeId: string, rni: RniDto) {
    return this.http.post(`/api/rni/${studyExchangeId}/create`, rni).pipe(
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('AwarenessDateCannotBeFuture')) {
            this.toastr.error("Date of awareness cannot be in the future.", "Invalid Date");
          }
        }
        return throwError(err);
      })
    );
  }

  updateRni(rniExchangeId: string, studyExchangeId: string, rni: RniDto) {
    return this.http.post(`/api/rni/${rniExchangeId}/${studyExchangeId}/update`, rni).pipe(
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('AwarenessDateCannotBeFuture')) {
            this.toastr.error("Date of awareness cannot be in the future.", "Invalid Date");
          } else if (err.errors[''].includes('SirbRniHasBeenApproved')) {
            this.toastr.error("Rni is complete/acknowledged", "Can't Update");
          } else {
            this.toastr.error("Something went wrong.", "Server error!");
          }
        }
        return throwError(err);
      })
    );
  }

  getNewModification(studyExchangeId: string) {
    return this.http.get<GetModificationResults>(`/api/modification/${studyExchangeId}`)
      .pipe(map((data) => data.modification));
  }

  getModification(modExchangeId: string, studyExchangeId: string) {
    return this.http.get<GetModificationResults>(`/api/modification/${modExchangeId}/${studyExchangeId}`)
      .pipe(map((data) => data.modification));
  }

  createModification(studyExchangeId: string, modification: Modification) {
    return this.http.post(`/api/modification/${studyExchangeId}/create`, modification).pipe(
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('SiteIsNotApproved')) {
            this.toastr.error("Site is not Approved.", "Cant open!")
          } else if (err.errors[''].includes('SiteHasOpenMod')) {
            this.toastr.error("There is already an open mod.", "Cant open!")
          } else {
            this.toastr.error("Something went wrong.", "Server error!")
          }
        }
        return throwError(err);
      })
    );
  }

  updateModification(modExchangeId: string, studyExchangeId: string, modification: Modification) {
    return this.http.post(`/api/modification/${modExchangeId}/${studyExchangeId}/update`, modification).pipe(
      catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('ModificationIsNotFound')) {
            this.toastr.error("Modification is not found.", "Not Found!")
          } else if (err.errors[''].includes('SirbModHasBeenApproved')) {
            this.toastr.error("Modification is Approved.", "Can't Update!")
          } else {
            this.toastr.error("Something went wrong.", "Server error!")
          }
        }
        return throwError(err);
      })
    );
  }

  mergeModification(modExchangeId: string, studyExchangeId: string) {
    return this.http.post(`/api/modification/${modExchangeId}/${studyExchangeId}/merge`, {});
  }

  updateContinuingReview(studyExchangeId: string, siteExchangeId: string, cr: CrReport) {
    return this.http.post(`api/research/continuingReview/${studyExchangeId}/${siteExchangeId}`, cr);
  }

}
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { User, UserDto } from '../models';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DataServicesModule } from './data-services.module';
import { ToastrService } from 'ngx-toastr';

export interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface RegisterRequest {
  organizationId: string,
  organizationName: string,
  assuranceNumber: string,
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordRequest {
  email: string;
  password: string;
  confirmPassword: string;
  code: string;
}

export interface ConfirmEmailResponse {
  isApproved: boolean;
}


@Injectable({
  providedIn: DataServicesModule
})
export class AccountService {

  constructor(private http: HttpClient,
              private toastr: ToastrService) { }

  changePassword(request: ChangePasswordRequest) {
    return this.http.post(`/api/accounts/change-password`, request, { withCredentials: true });
  }

  confirmEmail(userId: string, code: string) {
    return this.http.post<ConfirmEmailResponse>('/api/accounts/confirm-email', { userId, code });
  }

  register(request: RegisterRequest): Observable<User> {
    return this.http.post<Partial<UserDto>>('/api/accounts/register', request)
      .pipe(catchError(err => {
        if (err.errors && err.errors['']) {
          if (err.errors[''].includes('DuplicateOrganizationName')) {
            this.toastr.error("An individual from this institution has already submitted a registration request. " +
              "Contact HuronIRBExchange@huronconsultinggroup.com if you have questions about the registration process.",
              "Signup failed!");
          }
        }
        return throwError(err);
      }),
        map(dto => new User(dto)));
  }

  sendEmailConfirmation(userId: string) {
    return this.http.post('/api/accounts/send-email-confirmation', { userId });
  }

  recoverPassword(email: string) {
    return this.http.post('/api/accounts/recover-password', { email });
  }
  
  resetPassword(request: ResetPasswordRequest) {
    return this.http.post('/api/accounts/reset-password', request);
  }

  autoConfirmEmail(email: string) {
    return this.http.post('/api/accounts/auto-confirm-email', { email });
  }
}

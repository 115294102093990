import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared';
import { UsersModule } from '../users';
import { AgGridModule } from 'ag-grid-angular';

import { ContinuingReviewComponent } from './continuing-review/continuing-review.component';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { ReportableNewInformationComponent } from './reportable-new-information/reportable-new-information.component';
import { SiteDetailsComponent } from './site-details/site-details.component';
import { SiteModificationComponent } from './site-modification/site-modification.component';
import { StudyDetailsComponent } from './study-details/study-details.component';
import { StudyListComponent } from './study-list/study-list.component';
import { ReviewDetailsComponent } from './review-details/review-details.component';
import { ReviewDatesComponent } from './review-dates/review-dates.component';
import { DocumentDownloadCellComponent, DocumentUploadCellComponent, DocumentUploadListComponent, DocumentUploadListCellComponent, DocumentDownloadListCellComponent, RouterLinkCellComponent } from '../shared';
import { ReportableNewInformationEditorComponent } from './reportable-new-information-editor/reportable-new-information-editor.component';
import { ReportableNewInformationCreateComponent } from './reportable-new-information-create/reportable-new-information-create.component';
import { SiteModificationCreateComponent } from './site-modification-create/site-modification-create.component';
import { SiteModificationEditorComponent } from './site-modification-editor/site-modification-editor.component';
import { SiteContactsEditorComponent } from './site-contacts-editor/site-contacts-editor.component';
import { SiteContactsReaderComponent } from './site-contacts-reader/site-contacts-reader.component';
import { DeleteRowComponent } from '../shared/delete-row/delete-row.component';
import { UserSearchTypeaheadComponent } from '../users/user-search-typeahead/user-search-typeahead.component';
import { ModificationEditorComponent } from './modification-editor/modification-editor.component';
import { ModificationReaderComponent } from './modification-reader/modification-reader.component';
import { RniEditorComponent } from './rni-editor/rni-editor.component';
import { RniReaderComponent } from './rni-reader/rni-reader.component';
import { ModificationReviewDetailsComponent } from './modification-review-details/modification-review-details.component';
import { NotifyDialogComponent } from './notify-dialog/notify-dialog.component';

@NgModule({
    declarations: [
        ContinuingReviewComponent,
        InvitationListComponent,
        ReportableNewInformationComponent,
        SiteDetailsComponent,
        SiteModificationComponent,
        StudyDetailsComponent,
        StudyListComponent,
        ReviewDetailsComponent,
        ReviewDatesComponent,
        ReportableNewInformationEditorComponent,
        ReportableNewInformationCreateComponent,
        SiteModificationCreateComponent,
        SiteModificationEditorComponent,
        SiteContactsEditorComponent,
        SiteContactsReaderComponent,
        ModificationEditorComponent,
        ModificationReaderComponent,
        RniEditorComponent,
        RniReaderComponent,
        ModificationReviewDetailsComponent,
        NotifyDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        SharedModule,
        UsersModule,
        AgGridModule.withComponents([
            DocumentDownloadCellComponent,
            DocumentDownloadListCellComponent,
            DocumentUploadCellComponent,
            DocumentUploadListCellComponent,
            RouterLinkCellComponent,
            DeleteRowComponent,
        ])
    ],
    entryComponents: [
        UserSearchTypeaheadComponent,
        NotifyDialogComponent
    ]
})
export class ResearchModule { }

import { Injectable, SecurityContext } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  downloadFile(studyId:string, itemId:string, fileName:string){
    itemId = itemId.replace(/\//g, '|');
    studyId = encodeURIComponent(studyId);
    itemId = encodeURIComponent(itemId);
    fileName = fileName.replace(/\+/g, '_'); //The encodeURIComponent replaces +(Plus) to %2B, but that fails in API call. Hence keeping a manual conversion.
    fileName = encodeURIComponent(fileName);
    return this.http.get(`/api/file/download/${studyId}/${itemId}/${fileName}`, {responseType:'blob'});
  }
}

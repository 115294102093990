import { Component, OnInit, Input } from '@angular/core';

export class Step {

  private _active: boolean;
  private _complete: boolean;
  private _show: boolean;
  private _data: any;
  private _title: string;
  
  constructor(
    title: string,
    hide: boolean = false,
    data: any = null) {
      this._title = title;
      this._show = !hide;
      this._data = data;
  }

  get active(): boolean {
    return this._active;
  }

  get complete(): boolean {
    return this._complete;
  }

  get data(): any {
    return this._data;
  }

  get inactive(): boolean {
    return !this._active;
  }

  get show(): boolean {
    return this._show;
  }

  get title(): string {
    return this._title;
  }
  
  public markComplete() {
    this._complete = true;
  }

  public markIncomplete() {
    this._complete = false;
  }

  public markActive() {
    this._active = true;
  }

  public markInactive() {
    this._active = false;
  }

}

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.scss']
})
export class StepListComponent implements OnInit {

  @Input() steps: Step[];

  private index: number = -1;
  private _complete: boolean;
  private history: number[] = [];
  

  constructor() { 
    
  }

  get complete(): boolean {
    return this._complete;
  }
  get currentStep(): Step {
    return this.index < this.steps.length ? this.steps[this.index] : null;
  }
  get currentIndex(): number {
    return this.index;
  }

  get visibleSteps(): Step[] {
    return this.steps.filter(s => s.show);
  }

  ngOnInit() {
    console.log("[StepListComponent::ngOnInit()]")
    this.moveNext();
  }

  public canMoveTo(stepIndex: number) {
    return stepIndex < this.steps.length;
  }

  public canMoveNext(): boolean {
    return this.canMoveTo(this.index + 1);
  }

  public canMovePrevious(): boolean {
    return this.history.length > 0;
  }

  public moveTo(stepIndex: number, markComplete: boolean = true) {

    if (!this.canMoveTo(stepIndex)) {
      return false;
    }

    if (this.currentStep) {
      if (markComplete) {
        this.currentStep.markComplete();
      }
      
      this.currentStep.markInactive();
      this.history.push(this.index);
    }

    this.index = stepIndex;
    this.currentStep.markActive();
    return true;
  }

  public moveNext(): boolean {
    return this.moveTo(this.index + 1);
  }

  public movePrevious(): boolean {

    if (!this.canMovePrevious()) {
      return false;
    }

    var prevIndex = this.history.pop();
    var prevStep = this.steps[prevIndex];

    prevStep.markIncomplete();
    prevStep.markActive();

    this.currentStep.markInactive();

    this.index = prevIndex;
    return true;
  }

  public reset(): void {
    this.steps.forEach( function (step, index){
      step.markInactive();
      step.markIncomplete();
    });
    this.moveTo(0, false);
    this.history = [];
  }
}

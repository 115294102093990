import { Component, OnInit } from '@angular/core';
import { StudyListItem } from '../../models/study';
import { ResearchService } from '../../data-services';
import { ToastrService } from 'ngx-toastr';
import { AgGridDefaults, RouterLinkCellComponent } from '../../shared';

@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.scss']
})

export class InvitationListComponent implements OnInit {

  studyListItems: StudyListItem[] = [];
  loadComplete: boolean = false;
  hasInvitations: boolean = false;
  alertClosed: boolean = false;
  
  gridOptions = AgGridDefaults.getGridOptions({
    columnDefs: [
      { headerName: "IRB of Record", field: "irbOfRecord" },
      {
        headerName: "Study ID", field: "studyId", minWidth: 135, cellRenderer: "routerLinkRenderer",
        cellRendererParams: { routerLink: ['/research/studies/', '{{exchangeContainerId}}'], displayAttribute: 'studyId' }
      },
      { headerName: "Study Title", field: "title" },
      { headerName: "Lead PI", field: "studyPI.lastName", minWidth: 100 },
      { headerName: "Local PI", field: "sitePI.lastName", minWidth: 100 },
      { headerName: "Last Updated", field: "lastUpdated", type: ["date"] },
    ],
    frameworkComponents: {
      routerLinkRenderer: RouterLinkCellComponent,
    }
  });

  constructor(
    private researchService: ResearchService) { }

  ngOnInit() {
    this.researchService.getInvitations().subscribe(
      (stus) => {
        this.loadComplete = true;
        this.studyListItems = stus;
        this.hasInvitations = this.studyListItems.length > 0;
      });
  }

  //When grid is displayed following successful retrieval of site data, connect grid data source
  onGridReady(params) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;
    this.gridOptions.api.setRowData(this.studyListItems);
    this.gridOptions.setGridColumnWidths();
  }

  onFirstDataRendered(params, optionsObj) {
    return AgGridDefaults.onFirstDataRendered(params, optionsObj);
  }
}

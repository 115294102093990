
/** 
 * The users roles.
 */
export enum Role {
    Administrator = 'Administrator',
    AccountManager = 'Account Manager',
    RelianceCoordinator = 'Reliance Coordinator',
    StudyStaff = 'Study Staff'
}

/**
 * Additional role information that is useful for building UI components.
 */
export interface RoleMetadata {
    role: Role
    abbrev: string
    description: string
}

/**
 * The collection of metadata for each role.
 */
export const ROLE_METADATA: RoleMetadata[] = [
    {
        role: Role.Administrator,
        abbrev: 'Admin',
        description: ''
    },
    {
        role: Role.AccountManager,
        abbrev: 'AM',
        description: 'can add and remove users. Approve and deny user requests, and reassign user roles.'
    },
    {
        role: Role.RelianceCoordinator,
        abbrev: 'RC',
        description: 'can accept or decline any research invitation. They may also view and edit all sites for their organization.'
    },
    {
        role: Role.StudyStaff,
        abbrev: 'SS',
        description: 'can accept or decline research invitations when assigned as the principal investigator for a site. They may only view and edit sites on which they are assigned as a site contact (i.e. PI, PI Proxy).'
    }
];
import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { DocumentItem } from 'src/app/models/documentItem';

@Component({
  selector: 'app-document-upload-list-cell',
  templateUrl: './document-upload-list-cell.component.html',
  styleUrls: ['./document-upload-list-cell.component.scss']
})
export class DocumentUploadListCellComponent implements ICellEditorAngularComp {
  params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    return this.params.value;
  }

  isPopup(): boolean {
    return true;
  }

  onDocListChanged(docList:DocumentItem[]){
    this.params.api.resetRowHeights();
    this.params.api.sizeColumnsToFit();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AccountService } from '../../data-services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  theForm: FormGroup;
  showConfirmation = false;
  submitting = false;

  constructor(
    private accountService: AccountService,
    private fb: FormBuilder) { 

    this.theForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
  }

  preventSubmit() {
    return this.theForm.invalid || this.submitting;
  }

  onSubmit() {
    this.submitting = true;
    this.accountService.recoverPassword(this.theForm.controls['email'].value)
      .pipe(finalize(() => this.submitting = false))
      .subscribe(() => {
        this.showConfirmation = true;
      });
  }
}

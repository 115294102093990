import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-row',
  templateUrl: './delete-row.component.html',
  styleUrls: ['./delete-row.component.scss']
})

export class DeleteRowComponent {  
  params: any;

  constructor() { }
  
  refresh(params): boolean {
    this.params = params;
    return true;
  }

  agInit(params): void {
    this.params = params;
  }

  deleteRow(){
    this.params.node.data.delete = true;
    this.params.api.updateRowData({update: [this.params.node.data]});
  }
}

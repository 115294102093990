import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatePipeExampleComponent } from './date-pipe-example/date-pipe-example.component';
import { ForbiddenExampleComponent } from './forbidden-example/forbidden-example.component';
import { OrganizationSearchTypeaheadExampleComponent } from './organization-search-typeahead-example/organization-search-typeahead-example.component';
import { LoadingExampleComponent } from './loading-example/loading-example.component';
import { MessageBoxExampleComponent } from './message-box-example/message-box-example.component';

const routes: Routes = [
  { 
    path: 'examples',
    children: [
      { path: 'date-pipe', component: DatePipeExampleComponent },
      { path: 'forbidden', component: ForbiddenExampleComponent },
      { path: 'org-search', component: OrganizationSearchTypeaheadExampleComponent },
      { path: 'loading', component: LoadingExampleComponent },
      { path: 'message-box', component: MessageBoxExampleComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamplesRoutingModule { }

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Site } from '../../models/site';
import { UserSearchTypeaheadComponent } from 'src/app/users/user-search-typeahead/user-search-typeahead.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models';
import { UserSearchResult } from 'src/app/data-services';


@Component({
  selector: 'app-site-contacts-editor',
  templateUrl: './site-contacts-editor.component.html',
  styleUrls: ['./site-contacts-editor.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SiteContactsEditorComponent implements OnInit {
  @Input() site: Site;
  @Input() parentForm: NgForm;
  @ViewChild('typeahead') typeahead: UserSearchTypeaheadComponent;
  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  onClickShowSearch(searchTarget, targetIndex?) {
    this.modalRef = this.modalService.open(UserSearchTypeaheadComponent);
    switch(searchTarget){
      case null:
      case "investigator":
        this.modalRef.componentInstance.label = "Search for Investigator by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.principalInvestigator = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
      case "primaryContact":
        this.modalRef.componentInstance.label = "Search for Primary Contact by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.primaryContact = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
      case "proxy":
        this.modalRef.componentInstance.label = "Search for PI Proxy by Last Name or Email";
        this.modalRef.result.then((user: UserSearchResult) => {
          this.site.piProxies[targetIndex] = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }).catch(() => { /* cancelled by the user */ });
        break;
    }
  }
}

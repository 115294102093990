import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { RniDto } from 'src/app/models/rni';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserSearchTypeaheadComponent } from 'src/app/users/user-search-typeahead/user-search-typeahead.component';
import { UserSearchResult } from 'src/app/data-services';

@Component({
  selector: 'app-rni-editor',
  templateUrl: './rni-editor.component.html',
  styleUrls: ['./rni-editor.component.scss']
})
export class RniEditorComponent implements OnInit {

  @Input() rniForm: FormGroup;
  @Input() rni: RniDto;
  @Input() categories: string[];
  @Input() submitted: boolean;
  @ViewChild('typeahead') typeahead: UserSearchTypeaheadComponent;

  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  // Search user
  onClickShowSearch() {
    this.modalRef = this.modalService.open(UserSearchTypeaheadComponent);
    this.modalRef.componentInstance.label = "Search for Reported by Last Name or Email";
    this.modalRef.result.then((user: UserSearchResult) => {
      this.rniForm.patchValue({
        reportedBy: user.firstName + ' ' + user.lastName
      });
    }).catch(() => { /* cancelled by the user */ });
  }

  // convenience getter for easy access to form fields
  get f() { return this.rniForm.controls; }

  // get categories controls.
  get categoriesControl() {
    return <FormArray>this.rniForm.get('categories');
  }

}

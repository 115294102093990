import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/data-services';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxService, MessageBoxResult, MessageBoxIcon, MessageBoxButtons } from 'src/app/shared';
import { Observable } from 'rxjs';
import { User } from 'src/app/models';

@Component({
  selector: 'app-delete-user-row',
  templateUrl: './delete-user-row.component.html',
  styleUrls: ['./delete-user-row.component.scss']
})
export class DeleteUserRowComponent implements OnInit{
  private confirm: Observable<MessageBoxResult>;
  params: any;
  user : User;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private messageBox: MessageBoxService) { }

  ngOnInit() {

  }

  refresh(params): boolean {
    this.params = params;
    return true;
  }

  agInit(params): void {
    this.params = params;
    this.user = params.node.data;
    this.confirm = this.messageBox.open({
      message: 'Are you sure?',
      title: `Delete ${this.user.name}`,
      icon: MessageBoxIcon.Warning,
      buttons: MessageBoxButtons.YesNo,
      dismissable: false
    });
  }

  deleteRow() {
    this.confirm.subscribe(value => {
      if (value == MessageBoxResult.Yes) {
        this.userService.deleteUser(this.user.id)
          .subscribe(data => {
            this.toastr.success(`${this.user.name}'s account was deleted.`, 'Success');
            this.params.api.updateRowData({ remove: [this.params.node.data] });
          })
      }
    });
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { ReviewInformation } from '../../models/reviewInformation';

@Component({
  selector: 'app-modification-review-details',
  templateUrl: './modification-review-details.component.html',
  styleUrls: ['./modification-review-details.component.scss']
})
export class ModificationReviewDetailsComponent implements OnInit {

  @Input() reviewInformation: ReviewInformation;
  hasLetter: boolean = false;

  constructor() { }

  ngOnInit() {

    if (this.reviewInformation.correspondenceLetter != null && this.reviewInformation.correspondenceLetter.fileName != null) {
      this.hasLetter = true;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';

@Component({
  selector: 'app-user-row-actions-editor',
  templateUrl: './user-row-actions-editor.component.html',
  styleUrls: ['./user-row-actions-editor.component.scss']
})
export class UserRowActionsEditorComponent implements OnInit {
  gridParams: any;
  user: User;

  constructor() { }

  ngOnInit() {
  }

  refresh(params): boolean {
    this.gridParams = params;
    return true;
  }

  agInit(params): void {
    this.gridParams = params;
    this.user = this.gridParams.node.data;
  }

  onDenyUser(e: any) {
    this.gridParams.api.updateRowData({ remove: [this.gridParams.node.data] });
  }

}
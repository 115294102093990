import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataServicesModule } from './data-services.module';
import { Study, StudyListItem} from '../models';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: DataServicesModule
})
export class FeedbackService {

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  provideFeedback(comment: string, rating ?: number) {
    return this.http.post('/api/feedback', { comment, rating })
  }

  askSupport(comment:String, pageSelected:String){
    return this.http.post('api/feedback/support', {comment, pageSelected}).pipe(
      catchError(err => {
        if (err) {
            this.toastr.error("Something went wrong.", "Server error!")
          }
        return throwError(err);
      })
    );
  }

  sendStudyEmail(comment:String, study:StudyListItem){
    return this.http.post('api/feedback/studyEmail', {comment, study}).pipe(
      catchError(err => {
        if (err) {
            this.toastr.error("Something went wrong.", "Server error!")
          }
        return throwError(err);
      })
    );
  }

  notifyStudyTeamLead(studyExchangeId:String){
    return this.http.post('api/feedback/notifyStudyTeamLead', {studyExchangeId}).pipe(
      catchError(err => {
        if (err) {
            this.toastr.error("Something went wrong.", "Server error!")
          }
        return throwError(err);
      })
    );
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from 'src/app/models';
import { UserService } from '../../data-services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-lock-editor',
  templateUrl: './user-lock-editor.component.html',
  styleUrls: ['./user-lock-editor.component.scss']
})
export class UserLockEditorComponent implements OnInit {

  @Input() user: User;
  @Output() lock = new EventEmitter<User>();
  @Output() unlock = new EventEmitter<User>();

  constructor(
    private userService: UserService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  lockUser() {
    this.userService.lockUser(this.user.id)
      .subscribe(() => {
        this.toastr.success(`${this.user.name}'s account has been locked.`, 'Success');
        this.user.isLocked = true;
        this.lock.next(this.user);
      });
  }

  unlockUser() {
    this.userService.unlockUser(this.user.id)
      .subscribe(() => {
        this.toastr.success(`${this.user.name}'s account has been unlocked.`, 'Success');
        this.user.isLocked = false;
        this.unlock.next(this.user);
      });
  }

}

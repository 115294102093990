import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}


@Injectable()
export class NgbDateUSParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split(/[\/\-\.]/); //split on slash dot or dash
      if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {year: toInteger(dateParts[2]), month: toInteger(dateParts[0]), day: toInteger(dateParts[1])};
      }
    }   
    return null;
  }

  format(date: NgbDateStruct): string {
    var tempDate;
    if(date && isNumber(date.year) && isNumber(date.month) && isNumber(date.day)) {
      tempDate = new Date(date.year, date.month-1, date.day);
      return tempDate.toLocaleDateString("en-US");
    }
    else{
      return "";
    }
  }
}
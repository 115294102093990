import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidation {

    /**
     * This is a factory method for creating a validation that requires two controls to have
     * the same value. 
     * 
     * @param control1 The name of the password form control. Defaults to 'password'.
     * @param control2 The name of the confirm password form control. Defaults to 'confirmPassword'.
     */
    static MatchPassword(control1: string = 'password', control2: string = 'confirmPassword'): ValidatorFn {
        return (ac: AbstractControl) => {
            let password = ac.get(control1).value;
            let confirm = ac.get(control2).value;
            if (password != confirm) {
                ac.get(control2).setErrors({ matchPassword: true });
            }
            return null;
        }
    }

    /**
     * This validation ensures the value contains a minimum of number of unique characters.
     * 
     * @param min The minimum number of unique characters
     */
    static minUnique(min: number): ValidatorFn {
        
        function countUnique(value: string) {
            if (!value) {
                return 0;
            }
            
            var uniqueChars = "";
            for (var i=0; i < value.length; i++) {
                var c = value.charAt(i);
                if (uniqueChars.indexOf(c) == -1) {
                    uniqueChars += c;
                }
            }
    
            return uniqueChars.length;
        }

        return (ac: AbstractControl) => {
            var unique = countUnique(ac.value);
            return unique < min ? { 'minUnique': { required: min, actual: unique } } : null;
        }
    }
}

export class AgGridDefaults {
  public static gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      icons: {
        sortAscending: '<i class="fal fa-sort-up"></i>',
        sortDescending: '<i class="fal fa-sort-down"></i>',
        sortUnSort: '<i class="fal fa-sort"></i>',
        filter: '<i class="fal fa-filter fa-sm" style="width: 10px"></i>',
        menu: '<i class="fas fa-bars" style="width: 10px"></i>'
      }
    },
    columnTypes : {
      "date" : {
        valueFormatter: AgGridDefaults.renderDate,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: AgGridDefaults.dateComparator
        }
      },
      "deleteRow" : {
        minWidth: 40, 
        maxWidth: 40, 
        resizable: false, 
        filter: false,
        sortable: false
      },
      "noSort" : { sortable : false, filter: false },
      "nonEditable": {editable: false}
    },
    rowData: null,
    animateRows: true,
    suppressColumnMoveAnimation: true,
    stopEditingWhenGridLosesFocus: true,
    singleClickEdit: true,
    unSortIcon: true, 

    //common storage location for dynamically generated grid and column apis
    api: null,
    columnApi: null,
    
    suppressPropertyNamesCheck : true,
    suppressNoRowsOverlay : true,
    suppressLoadingOverlay : true,
    suppressMovableColumns : true,
    /**
     * set dynamic column widths if the api, columnApi, and columnDefs are set, with ids on the colDefs
     */
    setGridColumnWidths : function() {
      var allColumnIds = [];
      if(this.columnDefs && this.columnApi && this.api){
        this.api.setDomLayout("autoHeight");
        this.columnDefs.forEach(function (columnDef) {
          if(columnDef.colId){
            allColumnIds.push(columnDef.colId);
          }else{
            allColumnIds.push(columnDef.field);
          }
        });
        //autoSizeColumns will shrink/expand each col to minimum readable width
        this.columnApi.autoSizeColumns(allColumnIds);
        //sizeColumnsToFit will shrink/expand from there to fill the grid viewport
        this.api.sizeColumnsToFit();
      }
    }
  }

  /**
   * Returns default grid options such as icons, resizing of columns, animation, etc. Merges in the specific options passed as a paramter
   * @param specificGridOptions optional object containing a columnDefs array, any frameworkComponents used in columnDefs, and specific overrides of default options.
   */
  public static getGridOptions(specificGridOptions:any) : any {
    if(specificGridOptions == null){
      specificGridOptions = {};
    }
    //object spread is quite similar to Object.Assign(), but apparently its cooler and better somehow
    return Object.assign({}, this.gridOptions, specificGridOptions);
  }

  //generic handler for first row rendered in grid
  public static onFirstDataRendered(params, optionsObject) {
    optionsObject.api = params.api;
    optionsObject.columnApi = params.columnApi;
    optionsObject.setGridColumnWidths();
  }

  public static renderPersonLong(params){
    let retArray = [];
    if (params.value.lastName) {
      retArray.push(params.value.lastName);
    }
    if (params.value.firstName) {
      retArray.push(params.value.firstName);
    }
    return retArray.join(', ');
  }

  public static renderPersonShort(params){
    return params.value.lastName || "";
  }

  public static renderDate(params){
    if(!params.value) return "";
    let pDate = new Date(params.value);
    let stringBuilder = [];
    let strDay = "" + pDate.getDate();
    let strMonth = "" + (pDate.getMonth()+1);
    let strYear = "" + pDate.getFullYear();
    stringBuilder.push(strMonth.padStart(2,'0'));
    stringBuilder.push(strDay.padStart(2, '0'));
    stringBuilder.push(strYear);
    return stringBuilder.join('/');
  }

  public static dateComparator(filterLocalDateAtMidnight, cellValue){
    if(cellValue == null){
      return 0;
    }
    let filterDate = new Date(filterLocalDateAtMidnight);
    let cellDate = new Date(cellValue);
    cellDate.setHours(0,0,0,0);
    if (filterDate < cellDate){
      return 1;
    } else if (filterDate > cellDate){
      return -1;
    } else return 0;
  }
}
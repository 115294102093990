import { Component, OnInit } from '@angular/core';
import { MessageBoxService, MessageBoxButtons, MessageBoxIcon, MessageBoxResult } from 'src/app/shared';

@Component({
  selector: 'app-message-box-example',
  templateUrl: './message-box-example.component.html',
  styleUrls: ['./message-box-example.component.scss']
})
export class MessageBoxExampleComponent implements OnInit {

  constructor(private messageBox: MessageBoxService) { }

  ngOnInit() {
  }

  demo0() {
    this.messageBox.alert("simple alert message").subscribe((val) => {
      console.log(val == MessageBoxResult.Ok);
    });
  }

  demo1() {
    this.messageBox.open({ message: 'Hello, World!', title: "Title" })
      .subscribe((val) => {
        console.log(val);
      });
  }

  demo2() {
    this.messageBox.confirm("You do really want to do this?")
      .subscribe((val) => {
        console.log(val);
      })
  }

  demo3() {
    this.messageBox.open({ 
      message: "Something went very wrong. This is some more text so that I can see what happens when the line of text wraps.", 
      title: "Error", 
      buttons: MessageBoxButtons.OkCancel, 
      icon: MessageBoxIcon.Error
    }).subscribe((val) => {
      console.log(val);
    });
  }
  
  demo4() {
    this.messageBox.open({ 
      message: "Something went very wrong. This is some more text so that I can see what happens when the line of text wraps.", 
      title: "Warning", 
      buttons: MessageBoxButtons.OkCancel, 
      icon: MessageBoxIcon.Warning
    }).subscribe((val) => {
      console.log(val);
    });
  }

  demo5() {
    this.messageBox.open({ 
      message: "Something went very wrong. This is some more text so that I can see what happens when the line of text wraps.", 
      title: "Information", 
      buttons: MessageBoxButtons.OkCancel, 
      icon: MessageBoxIcon.Information
    }).subscribe((val) => {
      console.log(val);
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-pipe-example',
  templateUrl: './date-pipe-example.component.html',
  styleUrls: ['./date-pipe-example.component.scss']
})
export class DatePipeExampleComponent implements OnInit {

  myDate = "2019-01-29T21:25:00.6330073-08:00";
  myDateFormat = 'short';
  myCustomFormat = 'yyyy-MM-dd HH:mm:ss'

  formats: string[] = [
    'short',
    'medium',
    'long',
    'shortDate',
    'mediumDate',
    'longDate',
    'fullDate',
    'shortTime',
    'mediumTime',
    'longTime',
    'fullTime',
    'custom'
  ]

  constructor() { }

  ngOnInit() {
  }

  get dateFormat() {
    return this.myDateFormat === 'custom' ? this.myCustomFormat : this.myDateFormat;
  }
}

import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationSearchResult, OrganizationService } from '../../data-services';
import { Observable } from 'rxjs';
import { finalize, switchMap, tap, distinctUntilChanged, debounceTime, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-organization-search-typeahead',
  templateUrl: './organization-search-typeahead.component.html',
  styleUrls: ['./organization-search-typeahead.component.scss']
})
export class OrganizationSearchTypeaheadComponent implements OnInit {

  @Input() placeholder: string = "Search by name or FWA number";

  @Input() label: string;

  /**
   * The search term.
   */
  @Input() term: string;

  @Output('selectOrganization') select = new EventEmitter<OrganizationSearchResult>();

  /**
   * This event is fired just before the component makes a request to the
   * server to find matching organization. The search term is supplied as
   * the event data.
   */
  @Output('beforeSearch') beforeSearch = new EventEmitter<string>();

  /**
   * A refrence to the typeahead component used in the view template.
   */
  @ViewChild('typeahead') typeahead: NgbTypeahead;

  /**
   * The observable function used to produce the search results
   */
  search: (text: Observable<string>) => Observable<OrganizationSearchResult[]>

  /**
   * A flag to indicate a request has been made to the server and is waiting
   * on a response.
   */
  searching: boolean = false;

  constructor(private organizationService: OrganizationService) { }

  ngOnInit() {

    this.search = (text: Observable<string>) => text.pipe(
      map(t => t.trim()),
      tap(() => this.typeahead.dismissPopup()),
      filter(t => t.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      tap(t => this.beforeSearch.emit(t)),
      tap(() => this.searching = true),
      switchMap(t => this.organizationService.search({ term: t , local: true, exchange: false, ohrp: true }).pipe(
      finalize(() => this.searching = false))),
    );
  }

  onSelected(event: NgbTypeaheadSelectItemEvent) {
    this.select.emit(event.item as OrganizationSearchResult);
  }

  /**
   * This is used for format the search results into a display string for the typeahead input control.
   * 
   * @param org An organization search result object.
   */
  format(org: OrganizationSearchResult) {
    return org.name;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentItem } from 'src/app/models/DocumentItem';
import { DocumentService } from '../../data-services';
import { FileSaverService } from 'ngx-filesaver';
import { ValidationProblemDetails } from '..';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
  styleUrls: ['./document-download.component.scss']
})

export class DocumentDownloadComponent implements OnInit {
  @Input() doc: DocumentItem;
  @Input() showDeleteIcon: boolean;
  @Output() deleteIconClicked = new EventEmitter();
  loading: boolean = false;

  constructor(private documentService: DocumentService,
    private _FileSaverService: FileSaverService,
    private toastr: ToastrService) { }

  ngOnInit( ) { }

  downloadFile(studyId: string, itemId: string, fileName: string) {
    this.loading = true;
    this.documentService.downloadFile(studyId, itemId, fileName)
      .subscribe(
        (res) => {
          this._FileSaverService.save(res, fileName);
          this.loading = false;
        }, (err: ValidationProblemDetails) => {
          if (err) {
            this.toastr.info("Document may still be uploading at the source, try again later.", "Document Unavailable!");
            this.loading = false;
          }
        });
  }

  onClickDelete(){
    this.deleteIconClicked.emit(this.doc);
  }
}

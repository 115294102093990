import { Component } from '@angular/core';

@Component({
  selector: 'app-document-upload-cell',
  templateUrl: './document-upload-cell.component.html',
  styleUrls: ['./document-upload-cell.component.scss']
})
export class DocumentUploadCellComponent {
  params: any;

  constructor() { }

  refresh(params): boolean {
    this.params = params;
    return true;
  }

  agInit(params): void {
    this.params = params;
  }

  canEdit(): boolean {      
    return this.params && this.params.contextObj && this.params.contextObj.canEdit;
  }
}
